import React from 'react';
import styles from './SubscriptionBundles.module.css';
import { useNavigate } from 'react-router-dom';
import { useSubscriptionLogic } from '../useSubscriptionLogic';
import BundleCard from 'components/SubscriptionComponents/BundleCard/BundleCard';
import { Breadcrumbs, Typography } from '@mui/material';

const SubscriptionBundles = () => {
  const navigate = useNavigate();

  const { fetchPublicTiersQuery, shopTier, assignTier, unassignTier, shopId } = useSubscriptionLogic();

  return (
    <div className={styles.mainContainer}>
      <Breadcrumbs aria-label='breadcrumb' className={styles.breadcrumb}>
        <button
          onClick={() => navigate(`/shops/${shopId}/administration/subscriptions`)}
          className={styles.breadcrumb_link}>
          Előfizetői beállítások
        </button>
        <Typography variant='body1'>Csomagok</Typography>
      </Breadcrumbs>
      {fetchPublicTiersQuery?.data && (
        <div className={styles.cardContainer}>
          {fetchPublicTiersQuery?.data?.data
            ?.sort((a, b) => a?.name?.toLocaleLowerCase()?.localeCompare(b?.name?.toLocaleLowerCase()))
            ?.map((el, idx) => {
              return <BundleCard bundleData={el} currentTier={shopTier} assign={assignTier} unassign={unassignTier} />;
            })}
        </div>
      )}
    </div>
  );
};

export default SubscriptionBundles;
