import { Grow } from '@mui/material';
import React, { useState } from 'react';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import styles from './Alert.module.css';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const Alert = () => {
  const [hover, setHover] = useState(false);
  const navigate = useNavigate();

  const shopId = useSelector((state) => state.nonPersistedReducers?.shopSliceReducer?.shopId);
  const alert = useSelector((state) => state.nonPersistedReducers?.alertSliceReducer?.alert);

  const routeChange = (path) => {
    navigate(path);
  };

  return (
    <Grow timeout={500} direction='left' in={true}>
      <div
        className={styles.alertContainer}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={() => routeChange(`/shops/${shopId}/administration/invoicing`)}>
        <WarningAmberIcon style={{ color: 'white' }} />
        {hover && (
          <div className={styles.alertText}>
            {alert === 'no_vendor'
              ? 'Még nem állítottál be számlázási kiszolgálót!'
              : alert === 'set_modifiers'
              ? 'Van olyan terméked, amit még egyik számlázási kiszolgálóhoz sem állítottál be!'
              : ''}
          </div>
        )}
      </div>
    </Grow>
  );
};

export default Alert;
