import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import styles from './HeaderOption.module.css';

const HeaderOption = (props) => {
  return (
    <ListItem className={styles.listItem} disabled={props.disabled} onClick={props.click} button key={props.name}>
      <ListItemIcon>{props.icon}</ListItemIcon>
      <ListItemText primary={props.name} />
    </ListItem>
  );
};

export default HeaderOption;
