import { Zoom, Typography } from '@material-ui/core';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import React from 'react';
import styles from './CreateInvoiceVendorCard.module.css';

const CreateInvoiceVendorCard = ({ toggleCreateVendor }) => {
  return (
    <Zoom in={true} timeout={1000}>
      <div className={styles.cardStyle}>
        <div
          className={styles.cardActionArea}
          onClick={() => {
            toggleCreateVendor();
          }}>
          <AddCircleIcon className={styles.addCircle} />
          <Typography className={styles.cardTitle}>Számlakibocsátó hozzáadása</Typography>
        </div>
      </div>
    </Zoom>
  );
};

export default CreateInvoiceVendorCard;
