import { useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import styles from './Accordion.module.css';

const Accordion = ({ title, defaultState = false, className = null, children, fontSize }) => {
  const [opened, setOpened] = useState(defaultState);

  return (
    <div className={`${styles.accordion_base} ${opened ? styles.opened : ''} ${className || ''}`}>
      <div className={styles.accordion_head} onClick={() => setOpened(!opened)}>
        <span style={{ fontSize: fontSize }}>{title}</span>
        <KeyboardArrowDownIcon className={styles.accordion_icon} />
      </div>
      <div className={styles.accordion_description} style={{ fontSize: fontSize }}>
        {children}
      </div>
    </div>
  );
};

export default Accordion;
