import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import HeaderData from 'config/HeaderData';
import AuthService from 'services/AuthService';
import styles from './NavbarMenu.module.css';
import { useSelector } from 'react-redux';
import { CircularProgress, Tooltip } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { getApi } from 'api/axios';
import { errorToast, successToast } from 'components/common/Toast/Toast';
import ConfirmDialog from 'components/common/ConfirmDialog/ConfirmDialog';
import { useUserApi } from 'hooks/useUserApi';
import HeaderProfile from '../HeaderProfile/HeaderProfile';

const NavbarMenu = ({ user }) => {
  const shopId = useSelector((state) => state.nonPersistedReducers?.shopSliceReducer?.shopId);

  const { getCurrentUser } = useUserApi();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const launchShop = async () => {
    setLoading(true);
    try {
      const res = await (
        await getApi({
          baseUrl: window._env_.REACT_APP_QDAK_PRODUCT_URL
        })
      ).patch(`/admin/shops/${shopId}/clear`);
      if (res.status >= 200 && res.status < 300) {
        successToast(
          `Sikeresen élesítette boltját, most már teljeskörűen használhatja az Iskolaévkönyv összes funkcióját, értékesítheti fényképeit!`
        );
        setOpen(false);
        getCurrentUser();
      }
      setLoading(false);
    } catch (e) {
      errorToast(`Sikertelen bolt élesítés! Kérjük próbálja újra később!`);
      console.log(e);
      setLoading(false);
    }
  };

  const handleLogout = () => {
    AuthService.doLogout();
  };

  useEffect(() => {
    console.log(user?.ownedShops?.find((shop) => shop.id === shopId)?.isDemo);
  }, [shopId]);

  return (
    <List component='nav' className={styles.bigList}>
      {AuthService.isLoggedIn() && (
        <>
          {user?.groups?.find((group) => group === 'users') &&
            user?.ownedShops?.find((shop) => shop.id === shopId)?.isDemo === true && (
              <Tooltip title={'Élesítés'}>
                <ListItem
                  button
                  onClick={(e) => {
                    setOpen(true);
                  }}
                  className={styles.listItem}>
                  Demo
                </ListItem>
              </Tooltip>
            )}
          {(user?.groups?.find((group) => group === 'dt_admin') ||
            user?.groups?.find((group) => group === 'printer_admin')) && (
            <Tooltip title={'Kijelentkezés'}>
              <ListItem
                button
                onClick={(e) => {
                  handleLogout();
                }}
                className={styles.listItem}>
                {HeaderData.logoutOption.icon}
              </ListItem>
            </Tooltip>
          )}

          {user?.groups?.find((group) => group === 'users') && (
            <HeaderProfile user={user} handleLogout={handleLogout} />
          )}
        </>
      )}
      <ConfirmDialog
        open={open}
        title={'Biztosan élesíteni szeretné boltját?'}
        text={'FIGYELEM!'}
        text2={
          'Élesítéssel az összes tesztelési célra létrehozott rendelését és számlázási beállításait töröljük, ugyanakkor galériái megmaradnak, így azokat éles környezetben is használhatja.'
        }
        functionButtonText={loading ? <CircularProgress size={23} color='green' /> : 'Élesítés'}
        functionButtonColor={'green'}
        closeButtonText={'Mégse'}
        onOk={() => {
          launchShop();
        }}
        onClose={() => {
          setOpen(false);
        }}
        multipleButtons
        disabled={loading}
      />
    </List>
  );
};

export default NavbarMenu;
