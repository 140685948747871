import { Fragment, useEffect, useRef } from 'react';
import Button from '@material-ui/core/Button';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from './Reports.module.css';
import { Tooltip, Typography, Zoom } from '@material-ui/core';
import LoadIndicator from 'components/common/LoadIndicator/LoadIndicator';
import { useGetQueryString } from 'hooks/useGetQueryString';
import { useSelector } from 'react-redux';
import QueryBuilder from 'components/common/QueryBuilder/QueryBuilder';
import { useReportingAPI } from '../hooks/useReportingAPI';
import { getApi } from 'api/axios';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

const Reports = () => {
  const currentDate = new Date();
  const navigate = useNavigate();
  const shopId = useSelector((state) => state.nonPersistedReducers?.shopSliceReducer?.shopId);
  const { search } = useLocation();

  const routeChange = (id) => {
    let path = `/shops/${shopId}/orders/${id}`;
    navigate(path);
  };

  const { getQueryString, getDateString } = useGetQueryString();
  const queryParams = [
    {
      name: 'report',
      value: `fromDateTime=${getDateString(
        new Date(currentDate.getTime() - 300 * 24 * 60 * 60 * 1000).setUTCHours(0, 0, 0, 0)
      )}&toDateTime=${getDateString(new Date(new Date().setUTCHours(23, 59, 59, 999)))}`
    }
  ];

  const {
    fetchAllReports,
    fetchAllAggregated,
    fetchAllPrinted,
    allReports,
    allAggregated,
    allPrints,
    allReportsLength,
    allReportsloading,
    allAggregateloading,
    allPrintsloading
  } = useReportingAPI();

  const refresh = () => {
    if (search) {
      fetchAllReports({ queryString: search });
    }
  };

  const linkRef = useRef();

  const handleGetCSV = async (type) => {
    try {
      const res = await (
        await getApi({ baseUrl: window._env_.REACT_APP_QDAK_ORDER_URL })
      ).get(
        `/admin/${
          type === 'aggregated'
            ? 'reports/aggregated'
            : type === 'aggregated-printing'
            ? 'reports/aggregated/printing-price'
            : 'reports'
        }${search ? search : getQueryString(queryParams)}`,
        {
          headers: {
            Accept: 'text/csv',
            'Content-Type': 'text/csv'
          },
          responseType: 'blob'
        }
      );

      const href = window.URL.createObjectURL(new Blob([res.data], { type: 'text/csv; charset=UTF-8' }));

      const a = linkRef.current;
      a.download = `${
        type === 'aggregated'
          ? 'Aggregalt_kimutatas'
          : type === 'aggregated-printing'
          ? 'Nyomtatasi_arak_aggregalt_kimutatasa'
          : 'Kimutatas'
      }_lista ${new Date().toLocaleDateString('hu-HU')} .csv`;
      a.href = href;
      a.click();
      a.href = '';
    } catch (e) {
      console.log(e);
    }
  };

  const resetQueryString = () => {
    navigate(`/superadmin/dashboard${getQueryString(queryParams)}`);
  };

  useEffect(() => {
    refresh();
  }, [search]);

  useEffect(() => {
    fetchAllReports({ queryString: getQueryString(queryParams) });
    fetchAllAggregated({ queryString: getQueryString(queryParams) });
    fetchAllPrinted({ queryString: getQueryString(queryParams) });
  }, []);

  return (
    <Fragment>
      <a ref={linkRef} />
      <Typography
        component='h2'
        variant='h6'
        color='primary'
        gutterBottom
        style={{ fontFamily: 'var(--font-main), Sans-serif, Arial' }}>
        Kimutatások
      </Typography>

      <Zoom in={true} timeout={700}>
        <QueryBuilder
          itemsLength={allReportsLength}
          siteType={'reporting'}
          reset={resetQueryString}
          refresh={refresh}
          OriqueryParams={queryParams}>
          <div className={styles.headerTop}>
            <div className={styles.downloadBar}>
              <Tooltip title={'Kimutatás összesítő lista letöltése a szűrő feltételek szerint'}>
                <Button
                  variant='outlined'
                  disabled={allReports?.length < 1}
                  style={{
                    backgroundColor: 'var(--primary-background-content)',
                    color: 'var(--primary-main)',
                    margin: 0,
                    border: '2px solid var(--primary-background-primary)'
                  }}
                  onClick={() => handleGetCSV()}>
                  <FileDownloadIcon />
                  Kimutatás letöltés
                </Button>
              </Tooltip>
              <Tooltip title={'Kimutatás összesítő lista letöltése a szűrő feltételek szerint'}>
                <Button
                  variant='outlined'
                  disabled={allReports?.length < 1}
                  style={{
                    backgroundColor: 'var(--primary-background-content)',
                    color: 'var(--primary-main)',
                    margin: 0,
                    border: '2px solid var(--primary-background-primary)'
                  }}
                  onClick={() => handleGetCSV('aggregated')}>
                  <FileDownloadIcon />
                  Aggregált kimutatás letöltés
                </Button>
              </Tooltip>
              <Tooltip title={'Kimutatás összesítő lista letöltése a szűrő feltételek szerint'}>
                <Button
                  variant='outlined'
                  disabled={allReports?.length < 1}
                  style={{
                    backgroundColor: 'var(--primary-background-content)',
                    color: 'var(--primary-main)',
                    margin: 0,
                    border: '2px solid var(--primary-background-primary)'
                  }}
                  onClick={() => handleGetCSV('aggregated-printing')}>
                  <FileDownloadIcon />
                  Nyomtatási árak aggregált kimutatás letöltés
                </Button>
              </Tooltip>
            </div>
          </div>

          <table className={styles.header}>
            <thead>
              <tr width='100%'>
                <td className={styles.hideXs} style={{ width: '5%' }}>
                  <Typography className={styles.heading}>#</Typography>
                </td>
                <td className={styles.hideXs} style={{ width: '10%' }}>
                  <Typography className={styles.heading}>Rendelés azonosító</Typography>
                </td>
                <td className={styles.heading}>
                  <Typography className={styles.heading}>Bolt azonosító</Typography>
                </td>
                <td className={styles.heading}>
                  <Typography className={styles.heading}>Ügyfél neve</Typography>
                </td>
                <td className={styles.tableCell}>
                  <Typography className={styles.heading}>Teljes összeg</Typography>
                </td>
                <td className={styles.hideXs}>
                  <Typography className={styles.heading}>Ügyfél profit</Typography>
                </td>
                <td className={styles.hideXs}>
                  <Typography className={styles.heading}>Jutalék</Typography>
                </td>
                <td className={styles.hideXs}>
                  <Typography className={styles.heading}>Nyomtatási költség</Typography>
                </td>
              </tr>
            </thead>
          </table>
          <div className={styles.reportListContainer}>
            <table className={styles.listItemTable}>
              <tbody>
                {allReportsloading ? (
                  <tr>
                    <td>
                      <LoadIndicator color={'var(--primary-light)'} height={800} />
                    </td>
                  </tr>
                ) : allReports?.length > 0 ? (
                  allReports?.map((report, idx) => (
                    <tr className={styles.listItem} width='100%' key={idx}>
                      <td className={styles.hideXs} style={{ width: '5%' }}>
                        <Typography className={styles.tableCell}>{idx + 1}</Typography>
                      </td>
                      <td className={styles.hideXs} style={{ width: '10%' }}>
                        <Typography className={styles.tableCell}>{report?.orderId}</Typography>
                      </td>
                      <td className={styles.tableCell}>
                        <Typography className={styles.tableCell}>{report?.shopId}</Typography>
                      </td>
                      <td className={styles.tableCell}>{report?.userName}</td>

                      <td className={styles.tableCell}>
                        <Typography className={styles.tableCell}>
                          {report?.totalPrice} {report?.currency}
                        </Typography>
                      </td>
                      <td className={styles.hideXs}>
                        <Typography className={styles.tableCell}>
                          {report?.clientProfit} {report?.currency}
                        </Typography>
                      </td>
                      <td className={styles.hideXs}>
                        <Typography className={styles.tableCell}>
                          {report?.commission} {report?.currency}
                        </Typography>
                      </td>
                      <td className={styles.hideXs}>
                        <Typography className={styles.tableCell}>
                          {report?.printingPrice} {report?.currency}
                        </Typography>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr
                    style={{
                      margin: '0.5rem',
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center'
                    }}>
                    <td>
                      <Typography variant='h5' style={{ color: 'var(--primary-light)' }}>
                        Nem érkezett még jelentés.
                      </Typography>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {/*
          <table className={classes.headerTop}>
            <tbody>
              <tr width="100%" className={classes.searchBar}>
                <td
                  className={classes.hideXs}
                  style={{
                    color: "whitesmoke",
                    textAlign: "center",
                  }}
                >
                  <Tooltip
                    title={
                      "Kimutatás összesítő lista letöltése a szűrő feltételek szerint"
                    }
                  >
                    <Button
                      variant="outlined"
                      disabled={allReports?.length < 1}
                      style={{
                        backgroundColor:
                          theme.palette.primary.backgroundContent,
                        color: theme.palette.primary.main,
                        margin: 0,
                        border: `2px solid ${theme.palette.primary.backgroundPrimary}`,
                      }}
                      onClick={() => handleGetCSV("aggregated")}
                    >
                      <FileDownloadIcon />
                      Aggregált kimutatás letöltés
                    </Button>
                  </Tooltip>
                </td>
              </tr>
            </tbody>
          </table>
          <table className={classes.header}>
            <thead>
              <tr width="100%">
                <td className={classes.hideXs} style={{ width: "5%" }}>
                  <Typography className={classes.heading}>#</Typography>
                </td>
                <td className={classes.hideXs}>
                  <Typography className={classes.heading}>
                    Teljes forgalom
                  </Typography>
                </td>
                <td className={classes.heading}>
                  <Typography className={classes.heading}>
                    Jutalék
                  </Typography>
                </td>
                <td className={classes.heading}>
                  <Typography className={classes.heading}>
                    Nyomtatás díja
                  </Typography>
                </td>
                <td className={classes.tableCell}>
                  <Typography className={classes.heading}>
                    Bruttó profit
                  </Typography>
                </td>
                <td className={classes.hideXs}>
                  <Typography className={classes.heading}>
                    Nettó profit
                  </Typography>
                </td>
                <td className={classes.hideXs}>
                  <Typography className={classes.heading}>Bolt azonosító</Typography>
                </td>
                <td className={classes.hideXs}>
                  <Typography className={classes.heading}>
                    Fotós
                  </Typography>
                </td>
                <td className={classes.hideXs}>
                  <Typography className={classes.heading}>
                    Adószám
                  </Typography>
                </td>
                <td className={classes.hideXs}>
                  <Typography className={classes.heading}>
                    Számlázási cím
                  </Typography>
                </td>
              </tr>
            </thead>
          </table>
          <ReportListWrapper>
            <table className={classes.listitemtable}>
              <tbody>
                {allAggregateloading ? (
                  <tr>
                    <td>
                      <LoadIndicator
                        color={theme.palette.primary.light}
                        height={800}
                      />
                    </td>
                  </tr>
                ) : allAggregated?.length > 0 ? (
                  allAggregated?.map((report, idx) => (
                    <tr
                      className={classes.listItem}
                      width="100%"
                      key={report.id}
                      onClick={() => {
                        routeChange(report.id);
                      }}
                    >
                      <td className={classes.hideXs} style={{ width: "5%" }}>
                        <Typography className={classes.tableCell}>
                          {idx + 1}
                        </Typography>
                      </td>
                      <td className={classes.hideXs}>
                        <Typography className={classes.tableCell}>
                          {report?.totalPrice }
                        </Typography>
                      </td>
                      <td className={classes.tableCell}>
                        <Typography className={classes.tableCell}>
                          {report?.commission}
                        </Typography>
                      </td>
                      <td className={classes.tableCell}>
                        <Typography className={classes.tableCell}>
                          {report?.printingPrice}
                        </Typography>
                      </td>
                      <td className={classes.tableCell}>
                        <Typography className={classes.tableCell}>
                          {report?.clientProfitGross}
                        </Typography>
                      </td>
                      <td className={classes.hideXs}>
                        <Typography className={classes.tableCell}>
                          {report?.clientProfitNet}
                        </Typography>
                      </td>
                      <td className={classes.hideXs}>
                        <Typography className={classes.tableCell}>
                          {report?.shopId}
                        </Typography>
                      </td>
                      <td className={classes.hideXs}>
                        <Typography className={classes.tableCell}>
                          {report?.shopOwner}
                        </Typography>
                      </td>
                      <td className={classes.hideXs}>
                        <Typography className={classes.tableCell}>
                          {report?.vatNumber}
                        </Typography>
                      </td>
                      <td className={classes.hideXs}>
                        <Typography className={classes.tableCell}>
                          {report?.billingAddress}
                        </Typography>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr
                    style={{
                      margin: "0.5rem",
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <td>
                      <Typography
                        variant="h5"
                        style={{ color: theme?.palette?.primary?.light }}
                      >
                        Nem érkezett még jelentés.
                      </Typography>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </ReportListWrapper>
          <table className={classes.headerTop}>
            <tbody>
              <tr width="100%" className={classes.searchBar}>
                <td
                  className={classes.hideXs}
                  style={{
                    color: "whitesmoke",
                    textAlign: "center",
                  }}
                >
                  <Tooltip
                    title={
                      "Kimutatás összesítő lista letöltése a szűrő feltételek szerint"
                    }
                  >
                    <Button
                      variant="outlined"
                      disabled={allReports?.length < 1}
                      style={{
                        backgroundColor:
                          theme.palette.primary.backgroundContent,
                        color: theme.palette.primary.main,
                        margin: 0,
                        border: `2px solid ${theme.palette.primary.backgroundPrimary}`,
                      }}
                      onClick={() => handleGetCSV("aggregated-printing")}
                    >
                      <FileDownloadIcon />
                      Nyomtatási árak aggregált kimutatás letöltés
                    </Button>
                  </Tooltip>
                </td>
              </tr>
            </tbody>
          </table>
          <table className={classes.header}>
            <thead>
              <tr width="100%">
                <td className={classes.hideXs} style={{ width: "5%" }}>
                  <Typography className={classes.heading}>#</Typography>
                </td>
                <td className={classes.hideXs}>
                  <Typography className={classes.heading}>
                    Partner típus
                  </Typography>
                </td>
                <td className={classes.heading}>
                  <Typography className={classes.heading}>
                    Nyomtatot összeg
                  </Typography>
                </td>
                <td className={classes.heading}>
                  <Typography className={classes.heading}>
                    Valuta
                  </Typography>
                </td>
              </tr>
            </thead>
          </table>
          <ReportListWrapper>
            <table className={classes.listitemtable}>
              <tbody>
                {allPrintsloading ? (
                  <tr>
                    <td>
                      <LoadIndicator
                        color={theme.palette.primary.light}
                        height={800}
                      />
                    </td>
                  </tr>
                ) : allPrints?.length > 0 ? (
                  allPrints?.map((report, idx) => (
                    <tr
                      className={classes.listItem}
                      width="100%"
                      key={report.id}
                      onClick={() => {
                        routeChange(report.id);
                      }}
                    >
                      <td className={classes.hideXs} style={{ width: "5%" }}>
                        <Typography className={classes.tableCell}>
                          {idx + 1}
                        </Typography>
                      </td>
                      <td className={classes.hideXs}>
                        <Typography className={classes.tableCell}>
                          {report?.deliveryType}
                        </Typography>
                      </td>
                      <td className={classes.tableCell}>
                        <Typography className={classes.tableCell}>
                          {report?.printingPrice}
                        </Typography>
                      </td>
                      <td className={classes.tableCell}>
                        <Typography className={classes.tableCell}>
                          {report?.currency}
                        </Typography>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr
                    style={{
                      margin: "0.5rem",
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <td>
                      <Typography
                        variant="h5"
                        style={{ color: theme?.palette?.primary?.light }}
                      >
                        Nem érkezett még jelentés.
                      </Typography>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </ReportListWrapper>
          */}
        </QueryBuilder>
      </Zoom>

      <div className={styles.seeMore}>
        <Button variant='contained' color='primary' onClick={() => navigate(`/superadmin/dashboard`)}>
          Több jelentés megtekintése
        </Button>
      </div>
    </Fragment>
  );
};

export default Reports;
