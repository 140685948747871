import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Edit from '@material-ui/icons/Edit';
import Button from '@material-ui/core/Button';
import { useEffect, useState, useRef } from 'react';
import SettingsCard from 'components/PhotoAdminComponents/Settings/Card/SettingsCard';
import ActionButton from 'components/common/ActionButton/ActionButton';
import { Form, Formik } from 'formik';
import { CircularProgress } from '@material-ui/core';
import billingAddressChangeSchema from 'validation/UserSettings/BillingAddressChangeSchema';
import { useUserApi } from 'hooks/useUserApi';
import PostalCodeFields from '../../PostalCodeFields/PostalCodeFields';
import styles from './ChangeContactAddressCard.module.css';

const ChangeContactAddressCard = ({ user, setInProgress, inProgress, showDialog }) => {
  const [isEditing, setIsEditing] = useState(false);
  const toggleIsEditing = () => setIsEditing(!isEditing);
  const formikRef = useRef(null);
  const { getCurrentUser, putUserAddress } = useUserApi();

  const [contactAddressData, setContactAddressData] = useState({
    city: '',
    zipCode: '',
    streetAndNumber: '',
    country: ''
  });

  const putContactAddressWithDialog = async (body) => {
    setInProgress('contactAddress');
    const status = await putUserAddress(body);
    getCurrentUser();
    showDialog(status);
    setInProgress(null);
  };

  useEffect(() => {
    if (user && formikRef.current) {
      formikRef.current.values.city = contactAddressData?.city;
      formikRef.current.values.country = contactAddressData?.country;
      formikRef.current.values.zipCode = contactAddressData?.zipCode;
      formikRef.current.values.streetAndNumber = contactAddressData?.streetAndNumber;
    }
  }, [contactAddressData, formikRef]);

  useEffect(() => {
    if (isEditing) {
      setContactAddressData({
        city: '',
        zipCode: '',
        streetAndNumber: '',
        country: 'Magyarország'
      });
    } else {
      setContactAddressData({
        city: user?.contact?.address?.city,
        country: user?.contact?.address?.country,
        zipCode: user?.contact?.address?.zipCode,
        streetAndNumber: user?.contact?.address?.streetAndNumber
      });
    }
  }, [isEditing, user]);

  return (
    <SettingsCard
      title={'Kapcsolattartási cím'}
      actionButton={
        <ActionButton
          icon={<Edit color='primary' />}
          clickHandler={toggleIsEditing}
          className={styles.actionButtonStyle}
        />
      }>
      <Formik
        innerRef={formikRef}
        enableReinitialize
        initialValues={{
          city: '',
          country: 'Magyarország',
          zipCode: '',
          streetAndNumber: ''
        }}
        validationSchema={billingAddressChangeSchema}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          await putContactAddressWithDialog({
            ...user,
            contact: {
              ...user.contact,
              address: {
                ...user.contact.address,
                city: values.city,
                zipCode: values.zipCode,
                country: values.country,
                streetAndNumber: values.streetAndNumber
              }
            }
          });
          setIsEditing(false);
          setSubmitting(false);
        }}>
        {({ values, errors, touched, handleChange, handleSubmit, isSubmitting }) => (
          <Form className={styles.form}>
            <Grid container spacing={2}>
              <PostalCodeFields
                data={contactAddressData}
                setData={setContactAddressData}
                disabled={!isEditing}
                zipHelper={formikRef?.current?.touched?.zipCode}
                zipError={formikRef?.current?.errors?.zipCode}
                cityHelper={formikRef?.current?.touched?.city}
                cityError={formikRef?.current?.errors?.city}
              />

              <Grid item xs={12} md={6}>
                <TextField
                  disabled={!isEditing}
                  variant='outlined'
                  fullWidth
                  required
                  label='Utca, házszám'
                  id='streetAndNumber'
                  name='streetAndNumber'
                  type='text'
                  value={isEditing ? values.streetAndNumber : contactAddressData?.streetAndNumber}
                  onChange={handleChange}
                  error={touched.streetAndNumber && Boolean(errors.streetAndNumber)}
                  helperText={touched.streetAndNumber && errors.streetAndNumber}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  disabled
                  variant='outlined'
                  fullWidth
                  required
                  label='Ország'
                  id='country'
                  name='country'
                  type='text'
                  value={values.country}
                  onChange={handleChange}
                  error={touched.country && Boolean(errors.country)}
                  helperText={touched.country && errors.country}
                />
              </Grid>
            </Grid>
            <Grid container className={styles.gridContainer}>
              <Grid item xs={12}>
                <Button disabled={!isEditing} type='submit' fullWidth onClick={handleSubmit} variant='outlined'>
                  {inProgress === 'contactAddress' ? (
                    <>
                      Folyamatban
                      <CircularProgress style={{ marginLeft: '0.5rem' }} size={24} />
                    </>
                  ) : (
                    'Cím frissítése'
                  )}
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </SettingsCard>
  );
};

export default ChangeContactAddressCard;
