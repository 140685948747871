import styles from './ChangeContactPhoneCard.module.css';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Edit from '@material-ui/icons/Edit';
import Button from '@material-ui/core/Button';
import { useEffect, useState, useRef } from 'react';
import { Form, Formik } from 'formik';
import { CircularProgress, InputAdornment } from '@material-ui/core';
import SettingsCard from 'components/PhotoAdminComponents/Settings/Card/SettingsCard';
import ActionButton from 'components/common/ActionButton/ActionButton';
import phoneChangeValidationSchema from 'validation/UserSettings/PhoneChangeValidationSchema';
import { useUserApi } from 'hooks/useUserApi';

const ChangeContactPhoneCard = ({ user, setInProgress, inProgress, showDialog }) => {
  const [isEditing, setIsEditing] = useState(false);
  const toggleIsEditing = () => setIsEditing(!isEditing);
  const formikRef = useRef(null);
  const [phoneData, setPhoneData] = useState({ phone: '' });
  const { getCurrentUser, putUserPhone } = useUserApi();

  const putPhoneWithDialog = async (body) => {
    setInProgress('phone');
    const status = await putUserPhone(body);
    getCurrentUser();
    showDialog(status);
    setInProgress(null);
  };

  useEffect(() => {
    if (user && formikRef.current) {
      formikRef.current.values.phone = phoneData?.phone;
    }
  }, [user, formikRef]);

  useEffect(() => {
    if (isEditing) {
      setPhoneData({ phone: '' });
    } else {
      setPhoneData({
        phone: user?.contact?.phone
      });
    }
  }, [user, isEditing]);

  return (
    <SettingsCard
      title='Telefonszám'
      actionButton={
        <ActionButton
          icon={<Edit color='primary' />}
          clickHandler={toggleIsEditing}
          className={styles.actionButtonStyle}
        />
      }>
      <Formik
        innerRef={formikRef}
        enableReinitialize
        initialValues={{
          phone: ''
        }}
        validationSchema={phoneChangeValidationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);

          await putPhoneWithDialog({
            ...user,
            contact: {
              ...user?.contact,
              phone: values.phone
            }
          });
          setIsEditing(false);
          setSubmitting(false);
        }}>
        {({ values, errors, touched, handleChange, handleSubmit, isSubmitting }) => (
          <Form className={styles.form}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  disabled={!isEditing}
                  variant='outlined'
                  fullWidth
                  required
                  label='Telefonszám'
                  id='phone'
                  name='phone'
                  InputProps={{
                    startAdornment: <InputAdornment position='start'>+36</InputAdornment>
                  }}
                  value={isEditing ? values.phone : phoneData?.phone}
                  onChange={handleChange}
                  error={touched.phone && Boolean(errors.phone)}
                  helperText={touched.phone && errors.phone}
                />
              </Grid>
            </Grid>
            <Grid container className={styles.gridContainer}>
              <Grid item xs={12}>
                <Button disabled={!isEditing} type='submit' fullWidth onClick={handleSubmit} variant='outlined'>
                  {inProgress === 'phone' ? (
                    <>
                      Folyamatban
                      <CircularProgress style={{ marginLeft: '0.5rem' }} size={24} />
                    </>
                  ) : (
                    'Telefonszám frissítése'
                  )}
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </SettingsCard>
  );
};

export default ChangeContactPhoneCard;
