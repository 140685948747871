import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import styles from './Overlay.module.css';
import { useSelector } from 'react-redux';

const Overlay = () => {
  const location = useLocation();
  const currentLocation = location.pathname.split('/').pop();
  const shopTier = useSelector((state) => state.nonPersistedReducers?.shopTierSliceReducer?.shopTier);

  const calculateOverlay = () => {
    return (
      (currentLocation === 'printing' && !shopTier?.printOption) ||
      (currentLocation === 'reports' && !shopTier?.reports) ||
      (currentLocation === 'invoicing' && !shopTier?.automaticInvoice)
    );
  };

  return (
    <div className={styles.container}>
      {calculateOverlay() && (
        <div className={styles.overlay}>
          <div className={styles.overlayText}>
            Ezt az oldalt a jelenlegi előfizetésed nem támogatja. Javasoljuk hogy válts nagyobb csomagra!
          </div>
        </div>
      )}
      <div className={styles.content}>
        <Outlet />
      </div>
    </div>
  );
};

export default Overlay;
