import { Button } from '@material-ui/core';
import React from 'react';
import styles from './TemplateBubbles.module.css';
import { Grow } from '@mui/material';

const TemplateBubbles = ({ setIsTemplate, isOverlayed = false }) => {
  return (
    <Grow in={true}>
      <div className={isOverlayed ? styles.rightOverlay : styles.right}>
        <div className={styles.rightUpper}>
          <h3 className={styles.h3}>Árazási sablonok</h3>
          <div className={styles.title}>Jelenleg használt sablonok</div>
          <div className={styles.templateContainer}>
            <div className={styles.templateBubble}>Andrássy gimi</div>
            <div className={styles.templateBubble}>Kis gimi</div>
            <div className={styles.templateBubble}>Béla gimi</div>
          </div>
          <div className={styles.title}>Nem használt sablonok</div>
          <div className={styles.templateContainer}>
            <div className={styles.templateBubble}>Andrássy gimi</div>
            <div className={styles.templateBubble}>Nagy gimi</div>
            <div className={styles.templateBubble}>Bála gimi</div>
            <div className={styles.templateBubble}>Petőfi gimi</div>
            <div className={styles.templateBubble}>Egyszer használt sablon</div>
          </div>
        </div>
        <Button
          className={styles.buttonDark}
          onClick={() => {
            setIsTemplate(true);
          }}>
          Sablonok szerkesztése
        </Button>
      </div>
    </Grow>
  );
};

export default TemplateBubbles;
