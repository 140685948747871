import { Tooltip } from '@material-ui/core';
import { useEffect } from 'react';
import { useUploaderBl } from './hooks/useUploaderBl';
import styles from './Uploader.module.css';
import { useDispatch } from 'react-redux';
import { resetErrorCount } from 'redux/slices/UploadSlice';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const Uploader = ({ group, fetchProducts, products, disabled, isDemo }) => {
  const { fileInputRef, handleInputClick, handleFileUpload } = useUploaderBl();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetErrorCount());
  }, [group, dispatch]);

  return (
    <div className={styles.mainContainer}>
      {disabled ? (
        <Tooltip
          title={
            isDemo
              ? 'Elérted demo galériád képfeltöltési limitjét! Ha több képet szeretnél feltölteni, élesítsd boltodat!'
              : 'Elérted jelenlegi előfizetésed feltöltési limitjét. Amennyiben több képet szeretnél feltölteni, válts nagyobb csomagra.'
          }>
          <div className={styles.uploadButton} onClick={() => {}}>
            <CloudUploadIcon className={styles.uploadIcon} />
            <p className={styles.uploadText}>Feltöltés letiltva</p>
          </div>
        </Tooltip>
      ) : (
        <div className={styles.uploadButton} onClick={handleInputClick}>
          <CloudUploadIcon className={styles.uploadIcon} />
          <p className={styles.uploadText}>Feltöltés</p>
        </div>
      )}
      <input
        ref={fileInputRef}
        hidden
        type='file'
        accept='.jpg, .jpeg'
        onChange={(e) => {
          handleFileUpload(e, group?.id, group?.modifiers, fetchProducts, products);
        }}
        multiple
      />
    </div>
  );
};

export default Uploader;
