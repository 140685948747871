import React, { useEffect, useState, useRef } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Edit from '@material-ui/icons/Edit';
import Button from '@material-ui/core/Button';
import { Form, Formik } from 'formik';
import { CircularProgress } from '@material-ui/core';
import SettingsCard from 'components/PhotoAdminComponents/Settings/Card/SettingsCard';
import ActionButton from 'components/common/ActionButton/ActionButton';
import companyNameChangeSchema from 'validation/UserSettings/CompanyNameChangeSchema';
import { useUserApi } from 'hooks/useUserApi';
import styles from './ChangeBillingNameCard.module.css';

const ChangeBillingNameCard = ({ user, setInProgress, inProgress, showDialog }) => {
  const [isEditing, setIsEditing] = useState(false);
  const toggleIsEditing = () => setIsEditing(!isEditing);
  const formikRef = useRef(null);
  const { getCurrentUser, putUserBillingName } = useUserApi();

  const putBillingNameWithDialog = async (body) => {
    setInProgress('billingName');
    const status = await putUserBillingName(body);
    getCurrentUser();
    showDialog(status);
    setInProgress(null);
  };

  useEffect(() => {
    if (user && formikRef.current) {
      formikRef.current.values.companyName = user?.billingInfo?.companyName;
    }
  }, [user, formikRef]);

  return (
    <SettingsCard
      title={'Számlázási név'}
      actionButton={
        <ActionButton
          icon={<Edit color='primary' />}
          clickHandler={toggleIsEditing}
          className={styles.actionButtonStyle}
        />
      }>
      <Formik
        innerRef={formikRef}
        enableReinitialize
        initialValues={{
          companyName: ''
        }}
        validationSchema={companyNameChangeSchema}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          await putBillingNameWithDialog({
            ...user,
            billingInfo: {
              ...user?.billingInfo,
              companyName: values.companyName
            }
          });
          setIsEditing(false);
          setSubmitting(false);
        }}>
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
          <Form className={styles.form}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  disabled={!isEditing}
                  variant='outlined'
                  fullWidth
                  required
                  label={'Számlázási név'}
                  id='companyName'
                  name='companyName'
                  value={values.companyName}
                  onChange={handleChange}
                  error={touched.companyName && Boolean(errors.companyName)}
                  helperText={touched.companyName && errors.companyName}
                />
              </Grid>
            </Grid>
            <Grid container className={styles.gridContainer}>
              <Grid item xs={12}>
                <Button disabled={!isEditing} type='submit' fullWidth onClick={handleSubmit} variant='outlined'>
                  {inProgress === 'billingName' ? (
                    <>
                      Folyamatban
                      <CircularProgress style={{ marginLeft: '0.5rem' }} size={24} />
                    </>
                  ) : (
                    'Név frissítése'
                  )}
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </SettingsCard>
  );
};

export default ChangeBillingNameCard;
