import React from 'react';
import { useState } from 'react';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Button, IconButton, Tooltip, Typography } from '@material-ui/core';
import styles from './QueryParam.module.css';
import { useEffect } from 'react';
import QueryParamEditor from '../QueryParamEditor/QueryParamEditor';

const QueryParam = ({ option, setOption, disableOption, setIsAddDisabled }) => {
  const [isOpen, setIsOpen] = useState(option.value === '');
  const [hover, setHover] = useState(false);
  const toggleisOpen = () => setIsOpen(!isOpen);
  const [displayTitle, setDisplayTitle] = useState('');
  const [tempOption, setTempOption] = useState(option);

  const getDateString = (date) => {
    const options = { timeZone: 'UTC' };

    return new Date(date).toLocaleDateString('hu-HU', options);
  };

  useEffect(() => {
    setIsAddDisabled(isOpen);
  }, [isOpen]);
  const getDisplayFields = (key, value) => {
    const displayKey = option.keys.find((k) => k.name === key).displayName;
    const displayValue = option.values.find((k) => k.name === value).displayName;
    return [displayKey, displayValue];
  };

  const getDisplayedSelectValues = (value) => {
    let displayedValue = '';
    option.values.forEach((k, idx) => {
      if (k.toString() === value) {
        displayedValue = option.displayedValues[idx];
      }
    });
    return displayedValue;
  };
  useEffect(() => {
    setTempOption(option);
  }, [option]);

  useEffect(() => {
    switch (tempOption.type) {
      case 'date':
        const params = tempOption.value.split('&');
        const values = params.map((param) => getDateString(param.split('=')[1]));
        const fromDate = values[0];
        const toDate = values[1];
        setDisplayTitle(`${tempOption.displayName}${tempOption.value !== '' ? `= ${fromDate}-tól ${toDate}-ig` : ''}`);
        break;
      case 'select':
        if (tempOption.value !== '') {
          const value = tempOption.value.split('=')[1];
          const displayedName = getDisplayedSelectValues(value);
          setDisplayTitle(`${tempOption.displayName}${tempOption.value !== '' ? `= ${displayedName}` : ''}`);
        } else {
          setDisplayTitle(`${tempOption.displayName}`);
        }
        break;
      case 'reportingDate':
        const reportinParams = tempOption.value.split('&');
        const reportingvalues = reportinParams.map((param) => getDateString(param.split('=')[1]));
        const rFromDate = reportingvalues[0];
        const rtoDate = reportingvalues[1];
        setDisplayTitle(
          `${tempOption.displayName}${tempOption.value !== '' ? `= ${rFromDate}-tól ${rtoDate}-ig` : ''}`
        );
        break;
      case 'key-value':
        if (tempOption.value !== '') {
          const key = tempOption.value.split('=')[1].split(',')[0];
          const value = tempOption.value.split('=')[1].split(',')[1];
          const [displayKey, displayName] = getDisplayFields(key, value);
          setDisplayTitle(
            `${tempOption.displayName}${tempOption.value !== '' ? `= ${displayKey}, ${displayName}` : ''}`
          );
        } else {
          setDisplayTitle(`${tempOption.displayName}`);
        }
        break;
      case 'pagination':
        break;
      default:
        /*if(tempOption.value === "isActive=ISPRINTED"){
          tempOption.value = "isPrinted=true"
        }else if (tempOption.value === "isActive=ISPRINTEDDONE"){
          tempOption.value = "isPrintedDone=true"
        }*/
        setDisplayTitle(
          decodeURI(
            `${tempOption.displayName}${
              tempOption.value !== '' && tempOption.value === 'isActive=true'
                ? `= AKTÍV`
                : tempOption.value !== '' && tempOption.value === 'isActive=false'
                ? '= INAKTÍV'
                : tempOption.value !== '' && tempOption.value === 'isActive=ISPRINTED'
                ? '= NYOMTATÁSRA VÁR'
                : tempOption.value !== '' && tempOption.value === 'isActive=ISPRINTINGDONE'
                ? '= NYOMTATVA'
                : tempOption.value !== '' && tempOption.value === 'isActive=ISNOTPRINTED'
                ? '= NYOMTATHATÓ'
                : tempOption.value !== '' &&
                  (tempOption.value !== 'isActive=false' ||
                    tempOption.value !== 'isActive=true' ||
                    tempOption.value !== 'isActive=ISPRINTED' ||
                    tempOption.value !== 'isActive=ISPRINTINGDONE' ||
                    tempOption.value !== 'isActive=ISNOTPRINTED')
                ? `= ${tempOption.value.split('=')[1]}`
                : ''
            }`
          )
        );
        break;
    }
  }, [tempOption]);

  const confirm = () => {
    setOption(tempOption);
  };

  return (
    <Button variant='outlined' className={styles.button}>
      <Tooltip title={`${option.displayName} szerkesztése`}>
        <Typography onClick={toggleisOpen} variant='body1' className={styles.typograhy}>
          {displayTitle}
        </Typography>
      </Tooltip>

      <IconButton
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        className={styles.iconButton}
        style={{ color: hover ? 'var(--primary-error)' : 'var(--primary-main)' }}
        onClick={(e) => {
          disableOption();
          setIsOpen(false);
          setIsAddDisabled(false);
          e.stopPropagation();
        }}>
        <HighlightOffIcon />
      </IconButton>

      {isOpen && (
        <div className={styles.editorContainer}>
          <QueryParamEditor tempOption={tempOption} setTempOption={setTempOption} />
          <Button
            color='primary'
            variant='outlined'
            disabled={tempOption.value === ''}
            onClick={() => {
              setIsOpen(false);
              setIsAddDisabled(false);
              confirm();
            }}>
            Ok
          </Button>
        </div>
      )}
    </Button>
  );
};

export default QueryParam;
