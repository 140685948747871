import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import styles from './SettingsCard.module.css';

const SettingsCard = ({ children, title, actionButton }) => {
  return (
    <Paper className={styles.root}>
      <CssBaseline />
      <Grid container>
        <Grid container>
          <Grid item xs={10}>
            <Typography
              variant="h5"
              color="primary"
              style={{
                fontFamily: `var(--font-main), Sans-serif, Arial`,
              }}
            >
              {title}
            </Typography>
          </Grid>
          <Grid item xs={2} align="end">
            {actionButton ? actionButton : <></>}
          </Grid>
        </Grid>
        <Grid container>{children}</Grid>
      </Grid>
    </Paper>
  );
};

export default SettingsCard;
