import styles from './CheckIcon.module.css';
import { useEffect, useState } from 'react';

const CheckIcon = ({ position, right, bottom, size, speedy }) => {
  const [start, setStart] = useState(null);

  const timeout = setTimeout(
    () => {
      setStart(true);
    },
    speedy ? 0 : 2000
  );

  useEffect(() => {
    return () => {
      clearTimeout(timeout);
    };
  }, []);
  return (
    <div
      className={styles.iconWrapper}
      style={{
        position: position && position,
        right: right && right,
        bottom: bottom && bottom
      }}>
      {start && (
        <svg
          className={styles.successCheck}
          style={{ width: size && `${size}px`, height: size && `${size}px` }}
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 52 52'>
          <circle className={styles.successCheckCircle} cx='26' cy='26' r='25' fill='none' />
          <path className={styles.succcesCheckCheck} fill='none' d='M14.1 27.2l7.1 7.2 16.7-16.8' />
        </svg>
      )}
    </div>
  );
};

export default CheckIcon;
