import { useModifierSettingsAPI } from './hooks/useModifierSettingsAPI';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import styles from './ModifierInvoiceVendors.module.css';
import AddIcon from '@mui/icons-material/Add';
import DoneIcon from '@mui/icons-material/Done';
import DeleteIcon from '@mui/icons-material/Delete';
import { Typography, Paper, CircularProgress } from '@material-ui/core';
import { Info } from '@material-ui/icons';

const ModifierInvoiceVendors = () => {
  const shopId = useSelector((state) => state.nonPersistedReducers?.shopSliceReducer?.shopId);

  const [modifierHereID, setModifierHereID] = useState('');
  const [vendorHere, setVendorHere] = useState('');

  const {
    getModifiers,
    getInvoiceVendors,
    getModInvoiceVendors,
    postModInvoiceVendor,
    deleteModInvoiceVendor,
    getUriId,
    modifiers,
    invoiceVendors,
    modInvoiceVendors,
    modInvoiceVendorsIDS,
    modInvoiceVendorsLoading,
    unsetModis
  } = useModifierSettingsAPI();

  useEffect(() => {
    getModifiers();
    getInvoiceVendors();
    getModInvoiceVendors();
  }, [shopId]);

  const handleChangeModifier = (event) => {
    setModifierHereID(event.target.value);
  };
  const handleChangeVendor = (event) => {
    setVendorHere(event.target.value);
  };
  const handleAdd = () => {
    postModInvoiceVendor({
      id: null,
      shopId: shopId,
      modifierId: getUriId(modifiers.find((el) => el.id === modifierHereID).modifierUriSegment),
      invoiceVendorId: vendorHere
    });
    setModifierHereID('');
    setVendorHere('');
  };
  const handleDelete = (id) => {
    deleteModInvoiceVendor(id);
  };

  return (
    <div className={styles.mainContainer}>
      {unsetModis.length > 0 && (
        <Typography style={{ color: 'red' }}>
          <Info /> Figyelem! Van olyan terméked, amely egyik számlázási kiszolgálódhoz sincs beállítva, így ezen
          termékekről számlát sem tudunk automatikusan kiállítani!
        </Typography>
      )}
      <Paper className={styles.paperContainer}>
        <div className={styles.leftContainer}>
          <div className={styles.leftInnerContainer}>
            <FormControl sx={{ m: 1, minWidth: 250 }} className={styles.flexItemMain}>
              <InputLabel id='products'>Termék típusok</InputLabel>
              <Select
                labelId='products'
                className={styles.dropdown}
                value={modifierHereID}
                label='Termék típusok'
                onChange={handleChangeModifier}>
                {modifiers?.map((el) =>
                  !modInvoiceVendorsIDS?.includes(getUriId(el.modifierUriSegment)) && el.priority !== 1 ? (
                    <MenuItem key={el.id} value={el.id}>
                      {el.name}
                    </MenuItem>
                  ) : (
                    <></>
                  )
                )}
              </Select>
            </FormControl>
            <div className={styles.flexGrow}></div>
            <FormControl sx={{ m: 1, minWidth: 250 }} className={styles.flexItemMain}>
              <InputLabel id='vendors'>Számlázási vendor</InputLabel>
              <Select
                labelId='vendors'
                className={styles.dropdown}
                value={vendorHere}
                label='Számlázási vendor'
                onChange={handleChangeVendor}>
                {invoiceVendors?.map((el) => {
                  return (
                    <MenuItem key={el.id} value={el.id}>
                      {el.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <div className={styles.flexGrow}></div>
          </div>
          <div className={styles.leftInnerContainer}>
            {modifierHereID === '' ? (
              <div className={styles.flexItemMain}></div>
            ) : (
              <div className={styles.flexItemMain}>
                <div className={styles.boxVendorL}>{modifiers?.find((el) => el.id === modifierHereID)?.name}</div>
              </div>
            )}
            {modifierHereID !== '' || vendorHere !== '' ? (
              <div className={styles.flexGrow}>
                <AddIcon fontSize='large'></AddIcon>
              </div>
            ) : (
              <div></div>
            )}
            {vendorHere === '' ? (
              <div className={styles.flexItemMain}></div>
            ) : (
              <div className={styles.flexItemMain}>
                <div className={styles.boxVendorL}>{invoiceVendors?.find((el) => el.id === vendorHere)?.name}</div>
              </div>
            )}
            {modifierHereID !== '' && vendorHere !== '' ? (
              <div className={styles.flexGrow}>
                <DoneIcon fontSize='large' onClick={handleAdd} className={styles.buttonT}></DoneIcon>
              </div>
            ) : (
              <div></div>
            )}
          </div>
        </div>
        <div className={styles.rightContainer}>
          {modInvoiceVendorsLoading ? (
            <CircularProgress></CircularProgress>
          ) : modInvoiceVendors == null || modInvoiceVendors?.length < 1 ? (
            <div>Nincs elérhető adat</div>
          ) : (
            modInvoiceVendors?.map((vendors) => {
              return (
                <div className={styles.innerRight} key={vendors.id}>
                  <div className={styles.boxVendorR}>
                    <div>{modifiers?.find((el) => getUriId(el.modifierUriSegment) === vendors.modifierId)?.name}</div>
                    <div>{invoiceVendors?.find((el) => el.id === vendors.invoiceVendorId)?.name}</div>
                  </div>
                  <div>
                    <DeleteIcon
                      fontSize='large'
                      onClick={() => handleDelete(vendors.id)}
                      className={styles.buttonD}></DeleteIcon>
                  </div>
                </div>
              );
            })
          )}
        </div>
      </Paper>
    </div>
  );
};

export default ModifierInvoiceVendors;
