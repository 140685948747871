/** @format */

import { Fragment, useEffect, useState } from 'react';
import {
  Paper,
  TextField,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';
import { Checkbox } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import styles from './ModifierForm.module.css';
import ActualCommission from './ActualCommission/ActualCommission';
import { useModificationLogic } from './hooks/useModificationLogic';
import { useGroupAPI } from 'pages/PhotoAdmin/SelectShop/hooks/useGroupAPI';

const ModifierForm = ({
  templateForm,
  modifiers,
  changeChecker,
  setModifiers,
  buttonRef,
  inherit,
  belongsTo,
  setDisabled
}) => {
  const {
    getModificationList,
    setModification,
    setModificationAvailable,
    getModificationEnabled,
    getMinimals,
    minimals,
    getPrintingPrice,
    printingPrices,
    getUriId
  } = useModificationLogic();

  const { fetchHeader } = useGroupAPI();

  useEffect(() => {
    if (typeof belongsTo !== 'undefined' && belongsTo) {
      fetchHeader({ id: belongsTo.id });
    }
    getPrintingPrice();
  }, []);

  useEffect(() => {
    if (printingPrices !== null && printingPrices !== undefined && modifiers !== null && modifiers !== undefined) {
      getMinimals(modifiers);
    }
  }, [printingPrices, modifiers]);

  const [modificationList, setModificationList] = useState([]);

  const correctPriceChecker = () => {
    if (minimals) {
      if (
        modifiers
          ?.filter((modifier) => modifier.enabled)
          ?.find(
            (modifier) =>
              modifier?.unitPrice?.grossAmount <
              minimals?.find((minimal) => minimal.modifierId === getUriId(modifier.modifierUriSegment))?.price
                ?.grossAmount
          )
      ) {
        setDisabled(true);
      } else {
        setDisabled(false);
      }
    }
  };

  useEffect(() => {
    setModificationList(getModificationList(modifiers));
    if (templateForm) {
      changeChecker();
    }
    correctPriceChecker();
  }, [modifiers]);

  const checkFormValidity = async () => {
    let valid = true;
    modifiers.forEach(async (modifier) => {
      const element = await document.getElementById(`price_${modifier.id}`);
      if (element !== null && !element.validity.valid) {
        valid = false;
      }
    });
    return valid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (await checkFormValidity()) {
      setModifiers(modifiers);
      return true;
    } else {
      return false;
    }
  };

  return (
    <TableContainer className={styles.tablecontainer} component={Paper}>
      <form className={styles.table} onSubmit={async (e) => await handleSubmit(e)}>
        <Table aria-label='simple table' size='small'>
          <TableHead>
            <TableRow>
              <TableCell className={styles.customCell}>Elérhető?</TableCell>
              <TableCell className={styles.nameCell}>Megnevezés</TableCell>
              <Tooltip title={'Ezen az áron kerülnek eladásra a képeid!'}>
                <TableCell className={styles.customCell}>
                  Bruttó Értékesítési Ár (HUF)
                  <InfoIcon
                    style={{
                      fontSize: '0.9rem',
                      opacity: '0.5',
                      marginLeft: '10px'
                    }}
                  />
                </TableCell>
              </Tooltip>
              {/*
                            IF MINIMAL PRICE COLUMN IS NEEDED
                            <Tooltip title={"Minimális Ár!"}>
                                <TableCell className={classes.customCell}>
                                    Minimális ár (HUF)
                                    <InfoIcon
                                        style={{
                                            fontSize: "0.9rem",
                                            opacity: "0.5",
                                            marginLeft: "10px",
                                        }}
                                    />
                                </TableCell>
                            </Tooltip> */}
              <Tooltip title={'Ez az összeg lesz a bevételed az adott típusú képeknél!'}>
                <TableCell className={styles.customCell}>
                  Bruttó Bevétel (HUF)
                  <InfoIcon
                    style={{
                      fontSize: '0.9rem',
                      opacity: '0.5',
                      marginLeft: '10px'
                    }}
                  />
                </TableCell>
              </Tooltip>
              {/*
                            FOR VATRATE HANDLING
                            <Tooltip
                                title={
                                    "Itt adhatja meg milyen áfa kulccsal kívánja árusítani az adott típusó fényképet"
                                }
                            >
                                <TableCell className={classes.customCell}>
                                    Áfa kulcs (%)
                                    <InfoIcon
                                        style={{
                                            fontSize: "0.9rem",
                                            opacity: "0.5",
                                            marginLeft: "10px",
                                        }}
                                    />
                                </TableCell>
                            </Tooltip> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {modificationList.map(
              (
                { id, name, priority, compatibleWith, unitPrice, modifierUriSegment },
                enabled = getModificationEnabled(id, modifiers)
              ) => (
                <TableRow key={`modification_${name}`} hover className={priority === 1 ? styles.categoryRow : ''}>
                  {priority === 1 ? ( // if its prio 1 -> printer or digital, then price is 0 by default, else the price and availability must be both filled
                    <Fragment key={`modification_${name}_inner`}>
                      <TableCell className={styles.customCell} align='center'>
                        <Checkbox
                          disabled={inherit}
                          color='primary'
                          onChange={(e) => {
                            setModificationAvailable(
                              id,
                              modifierUriSegment,
                              e.target.checked,
                              modifiers,
                              setModifiers,
                              priority
                            );
                          }}
                          value={
                            modifiers === undefined || modifiers?.length < 1
                              ? true
                              : modifiers.find((originalModification) => id === originalModification.id)?.enabled
                          }
                          checked={
                            modifiers === undefined || modifiers?.length < 1
                              ? true
                              : modifiers.find((originalModification) => id === originalModification.id)?.enabled
                          }
                        />
                      </TableCell>
                      <TableCell className={styles.nameCell} component='th' scope='row' colSpan={1}>
                        {name}
                      </TableCell>
                      <TableCell colSpan={1} className={styles.customCell} align='left'>
                        {/* 
                        Not needed cause every modifier's price should be set one by one
                        <TextField
                          id="price"
                          name="price"
                          inputProps={{
                            type: "number",
                            inputMode: "numeric",
                            pattern: "[0-9][0-9]*",
                            min: 0,
                            style: {
                              fontFamily: `${theme.typography.secondary}, Sans-serif, Arial`,
                            },
                          }}
                          variant="outlined"
                          className={classes.huf}
                          size="small"
                          onChange={(e) => {
                            if (!isNaN(parseInt(e.target.value))) {
                              setFormatPrice(
                                compatibleWith,
                                e.target.value,
                                modifiers,
                                setModifiers
                              );
                            } else {
                              setFormatPrice(
                                compatibleWith,
                                0,
                                modifiers,
                                setModifiers
                              );
                            }
                          }}
                        ></TextField> */}
                      </TableCell>
                      <TableCell colSpan={1} className={styles.customCell}></TableCell>
                      {/*
                                            FOR VATRATE HANDLING
                                            <TableCell
                                                colSpan={1}
                                                className={classes.customCell}
                                            ></TableCell> */}
                      {/*
                                            IF MINIMAL PRICE COLUMN IS NEEDED
                                            <TableCell
                                                colSpan={1}
                                                className={classes.customCell}
                                            ></TableCell> */}
                    </Fragment>
                  ) : (
                    <Fragment key={`modification_${name}_inner`}>
                      <TableCell
                        className={
                          !modifiers.find((originalModification) => id === originalModification.id)?.enabled || inherit
                            ? styles.disabledCell
                            : styles.customCell
                        }
                        align='center'>
                        <Checkbox
                          disabled={inherit}
                          color='primary'
                          onChange={(e) => {
                            setModificationAvailable(
                              id,
                              modifierUriSegment,
                              e.target.checked,
                              modifiers,
                              setModifiers,
                              priority
                            );
                          }}
                          value={
                            modifiers === undefined || modifiers?.length < 1
                              ? true
                              : modifiers.find((originalModification) => id === originalModification.id)?.enabled
                          }
                          checked={
                            modifiers === undefined || modifiers?.length < 1
                              ? true
                              : modifiers.find((originalModification) => id === originalModification.id)?.enabled
                          }
                        />
                      </TableCell>
                      <TableCell
                        className={
                          !modifiers.find((originalModification) => id === originalModification.id)?.enabled || inherit
                            ? styles.disabledNameCell
                            : styles.nameCell
                        }
                        component='th'
                        scope='row'>
                        {name}
                      </TableCell>
                      <TableCell
                        className={
                          !modifiers.find((originalModification) => id === originalModification.id)?.enabled || inherit
                            ? styles.disabledCell
                            : styles.customCell
                        }
                        align='left'>
                        <Tooltip
                          placement='right'
                          open={
                            modifiers?.find((originalModification) => id === originalModification.id)?.enabled &&
                            modifiers?.find((originalModification) => id === originalModification.id)?.unitPrice
                              ?.grossAmount <
                              minimals?.find((minimal) => minimal.modifierId === getUriId(modifierUriSegment))?.price
                                ?.grossAmount
                          }
                          title={`Minimális ár: ${minimals?.find(
                            (minimal) => minimal.modifierId === getUriId(modifierUriSegment)
                          )?.price?.grossAmount}`}>
                          <TextField
                            disabled={
                              // inputDisabled ||                              |
                              !modifiers?.find((originalModification) => id === originalModification.id)?.enabled ||
                              inherit
                            }
                            value={
                              modifiers?.find((originalModification) => id === originalModification.id)?.unitPrice
                                ?.grossAmount
                            }
                            type='text'
                            inputProps={{
                              required: enabled,
                              type: 'number',
                              // readOnly: inputDisabled,
                              inputMode: 'numeric',
                              pattern: '[0-9][0-9]*',
                              min: 0,
                              style: {
                                fontFamily: 'var(--font-secondary), Sans-serif, Arial'
                              }
                            }}
                            onChange={(e) => {
                              if (!isNaN(parseInt(e.target.value))) {
                                setModification(modifiers, id, e.target.value, setModifiers);
                                setModification({
                                  currentModifiers: modifiers,
                                  newModificationId: id,
                                  value: e.target.value,
                                  setCurrentModifiers: setModifiers,
                                  grossAmount: modifiers.find((originalModification) => id === originalModification.id)
                                    ?.unitPrice?.grossAmount,
                                  taxRate: modifiers.find((originalModification) => id === originalModification.id)
                                    ?.unitPrice?.taxRate,
                                  tax: false
                                });
                              } else {
                                setModification({
                                  currentModifiers: modifiers,
                                  newModificationId: id,
                                  value: 0,
                                  setCurrentModifiers: setModifiers,
                                  grossAmount: modifiers.find((originalModification) => id === originalModification.id)
                                    ?.unitPrice?.grossAmount,
                                  taxRate: modifiers.find((originalModification) => id === originalModification.id)
                                    ?.unitPrice?.taxRate,
                                  tax: false
                                });
                              }
                            }}
                            variant='outlined'
                            id={`price_${id}`}
                            className={styles.huf}
                            size='small'></TextField>
                        </Tooltip>
                      </TableCell>
                      {/*
                                            IF MINIMAL PRICE COLUMN IS NEEDED
                                            <TableCell
                                                colSpan={1}
                                                className={
                                                    !modifiers.find(
                                                        (
                                                            originalModification
                                                        ) =>
                                                            id ===
                                                            originalModification.id
                                                    )?.enabled || inherit
                                                        ? classes.disabledCell
                                                        : classes.customCell
                                                }
                                            ></TableCell> */}

                      <TableCell
                        colSpan={1}
                        className={
                          !modifiers.find((originalModification) => id === originalModification.id)?.enabled || inherit
                            ? styles.disabledCell
                            : styles.customCell
                        }>
                        {modifiers.find((originalModification) => id === originalModification.id)?.unitPrice
                          ?.grossAmount !== 0 && (
                          <div
                            style={{
                              display: 'flex',
                              width: '100%',
                              justifyContent: 'center',
                              alignItems: 'center'
                            }}>
                            {modifiers.find((originalModification) => id === originalModification.id)?.enabled && (
                              <ActualCommission
                                modifierId={id}
                                total={modifiers.find((originalModification) => id === originalModification.id)?.total}
                                netAmount={
                                  modifiers.find((originalModification) => id === originalModification.id)?.unitPrice
                                    ?.grossAmount
                                }
                                taxRate={
                                  modifiers.find((originalModification) => id === originalModification.id)?.unitPrice
                                    ?.taxRate
                                }
                                modifierDeliveryType={
                                  modifiers.find((originalModification) => id === originalModification.id)
                                    ?.deliveryTypeDTO
                                }
                                modifierUriSegment={modifierUriSegment}
                              />
                            )}
                          </div>
                        )}
                      </TableCell>
                      {/*
                                            FOR VATRATE HANDLING
                                            
                                            <TableCell
                                                className={
                                                    !modifiers.find(
                                                        (
                                                            originalModification
                                                        ) =>
                                                            id ===
                                                            originalModification.id
                                                    )?.enabled || inherit
                                                        ? classes.disabledCell
                                                        : classes.customCell
                                                }
                                                align="left"
                                            >
                                                <TextField
                                                    disabled={
                                                        // inputDisabled ||
                                                        !modifiers.find(
                                                            (
                                                                originalModification
                                                            ) =>
                                                                id ===
                                                                originalModification.id
                                                        )?.enabled || inherit
                                                    }
                                                    value={
                                                        modifiers.find(
                                                            (
                                                                originalModification
                                                            ) =>
                                                                id ===
                                                                originalModification.id
                                                        )?.unitPrice?.taxRate *
                                                        100
                                                    }
                                                    type="text"
                                                    inputProps={{
                                                        required: enabled,
                                                        type: "number",
                                                        // readOnly: inputDisabled,
                                                        inputMode: "numeric",
                                                        pattern: "[0-9][0-9]*",
                                                        min: 0,
                                                        style: {
                                                            fontFamily: `${theme.typography.secondary}, Sans-serif, Arial`,
                                                        },
                                                    }}
                                                    onChange={(e) => {
                                                        if (
                                                            !isNaN(
                                                                parseInt(
                                                                    e.target
                                                                        .value
                                                                )
                                                            )
                                                        ) {
                                                            setModification({
                                                                currentModifiers:
                                                                    modifiers,
                                                                newModificationId:
                                                                    id,
                                                                value:
                                                                    e.target
                                                                        .value /
                                                                    100,
                                                                setCurrentModifiers:
                                                                    setModifiers,
                                                                grossAmount:
                                                                    modifiers.find(
                                                                        (
                                                                            originalModification
                                                                        ) =>
                                                                            id ===
                                                                            originalModification.id
                                                                    )?.unitPrice
                                                                        ?.grossAmount,
                                                                taxRate:
                                                                    modifiers.find(
                                                                        (
                                                                            originalModification
                                                                        ) =>
                                                                            id ===
                                                                            originalModification.id
                                                                    )?.unitPrice
                                                                        ?.taxRate,
                                                                tax: true,
                                                            });
                                                        } else {
                                                            setModification({
                                                                currentModifiers:
                                                                    modifiers,
                                                                newModificationId:
                                                                    id,
                                                                value: 0,
                                                                setCurrentModifiers:
                                                                    setModifiers,
                                                                grossAmount:
                                                                    modifiers.find(
                                                                        (
                                                                            originalModification
                                                                        ) =>
                                                                            id ===
                                                                            originalModification.id
                                                                    )?.unitPrice
                                                                        ?.grossAmount,
                                                                taxRate:
                                                                    modifiers.find(
                                                                        (
                                                                            originalModification
                                                                        ) =>
                                                                            id ===
                                                                            originalModification.id
                                                                    )?.unitPrice
                                                                        ?.taxRate,
                                                                tax: true,
                                                            });
                                                        }
                                                    }}
                                                    variant="outlined"
                                                    id={`vat_${id}`}
                                                    className={classes.huf}
                                                    size="small"
                                                ></TextField>
                                            </TableCell> */}
                    </Fragment>
                  )}
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
        <button ref={buttonRef} type='submit' hidden />
      </form>
    </TableContainer>
  );
};

export default ModifierForm;
