import { Button, CircularProgress, Tooltip, Typography } from '@material-ui/core';
import styles from './DetailsCard.module.css';

const DetailsCard = ({
  title,
  handler,
  icon,
  buttonText,
  buttonDisabled,
  rows,
  button2Text,
  handler2,
  icon2,
  tooltipText,
  tooltipText2,
  downloadProgress
}) => {
  return (
    <>
      <div className={styles.titleContainer}>
        <Typography className={styles.title}>{title} részletei</Typography>{' '}
        {!!icon2 && (
          <Tooltip title={tooltipText2}>
            <div>
              <Button
                children={icon2}
                className={styles.detailButton}
                type='submit'
                disabled={buttonDisabled}
                variant='outlined'
                onClick={() => {
                  handler2();
                }}>
                {button2Text}
                {icon2}
              </Button>
            </div>
          </Tooltip>
        )}
        {!!icon && (
          <Tooltip title={tooltipText}>
            <div>
              <Button
                children={icon}
                className={styles.detailButton}
                type='submit'
                disabled={buttonDisabled || downloadProgress}
                variant='outlined'
                onClick={() => {
                  handler();
                }}>
                {buttonText}
                {downloadProgress ? <CircularProgress size={24} /> : icon}
              </Button>
            </div>
          </Tooltip>
        )}
      </div>
      <div className={styles.orderDetail}>
        {rows.map((row, idx) =>
          row?.title !== 'Rendelés lejárata' ? (
            <div className={styles.row} key={idx}>
              <p className={styles.titleBottom}>{row?.title}:</p>
              <p className={styles.detailBottom}>{row?.info}</p>
            </div>
          ) : (
            ''
          )
        )}
      </div>
    </>
  );
};

export default DetailsCard;
