import { Button } from '@material-ui/core';
import styles from './Subscription.module.css';
import { useNavigate } from 'react-router-dom';
import CardRegister from 'components/SubscriptionComponents/CardRegister/CardRegister';
import Accordion from 'components/SubscriptionComponents/Accordion/Accordion';
import logoSvg from 'assets/images/logo.svg';
import { useSubscriptionLogic } from './useSubscriptionLogic';
import { MdCheck, MdClose } from 'react-icons/md';
import { Breadcrumbs, Paper, Typography } from '@mui/material';

const Subscription = () => {
  const navigate = useNavigate();

  const { shopTier, shopId } = useSubscriptionLogic();

  return (
    <div className={styles.mainContainer}>
      <Breadcrumbs aria-label='breadcrumb' className={styles.breadcrumb}>
        <Typography variant='body1'>Előfizetői beállítások</Typography>
      </Breadcrumbs>
      <div className={styles.middleContainer}>
        <div className={styles.middleLeft}>
          <h2>Kártyaregisztráció</h2>
          <CardRegister />
        </div>
        <div className={styles.middleRight}>
          <h2>Előfizetési adatok</h2>

          {shopTier ? (
            <div className={styles.rightBottom}>
              <div className={styles.subscriptionInfo}>
                Előfizetésed kezdete: {shopTier?.subscribeDate ? shopTier?.subscribeDate : 'Backend TODO'}
              </div>
              <div className={styles.logoContainer}>
                <img src={logoSvg} alt='logo' />
              </div>
              <h3 className={styles.title}>{shopTier?.name}</h3>
              <div style={{ marginTop: 'auto' }}>
                A következő fizetés időpontja: {shopTier?.nextPaymentTime ? shopTier?.nextPaymentTime : 'Backend TODO'}
              </div>
              <Accordion title={'Csomag részletek'}>
                <div className={styles.details}>
                  <div className={styles.detail_item}>
                    <p>Galéria limit:</p> <p>{shopTier?.galleryLimit}db</p>
                  </div>
                  <div className={styles.detail_item}>
                    <p>Képfeltöltési limit:</p> <p>{shopTier?.productsLimit}db</p>
                  </div>
                  <div className={styles.detail_item}>
                    <p>Galéria létrehozás:</p>{' '}
                    <p>
                      <MdCheck color='green' fontSize={22} />
                    </p>
                  </div>
                  <div className={styles.detail_item}>
                    <p>Képfeltöltés:</p>{' '}
                    <p>
                      <MdCheck color='green' fontSize={22} />
                    </p>
                  </div>
                  <div className={styles.detail_item}>
                    <p>Saját termék definiálás:</p>{' '}
                    <p>
                      <MdCheck color='green' fontSize={22} />
                    </p>
                  </div>
                  <div className={styles.detail_item}>
                    <p>Kimutatások:</p>{' '}
                    <p>
                      {shopTier?.reports ? (
                        <MdCheck color='green' fontSize={22} />
                      ) : (
                        <MdClose color='red' fontSize={22} />
                      )}
                    </p>
                  </div>
                  <div className={styles.detail_item}>
                    <p>Nyomtatási lehetőség:</p>{' '}
                    <p>
                      {shopTier?.printOption ? (
                        <MdCheck color='green' fontSize={22} />
                      ) : (
                        <MdClose color='red' fontSize={22} />
                      )}
                    </p>
                  </div>
                  <div className={styles.detail_item}>
                    <p>Rendelési határidő beállítása:</p>{' '}
                    <p>
                      {shopTier?.galleryExpiryDate ? (
                        <MdCheck color='green' fontSize={22} />
                      ) : (
                        <MdClose color='red' fontSize={22} />
                      )}
                    </p>
                  </div>
                  <div className={styles.detail_item}>
                    <p>Rendelési határidő öröklés:</p>{' '}
                    <p>
                      {shopTier?.expiryDateInheritance ? (
                        <MdCheck color='green' fontSize={22} />
                      ) : (
                        <MdClose color='red' fontSize={22} />
                      )}
                    </p>
                  </div>
                  <div className={styles.detail_item}>
                    <p>Áröröklés:</p>{' '}
                    <p>
                      {shopTier?.priceInheritance ? (
                        <MdCheck color='green' fontSize={22} />
                      ) : (
                        <MdClose color='red' fontSize={22} />
                      )}
                    </p>
                  </div>
                  <div className={styles.detail_item}>
                    <p>Automata csoportkép galéria generálás:</p>{' '}
                    <p>
                      {shopTier?.groupGallery ? (
                        <MdCheck color='green' fontSize={22} />
                      ) : (
                        <MdClose color='red' fontSize={22} />
                      )}
                    </p>
                  </div>
                  <div className={styles.detail_item}>
                    <p>Galéria külön kódolása:</p>{' '}
                    <p>
                      {shopTier?.galleryCoding ? (
                        <MdCheck color='green' fontSize={22} />
                      ) : (
                        <MdClose color='red' fontSize={22} />
                      )}
                    </p>
                  </div>
                  <div className={styles.detail_item}>
                    <p>Online fizetés:</p>{' '}
                    <p>
                      {shopTier?.onlinePayment ? (
                        <MdCheck color='green' fontSize={22} />
                      ) : (
                        <MdClose color='red' fontSize={22} />
                      )}
                    </p>
                  </div>
                  <div className={styles.detail_item}>
                    <p>Árazási sablon létrehozás:</p>{' '}
                    <p>
                      {shopTier?.pricingTemplates ? (
                        <MdCheck color='green' fontSize={22} />
                      ) : (
                        <MdClose color='red' fontSize={22} />
                      )}
                    </p>
                  </div>
                  <div className={styles.detail_item}>
                    <p>Automata számlázás:</p>{' '}
                    <p>
                      {shopTier?.automaticInvoice ? (
                        <MdCheck color='green' fontSize={22} />
                      ) : (
                        <MdClose color='red' fontSize={22} />
                      )}
                    </p>
                  </div>
                  <b>Csomag ára:</b> {shopTier?.price ? shopTier?.price : 'Backend TODO'} Ft/hó
                </div>
              </Accordion>
            </div>
          ) : (
            <div className={styles.rightBottom}>
              <div className={styles.subscriptionInfo}>Jelenleg nincs előfizetésed</div>
              <h3 className={styles.title}>Nincs előfizetésed</h3>
              <Button
                className={styles.buttonDark}
                style={{ marginBottom: '1.1rem' }}
                onClick={() => {
                  navigate(`/settings/subscriptions-bundles`);
                }}>
                Csomagok vásárlása
              </Button>
            </div>
          )}
        </div>
      </div>
      <div className={styles.bottom}>
        <h2 className={styles.bundles_title}>Előfizetői csomagok megjelenítése</h2>
        <Button
          className={styles.buttonDark}
          onClick={() => {
            navigate(`/shops/${shopId}/administration/subscriptions-bundles`);
          }}>
          Csomagok
        </Button>
      </div>
    </div>
  );
};

export default Subscription;
