import {
  Breadcrumbs,
  Button,
  CircularProgress,
  Container,
  CssBaseline,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Paper,
  Select,
  Tooltip,
  Typography,
  Zoom
} from '@material-ui/core';
import { useModificationLogic } from 'components/PhotoAdminComponents/ModifierForm/hooks/useModificationLogic';
import TemplateDetails from 'components/PhotoAdminComponents/Templates/TemplateDetails/TemplateDetails';
import React, { useState, useRef, useEffect } from 'react';
import { useModifierApi } from './hooks/useModifierApi';
import { useTemplateApi } from './hooks/useTemplateApi';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import ErrorIcon from '@mui/icons-material/Error';
import { MdClose } from 'react-icons/md';
import styles from './Templates.module.css';
import ConfirmDialog from 'components/common/ConfirmDialog/ConfirmDialog';
import LoadIndicator from 'components/common/LoadIndicator/LoadIndicator';
import TemplateMain from 'components/PhotoAdminComponents/Templates/TemplateMain/TemplateMain';
import { Grow } from '@mui/material';

const Templates = () => {
  const {
    templates,
    getTemplates,
    getTemplate,
    templatesLoading,
    postTemplate,
    putTemplate,
    deleteTemplate,
    templatesError,
    getTemplateBody,
    template,
    setTemplate,
    templateLoading,
    createProgress,
    updateProgress,
    deleteProgress
  } = useTemplateApi();
  const { getModifiers, modifiers } = useModifierApi();
  const { getCurrentModifiers, clearModifiers } = useModificationLogic();

  const [isTemplate, setIsTemplate] = useState(false);

  const [clickedTemplate, setClickedTemplate] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [formChanged, setFormChanged] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const buttonRef = useRef(null);

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
  const [openCreateDialog, setOpenCreateDialog] = useState(false);

  const [currentModifiers, setCurrentModifiers] = useState([]);
  const [name, setName] = useState('Új sablon');

  useEffect(() => {
    getTemplates();
    getModifiers();
  }, []);

  useEffect(() => {
    if (modifiers) {
      setCurrentModifiers(getCurrentModifiers([], modifiers));
    }
  }, [modifiers]);

  const confirmSelect = () => {
    if (clickedTemplate && buttonRef.current) {
      setSelectedTemplate(clickedTemplate);
      getTemplate(clickedTemplate);
    }
  };

  useEffect(() => {
    if (template) {
      setName(template?.name);
      if (template?.modifiers?.length > 0) {
        setCurrentModifiers(getCurrentModifiers(template, modifiers));
      }
    }
  }, [template]);

  const resetForm = () => {
    setName('Új sablon');
    setClickedTemplate(null);
    setSelectedTemplate(null);
    setCurrentModifiers(getCurrentModifiers([], modifiers));
    setTemplate(null);
  };

  const handleDelete = async () => {
    if (buttonRef.current) {
      await deleteTemplate(selectedTemplate);
      await getTemplates();
      resetForm();
    }
  };

  const handlePut = async () => {
    if (buttonRef.current) {
      await buttonRef.current.click();
      await putTemplate(getTemplateBody(selectedTemplate, name, clearModifiers(currentModifiers)));
      await getTemplates();
      resetForm();
    }
  };

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  useEffect(() => {
    if (templatesError) {
      setIsDialogOpen(true);
    }
  }, [templatesError]);

  const handlePost = async () => {
    if (buttonRef.current) {
      await buttonRef.current.click();
      await postTemplate(getTemplateBody(null, name, clearModifiers(currentModifiers)));
      await getTemplates();
      resetForm();
    }
  };
  return (
    <Container maxWidth={false} className={styles.root}>
      <CssBaseline />
      <Breadcrumbs aria-label='breadcrumb' className={styles.breadcrumb}>
        <Typography variant='body1'>Termékek és sablonok</Typography>
      </Breadcrumbs>
      <TemplateMain modifiers={modifiers} isTemplate={isTemplate} setIsTemplate={setIsTemplate} />
      {isTemplate ? (
        <Grow in={true}>
          <div className={styles.container}>
            <div className={styles.sideContainer}>
              <Grid container>
                <div className={styles.topContainer}>
                  {templatesLoading ? (
                    <LoadIndicator color={'var(--primary-text-primary)'} height={120} />
                  ) : (
                    <>
                      {templates.length > 0 ? (
                        <Select
                          className={styles.select}
                          variant='outlined'
                          onChange={(e) => setClickedTemplate(e.target.value)}
                          defaultValue={-1}
                          style={{
                            fontFamily: 'var(--font-main), Sans-serif, Arial'
                          }}>
                          <MenuItem
                            key={`no_template`}
                            value={-1}
                            disabled
                            style={{
                              fontFamily: 'var(--font-main), Sans-serif, Arial'
                            }}>
                            Meglévő sablon választása
                          </MenuItem>
                          {templates?.map((template) => (
                            <MenuItem
                              key={`${template.id}_template`}
                              value={template.id}
                              style={{
                                fontFamily: 'var(--font-main), Sans-serif, Arial'
                              }}>
                              {template.name}
                            </MenuItem>
                          ))}
                        </Select>
                      ) : (
                        <Select
                          variant='outlined'
                          defaultValue={-1}
                          fullWidth
                          style={{
                            fontFamily: 'var(--font-main), Sans-serif, Arial'
                          }}>
                          <MenuItem
                            key={`no_templates`}
                            value={-1}
                            disabled
                            style={{
                              fontFamily: 'var(--font-main), Sans-serif, Arial'
                            }}>
                            Nincs megjeleníthető elem
                          </MenuItem>
                        </Select>
                      )}
                    </>
                  )}
                  <Button
                    className={styles.buttonClose}
                    onClick={() => {
                      setIsTemplate(false);
                    }}>
                    <MdClose size={'30px'} />
                  </Button>
                </div>
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    className={styles.loadButton}
                    variant='contained'
                    disabled={!clickedTemplate || templatesLoading || templateLoading}
                    onClick={confirmSelect}>
                    {templateLoading ? (
                      <CircularProgress
                        size={25}
                        style={{
                          color: 'var(--primary-text-primary)'
                        }}
                      />
                    ) : (
                      'Betölt'
                    )}
                  </Button>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                {currentModifiers && (
                  <TemplateDetails
                    id={selectedTemplate || null}
                    name={name}
                    setName={setName}
                    modifiers={currentModifiers}
                    selectedTemplate={template}
                    setFormChanged={setFormChanged}
                    technical={false}
                    setModifiers={setCurrentModifiers}
                    buttonRef={buttonRef}
                    setDisabled={setDisabled}
                  />
                )}
              </Grid>
              <Grid item xs={12}>
                <Typography
                  style={{
                    color: 'var(--primary-text-primary)',
                    opacity: disabled ? 1 : 0,
                    transition: 'opacity 300ms'
                  }}>
                  <ErrorIcon
                    style={{
                      fontSize: '1rem',
                      margin: 'auto 10px',
                      color: 'red'
                    }}
                  />
                  {'Az kiválasztott típusok árai nem lehetnek kevesebbek a minimális értéküknél'}
                </Typography>
              </Grid>
              <ConfirmDialog
                open={openDeleteDialog}
                onOk={() => {
                  handleDelete();
                  setOpenDeleteDialog(false);
                }}
                onClose={() => setOpenDeleteDialog(false)}
                title={'Biztosan törölni akarod?'}
                functionButtonText={'Törlés'}
                functionButtonColor={'var(--primary-error)'}
                closeButtonText={'Mégsem'}
                multipleButtons={true}
              />
              <Grid container className={styles.buttons}>
                <Grid item xs={4} className={styles.button}>
                  <Tooltip title='Sablon törlése'>
                    <div>
                      <Button
                        style={{
                          background: selectedTemplate && 'var(--primary-error)'
                        }}
                        variant='outlined'
                        disabled={!selectedTemplate}
                        onClick={() => setOpenDeleteDialog(true)}>
                        {deleteProgress ? (
                          <CircularProgress
                            size={24}
                            style={{
                              color: 'var(--primary-text-primary)'
                            }}
                          />
                        ) : (
                          <DeleteIcon
                            style={{
                              color: selectedTemplate && 'var(--primary-text-primary)'
                            }}
                          />
                        )}
                      </Button>
                    </div>
                  </Tooltip>
                </Grid>
                <ConfirmDialog
                  open={openUpdateDialog}
                  onOk={() => {
                    handlePut();
                    setOpenUpdateDialog(false);
                  }}
                  onClose={() => setOpenUpdateDialog(false)}
                  title={'Biztosan frissíteni akarod?'}
                  functionButtonText={'Frissítés'}
                  functionButtonColor={'var(--primary-info)'}
                  closeButtonText={'Mégsem'}
                  multipleButtons={true}
                />
                <Grid item xs={4} className={styles.button}>
                  <Tooltip title='Sablon frissítése'>
                    <div>
                      <Button
                        style={{
                          background: selectedTemplate && formChanged && !disabled && 'var(--primary-info)'
                        }}
                        variant='outlined'
                        disabled={disabled || !selectedTemplate || !formChanged}
                        onClick={() => setOpenUpdateDialog(true)}>
                        {updateProgress ? (
                          <CircularProgress
                            size={24}
                            style={{
                              color: 'var(--primary-text-primary)'
                            }}
                          />
                        ) : (
                          <AutorenewIcon
                            style={{
                              color: selectedTemplate && formChanged && !disabled && 'var(--primary-text-primary)'
                            }}
                          />
                        )}
                      </Button>
                    </div>
                  </Tooltip>
                </Grid>
                <ConfirmDialog
                  open={openCreateDialog}
                  onOk={() => {
                    handlePost();
                    setOpenCreateDialog(false);
                  }}
                  onClose={() => setOpenCreateDialog(false)}
                  title={'Elkészülhet az új sablonod?'}
                  functionButtonText={'Létrehozás'}
                  functionButtonColor={'var(--primary-success)'}
                  closeButtonText={'Mégsem'}
                  multipleButtons={true}
                />
                <Grid item xs={4} className={styles.button}>
                  <Tooltip title='Új sablon létrehozása a megadott adatokkal'>
                    <div>
                      <Button
                        variant='outlined'
                        style={{
                          background:
                            !currentModifiers?.length > 0 || (selectedTemplate && !formChanged) || disabled
                              ? ''
                              : 'var(--primary-success)'
                        }}
                        disabled={disabled || !currentModifiers?.length > 0 || (selectedTemplate && !formChanged)}
                        onClick={() => setOpenCreateDialog(true)}>
                        {createProgress ? (
                          <CircularProgress
                            size={24}
                            style={{
                              color: 'var(--primary-text-primary)'
                            }}
                          />
                        ) : (
                          <AddIcon
                            style={{
                              color:
                                !currentModifiers?.length > 0 || (selectedTemplate && !formChanged) || disabled
                                  ? ''
                                  : 'var(--primary-text-primary)'
                            }}
                          />
                        )}
                      </Button>
                    </div>
                  </Tooltip>
                </Grid>
              </Grid>
            </div>
          </div>
        </Grow>
      ) : null}
      <Dialog open={isDialogOpen} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
        <DialogTitle id='alert-dialog-title'>{'Művelet sikertelen!'}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Hiba történt a művelet végrehajtása során. Amennyiben sablont törölne, először bizonyosodjon meg arról, hogy
            az nincs használatban.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDialogOpen(false)} color='primary'>
            Rendben
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Templates;
