/** @format */

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import queries from "../../../api/queries/PaymentQueries";
import { useLocation, useNavigate } from "react-router-dom";
import { errorToast } from "components/common/Toast/Toast";

export const usePaymentsLogic = () => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const { search } = useLocation();

    const [showAll, setShowAll] = useState(false);
    const [allSelected, setAllSelected] = useState(false);
    const [paymentData, setPaymentData] = useState([]);
    const [open, setOpen] = useState(false);
    const [selectedState, setSelectedState] = useState("PAID");

    const selectableStates = [
        "RECEIVED",
        "INITIATED",
        "CONFIRMED",
        "PAID",
        "REJECTED",
        "CANCELLED",
        "REFUND",
        "TIMEOUT",
    ];
    const getShowAllQueryString = () => {
        let queryParams = new URLSearchParams(search);
        queryParams.set("page", 1);
        queryParams.set("size", fetchPayments?.data?.data?.page?.totalElements);
        return queryParams.toString();
    };
    const handleShowAll = () => {
        setShowAll(!showAll);

        navigate(`/superadmin/payments?${getShowAllQueryString()}`);
    };

    const fetchPayments = useQuery({
        queryKey: ["payments", search],
        queryFn: () => queries.getPayments(search),
        onSuccess: (resp) => {
            let modifiedData = resp?.data?._embedded?.paymentDTOList?.map(
                (payment) => payment && { ...payment, selected: false }
            );
            setPaymentData(modifiedData);
        },
    });
    const refresh = () => {
        queryClient.invalidateQueries(["payments", search]);
    };
    const changePaymentState = useMutation({
        mutationFn: (props) =>
            queries.patchPayment({
                id: props?.id,
                shopId: props?.shopId,
                status: props?.status,
            }),
        onError: () => {
            errorToast(`Sikertelen státuszmódosítás`);
        },
    });

    const handlePaymentStateChange = async () => {
        let completed = [];
        paymentData.forEach((payment) => {
            if (payment.selected) {
                let paymentChange = changePaymentState.mutate({
                    id: payment?.id,
                    shopId: payment?.shopId,
                    status: selectedState,
                });
                completed.push(paymentChange);
            }
        });
        await Promise.all(completed);
        setAllSelected(false);
        refresh();
    };

    const handleSelectAll = () => {
        let modifiedData = paymentData;
        if (allSelected) {
            setPaymentData(
                modifiedData?.map(
                    (payment) => payment && { ...payment, selected: true }
                )
            );
        } else {
            setPaymentData(
                modifiedData?.map(
                    (payment) => payment && { ...payment, selected: false }
                )
            );
        }
    };

    useEffect(() => {
        refresh();
        let queryParams = new URLSearchParams(search);
        if (
            queryParams.get("size") !==
            fetchPayments?.data?.data?.page?.totalElements
        ) {
            setShowAll(false);
        }
    }, [search]);

    useEffect(() => {
        handleSelectAll();
    }, [allSelected]);

    const resetQueryString = () => {
        navigate("/superadmin/payments");
    };

    const handleStatusTranslate = (status) => {
        if (status === "RECEIVED") {
            return "FIZETÉSRE VÁR";
        }
        if (status === "INITIATED") {
            return "INITIATED";
        }
        if (status === "CANCELLED") {
            return "MEGSZAKÍTVA";
        }
        if (status === "CONFIRMED") {
            return "FIZETÉS MEGKEZDVE";
        }
        if (status === "PAID") {
            return "FIZETVE";
        }
        if (status === "REJECTED") {
            return "VISSZAUTASÍTVA";
        }
        if (status === "TIMEOUT") {
            return "IDŐTÚLLÉPÉS";
        }
        if (status === "REFUND") {
            return "VISSZATÉRTÉS";
        }
    };

    return {
        handleShowAll,
        fetchPayments,
        resetQueryString,
        refresh,
        showAll,
        handleStatusTranslate,
        paymentData,
        setPaymentData,
        setAllSelected,
        handlePaymentStateChange,
        allSelected,
        open,
        setOpen,
        selectableStates,
        selectedState,
        setSelectedState,
    };
};
