import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Collapse from '@material-ui/core/Collapse';
import { useNavigate, useLocation } from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import styles from './DrawerMenu.module.css';
import HeaderOption from '../HeaderOption/HeaderOption';
import HeaderData from 'config/HeaderData';
import AuthService from 'services/AuthService';
import { useSelector } from 'react-redux';
import { MdPerson, MdSettings, MdStore } from 'react-icons/md';

const DrawerMenu = (props) => {
  const navigate = useNavigate();

  const routeChange = (path) => {
    navigate(path);
    props.setDrawer(false);
  };
  const shopId = useSelector((state) => state.nonPersistedReducers?.shopSliceReducer?.shopId);

  const id = useLocation().pathname.split('/')[2];

  const handleLogout = () => {
    //dispatch(logout());
    AuthService.doLogout();
  };

  return (
    <Drawer anchor='left' open={props.drawer} onClose={() => props.toggleDrawer()}>
      <List className={styles.listWrapper}>
        {props?.user?.groups?.find((role) => role === 'users') && (
          <div>
            <ListItem
              button
              onClick={(e) => {
                props.toggleAdminOptions();
                e.stopPropagation();
              }}
              disabled={shopId === -1}>
              <ListItemIcon style={{ position: 'relative' }}>
                <MdStore color='white' size={25} />
                <div className={styles.special_icon_wrapper}>
                  <MdSettings />
                </div>
              </ListItemIcon>
              <div className={styles.listItemText}>Adminisztráció</div>
            </ListItem>
            <Collapse in={props?.adminOptionsOpen} timeout='auto' unmountOnExit>
              <List component='div' disablePadding>
                {HeaderData.options.admin.map((item, idx) => (
                  <HeaderOption
                    disabled={item.disabled}
                    click={() => {
                      routeChange(`/shops/${id}/administration/${item.to}`);
                    }}
                    name={item.name}
                    icon={item.icon}
                    key={`header_small_admin_option_admin_${idx}`}
                  />
                ))}
              </List>
            </Collapse>
            <Divider />
            <ListItem
              button
              onClick={(e) => {
                props.toggleProfileOptions();
                e.stopPropagation();
              }}>
              <ListItemIcon className={styles.titleIcon}>
                <MdPerson color='white' size={25} />
              </ListItemIcon>
              <div className={styles.listItemText}>Profil</div>
            </ListItem>
            <Collapse in={props?.profileOptionsOpen} timeout='auto' unmountOnExit>
              <List component='div' disablePadding>
                {HeaderData.options.profile.map((item, idx) => (
                  <HeaderOption
                    disabled={item.disabled}
                    click={() => {
                      routeChange(`/settings/${item.to}`);
                    }}
                    name={item.name}
                    icon={item.icon}
                    key={`header_small_admin_option_admin_${idx}`}
                  />
                ))}
              </List>
            </Collapse>
          </div>
        )}
        <div>
          {props?.user?.groups?.find((role) => role === 'dt_admin') && (
            <List component='div' disablePadding>
              {HeaderData.options.superAdmin.map((item, idx) => (
                <HeaderOption
                  disabled={item.disabled}
                  click={() => {
                    routeChange(`superadmin/${item.to}`);
                  }}
                  name={item.name}
                  icon={item.icon}
                  key={`header_small_admin_option_admin_${idx}`}
                />
              ))}
            </List>
          )}
        </div>

        <div>
          <HeaderOption
            click={handleLogout}
            name={HeaderData.logoutOption.name}
            icon={HeaderData.logoutOption.icon}
            key={HeaderData.logoutOption.name}
          />
        </div>
      </List>
    </Drawer>
  );
};

export default DrawerMenu;
