import { MenuItem, Select } from '@material-ui/core';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import styles from './KeyValueEditor.module.css';

const KeyValueEditor = ({ option, setOption }) => {
  const [currentKey, setCurrentKey] = useState(option.value === '' ? -1 : option.value.split('=')[1].split(',')[0]);
  const [currentValue, setCurrentValue] = useState(option.value === '' ? -1 : option.value.split('=')[1].split(',')[1]);

  useEffect(() => {
    if (currentKey !== -1 && currentValue !== -1) {
      setOption({
        ...option,
        value: `${option.name}=${currentKey},${currentValue}`
      });
    }
  }, [currentKey, currentValue]);

  return (
    <div className={styles.mainContainer}>
      {option.keys.length > 0 ? (
        <Select
          required
          variant='outlined'
          onChange={(e) => {
            setCurrentKey(e.target.value);
          }}
          value={currentKey}
          fullWidth
          className={styles.fontType}>
          <MenuItem fullWidth key={`default_template_option_key`} value={-1} disabled>
            Kérem válasszon...
          </MenuItem>
          {option.keys?.map((key) => (
            <MenuItem fullWidth key={`${option.name}_option_key`} value={key.name} className={styles.fontType}>
              {key.displayName}
            </MenuItem>
          ))}
        </Select>
      ) : (
        <Select variant='outlined' defaultValue={-1} fullWidth className={styles.fontType}>
          <MenuItem fullWidth key={`no_items`} value={-1} disabled className={styles.fontType}>
            Nincs megjeleníthető elem
          </MenuItem>
        </Select>
      )}
      {option.values.length > 0 ? (
        <Select
          required
          variant='outlined'
          onChange={(e) => {
            setCurrentValue(e.target.value);
          }}
          value={currentValue}
          fullWidth
          className={styles.fontType}>
          <MenuItem fullWidth key={`default_template_option`} value={-1} disabled className={styles.fontType}>
            Kérem válasszon...
          </MenuItem>
          {option.values?.map((value) => (
            <MenuItem fullWidth key={`${option.name}_option`} value={value.name} className={styles.fontType}>
              {value.displayName}
            </MenuItem>
          ))}
        </Select>
      ) : (
        <Select variant='outlined' defaultValue={-1} fullWidth className={styles.fontType}>
          <MenuItem fullWidth key={`no_items`} value={-1} disabled className={styles.fontType}>
            Nincs megjeleníthető elem
          </MenuItem>
        </Select>
      )}
    </div>
  );
};

export default KeyValueEditor;
