import { Dialog, DialogContent, DialogTitle, Tooltip } from '@mui/material';
import React from 'react';
import styles from './subscriptionDialog.module.css';
import { MdCheck, MdClose, MdInfo } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { useSubscriptionLogic } from 'pages/PhotoAdmin/AdminMenu/Subscription/useSubscriptionLogic';
import ConfirmDialog from 'components/common/ConfirmDialog/ConfirmDialog';
import { useState } from 'react';

const SubscriptionDialog = ({ bundleData, assign, unassign, open, setOpen }) => {
  const shopTier = useSelector((state) => state.nonPersistedReducers?.shopTierSliceReducer?.shopTier);

  const { fetchCardRegister } = useSubscriptionLogic();

  const [confirmOpen, setConfirmOpen] = useState(false);

  const handleSubmit = async () => {
    let request = shopTier?.id !== bundleData?.id ? assign.mutate(bundleData?.id) : unassign.mutate();
    await request;
    return assign?.isSuccess || unassign?.isSuccess ? setOpen(false) : null;
  };
  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)}>
        {shopTier?.id === bundleData?.id && <div className={styles.dialog_banner}>Aktuális előfizetés</div>}
        <DialogContent>
          <div className={styles.dialog_content}>
            <h2>{bundleData?.name}</h2>
            {shopTier?.id !== bundleData?.id && <p>Jelenlegi csomagodhoz viszonyított eltérést sárgával emeltük ki</p>}
            <div className={styles.tier_info_wrapper}>
              <div className={styles.detail_item}>
                <p>Galéria limit:</p> <p>{bundleData?.galleryLimit}db</p>
              </div>
              <div className={styles.detail_item}>
                <p>Képfeltöltési limit:</p> <p>{bundleData?.productsLimit}db</p>
              </div>
              <div className={styles.detail_item}>
                <p>Galéria létrehozás:</p>{' '}
                <p>
                  <MdCheck color='green' fontSize={22} />
                </p>
              </div>
              <div className={styles.detail_item}>
                <p>Képfeltöltés:</p>{' '}
                <p>
                  <MdCheck color='green' fontSize={22} />
                </p>
              </div>
              <div className={styles.detail_item}>
                <Tooltip title="Személyre szabott termékkategóriák és termékek létrehozása, a 'Termékek és sablonok' felületen. Megadhatod pontosan milyen méretű és formátumú képeket szeretnél árusítani!">
                  <p>
                    <MdInfo />
                    Saját termék definiálás:
                  </p>
                </Tooltip>
                <p>
                  <MdCheck color='green' fontSize={22} />
                </p>
              </div>
              <div className={styles.detail_item}>
                <p>Kimutatások:</p>{' '}
                <p>
                  {bundleData?.reports ? (
                    <MdCheck
                      color={
                        bundleData?.reports !== shopTier?.reports && !!shopTier
                          ? 'var(--primary-background-secondary)'
                          : 'green'
                      }
                      fontSize={22}
                    />
                  ) : (
                    <MdClose
                      color={
                        bundleData?.reports !== shopTier?.reports && !!shopTier
                          ? 'var(--primary-background-secondary)'
                          : 'red'
                      }
                      fontSize={22}
                    />
                  )}
                </p>
              </div>
              <div className={styles.detail_item}>
                <p>Nyomtatási lehetőség:</p>{' '}
                <p>
                  {bundleData?.printOption ? (
                    <MdCheck
                      color={
                        bundleData?.printOption !== shopTier?.printOption && !!shopTier
                          ? 'var(--primary-background-secondary)'
                          : 'green'
                      }
                      fontSize={22}
                    />
                  ) : (
                    <MdClose
                      color={
                        bundleData?.printOption !== shopTier?.printOption && !!shopTier
                          ? 'var(--primary-background-secondary)'
                          : 'red'
                      }
                      fontSize={22}
                    />
                  )}
                </p>
              </div>
              <div className={styles.detail_item}>
                <p>Rendelési határidő beállítása:</p>{' '}
                <p>
                  {bundleData?.galleryExpiryDate ? (
                    <MdCheck
                      color={
                        bundleData?.galleryExpiryDate !== shopTier?.galleryExpiryDate && !!shopTier
                          ? 'var(--primary-background-secondary)'
                          : 'green'
                      }
                      fontSize={22}
                    />
                  ) : (
                    <MdClose
                      color={
                        bundleData?.galleryExpiryDate !== shopTier?.galleryExpiryDate && !!shopTier
                          ? 'var(--primary-background-secondary)'
                          : 'red'
                      }
                      fontSize={22}
                    />
                  )}
                </p>
              </div>
              <div className={styles.detail_item}>
                <p>Rendelési határidő öröklés:</p>{' '}
                <p>
                  {bundleData?.expiryDateInheritance ? (
                    <MdCheck
                      color={
                        bundleData?.expiryDateInheritance !== shopTier?.expiryDateInheritance && !!shopTier
                          ? 'var(--primary-background-secondary)'
                          : 'green'
                      }
                      fontSize={22}
                    />
                  ) : (
                    <MdClose
                      color={
                        bundleData?.expiryDateInheritance !== shopTier?.expiryDateInheritance && !!shopTier
                          ? 'var(--primary-background-secondary)'
                          : 'red'
                      }
                      fontSize={22}
                    />
                  )}
                </p>
              </div>
              <div className={styles.detail_item}>
                <p>Áröröklés:</p>{' '}
                <p>
                  {bundleData?.priceInheritance ? (
                    <MdCheck
                      color={
                        bundleData?.priceInheritance !== shopTier?.priceInheritance && !!shopTier
                          ? 'var(--primary-background-secondary)'
                          : 'green'
                      }
                      fontSize={22}
                    />
                  ) : (
                    <MdClose
                      color={
                        bundleData?.priceInheritance !== shopTier?.priceInheritance && !!shopTier
                          ? 'var(--primary-background-secondary)'
                          : 'red'
                      }
                      fontSize={22}
                    />
                  )}
                </p>
              </div>
              <div className={styles.detail_item}>
                <p>Automata csoportkép galéria generálás:</p>{' '}
                <p>
                  {bundleData?.groupGallery ? (
                    <MdCheck
                      color={
                        bundleData?.groupGallery !== shopTier?.groupGallery && !!shopTier
                          ? 'var(--primary-background-secondary)'
                          : 'green'
                      }
                      fontSize={22}
                    />
                  ) : (
                    <MdClose
                      color={
                        bundleData?.groupGallery !== shopTier?.groupGallery && !!shopTier
                          ? 'var(--primary-background-secondary)'
                          : 'red'
                      }
                      fontSize={22}
                    />
                  )}
                </p>
              </div>
              <div className={styles.detail_item}>
                <Tooltip title='Lehetőséget biztosít arra hogy a létrehozott galériákat, külön kereső kulccsal lehessen ellátni. A galériák külön kódolása, mégnagyobb védelmet biztosít, a képeken szereplő személyek számára!'>
                  <p>
                    <MdInfo />
                    Galéria külön kódolása:
                  </p>
                </Tooltip>
                <p>
                  {bundleData?.galleryCoding ? (
                    <MdCheck
                      color={
                        bundleData?.galleryCoding !== shopTier?.galleryCoding && !!shopTier
                          ? 'var(--primary-background-secondary)'
                          : 'green'
                      }
                      fontSize={22}
                    />
                  ) : (
                    <MdClose
                      color={
                        bundleData?.galleryCoding !== shopTier?.galleryCoding && !!shopTier
                          ? 'var(--primary-background-secondary)'
                          : 'red'
                      }
                      fontSize={22}
                    />
                  )}
                </p>
              </div>
              <div className={styles.detail_item}>
                <Tooltip title='A funkció lehetővét teszi, hogy a vásárlók az Iskolaévkönyv felületén végezzék ne csak a rendelés leadását, de még a fizetést is!'>
                  <p>
                    <MdInfo /> Online fizetés:
                  </p>
                </Tooltip>
                <p>
                  {bundleData?.onlinePayment ? (
                    <MdCheck
                      color={
                        bundleData?.onlinePayment !== shopTier?.onlinePayment && !!shopTier
                          ? 'var(--primary-background-secondary)'
                          : 'green'
                      }
                      fontSize={22}
                    />
                  ) : (
                    <MdClose
                      color={
                        bundleData?.onlinePayment !== shopTier?.onlinePayment && !!shopTier
                          ? 'var(--primary-background-secondary)'
                          : 'red'
                      }
                      fontSize={22}
                    />
                  )}
                </p>
              </div>
              <div className={styles.detail_item}>
                <Tooltip title='Lehetőség árazási sablonok léterhozására. Az általad létrehozott sablonokat korlátlanul használhatod, az összes galériádon!'>
                  <p>
                    <MdInfo />
                    Árazási sablon létrehozás:
                  </p>
                </Tooltip>{' '}
                <p>
                  {bundleData?.pricingTemplates ? (
                    <MdCheck
                      color={
                        bundleData?.pricingTemplates !== shopTier?.pricingTemplates && !!shopTier
                          ? 'var(--primary-background-secondary)'
                          : 'green'
                      }
                      fontSize={22}
                    />
                  ) : (
                    <MdClose
                      color={
                        bundleData?.pricingTemplates !== shopTier?.pricingTemplates && !!shopTier
                          ? 'var(--primary-background-secondary)'
                          : 'red'
                      }
                      fontSize={22}
                    />
                  )}
                </p>
              </div>
              <div className={styles.detail_item}>
                <Tooltip title='Lehetőség számlakibocsátó szolgáltató beállítására (Számlázz.hu/Billingo). A számlák automatikusan generálódnak és azokat az Iskolaévkönyv rendszere továbbítja a vásárlók felé!'>
                  <p>
                    <MdInfo />
                    Automata számlázás:
                  </p>
                </Tooltip>{' '}
                <p>
                  {bundleData?.automaticInvoice ? (
                    <MdCheck
                      color={
                        bundleData?.automaticInvoice !== shopTier?.automaticInvoice && !!shopTier
                          ? 'var(--primary-background-secondary)'
                          : 'green'
                      }
                      fontSize={22}
                    />
                  ) : (
                    <MdClose
                      color={
                        bundleData?.automaticInvoice !== shopTier?.automaticInvoice && !!shopTier
                          ? 'var(--primary-background-secondary)'
                          : 'red'
                      }
                      fontSize={22}
                    />
                  )}
                </p>
              </div>
            </div>
            <div className={styles.price_wrapper}>
              <b>Csomag ára:</b> {bundleData?.price ? bundleData?.price : 'Backend TODO'} Ft/hó
            </div>
            <p className={styles.info_message}>
              Az új díjcsomagod a számlázási időszak végén, 2025. jan. 7. dátummal módosul. <br />
              <br /> Egyetértesz azzal, hogy az{' '}
              <b>
                előfizetésed folytatódik, és annak lemondásáig az új havi díjat számítjuk fel. Bármikor lemondhatod,
                hogy elkerüld a következő havidíj levonását.
              </b>
            </p>
            <div className={styles.button_wrapper}>
              <button className={styles.cancel_button} onClick={() => setOpen(false)}>
                Mégsem
              </button>
              {fetchCardRegister?.data?.data?.tokens.length > 0 ? (
                <button
                  disabled={assign?.isLoading || unassign?.isLoading}
                  className={styles.submit_button}
                  onClick={() => setConfirmOpen(true)}>
                  {shopTier?.id !== bundleData?.id ? 'Előfizetés módosítása' : 'Előfizetés lemondása'}
                </button>
              ) : (
                <Tooltip title={'Csomag választásához regisztráld a bankkártyádat!'}>
                  <button
                    disabled={assign?.isLoading || unassign?.isLoading}
                    className={styles.disabled_button}
                    onClick={() => {}}>
                    {shopTier?.id !== bundleData?.id ? 'Előfizetés módosítása' : 'Előfizetés lemondása'}
                  </button>
                </Tooltip>
              )}
            </div>
          </div>
        </DialogContent>
        <ConfirmDialog
          open={confirmOpen}
          onClose={() => setConfirmOpen(false)}
          functionButtonText={'Igen'}
          closeButtonText={'Mégsem'}
          multipleButtons
          title={'Biztosan módosítani szeretnéd előfizetésed?'}
          onOk={() => {
            handleSubmit();
            setConfirmOpen(false);
          }}
        />
      </Dialog>
    </>
  );
};

export default SubscriptionDialog;
