import { TextField } from '@material-ui/core';
import ModifierForm from 'components/PhotoAdminComponents/ModifierForm/ModifierForm';
import React from 'react';
import { useEffect } from 'react';
import styles from './TemplateDetails.module.css';

const TemplateDetails = ({
  name,
  setName,
  modifiers,
  setModifiers,
  buttonRef,
  selectedTemplate,
  setFormChanged,
  setDisabled
}) => {
  const changeChecker = () => {
    if (selectedTemplate) {
      if (name !== selectedTemplate?.name) {
        setFormChanged(true);
      } else if (JSON.stringify(modifiers) !== JSON.stringify(selectedTemplate?.modifiers)) {
        setFormChanged(true);
      } else {
        setFormChanged(false);
      }
    }
  };

  useEffect(() => {
    changeChecker();
  }, [name]);
  return (
    <div className={styles.wrapper}>
      <div className={styles.titleWrapper}>
        <TextField
          variant='outlined'
          className={styles.name}
          InputProps={{
            className: styles.name,
            style: {
              fontFamily: `var(--font-secondary), Sans-serif, Arial`
            }
          }}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </div>
      <div className={styles.formWrapper}>
        <ModifierForm
          shopId={null} // TODO will we bind templates to shops?
          templateForm={true}
          modifiers={modifiers}
          changeChecker={changeChecker}
          setModifiers={setModifiers}
          buttonRef={buttonRef}
          inherit={false}
          setDisabled={setDisabled}
          belongsTo={null}
        />
      </div>
    </div>
  );
};

export default TemplateDetails;
