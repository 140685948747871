import styles from './ChangeEmailAddressCard.module.css';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Edit from '@material-ui/icons/Edit';
import Button from '@material-ui/core/Button';
import { useEffect, useState, useRef } from 'react';
import { Form, Formik } from 'formik';
import { CircularProgress } from '@material-ui/core';
import SettingsCard from 'components/PhotoAdminComponents/Settings/Card/SettingsCard';
import ActionButton from 'components/common/ActionButton/ActionButton';
import emailChangeValidationSchema from 'validation/UserSettings/EmailChangeSchema';
import { useUserApi } from 'hooks/useUserApi';

const ChangeEmailAddressCard = ({ user, setInProgress, inProgress, showDialog }) => {
  const [isEditing, setIsEditing] = useState(false);
  const toggleIsEditing = () => setIsEditing(!isEditing);
  const formikRef = useRef(null);
  const { getCurrentUser, putUserEmail } = useUserApi();

  const [emailData, setEmailData] = useState({ email: '' });

  const putEmailWithDialog = async ({ user, email }) => {
    setInProgress('email');
    const status = await putUserEmail({ user: user, email: email });
    getCurrentUser();
    showDialog(status);
    setInProgress(null);
  };

  useEffect(() => {
    if (user && formikRef.current) {
      formikRef.current.values.contactEmail = emailData?.email;
    }
  }, [user, formikRef]);

  useEffect(() => {
    if (isEditing) {
      setEmailData({ email: '' });
    } else {
      setEmailData({
        email: user?.contact?.email
      });
    }
  }, [user, isEditing]);

  return (
    <SettingsCard
      title='Email cím'
      actionButton={
        <ActionButton
          icon={<Edit color='primary' />}
          clickHandler={toggleIsEditing}
          className={styles.actionButtonStyle}
        />
      }>
      <Formik
        innerRef={formikRef}
        enableReinitialize
        initialValues={{
          contactEmail: '',
          emailConfirm: ''
        }}
        validationSchema={emailChangeValidationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          await putEmailWithDialog({ user: { ...user }, email: values.contactEmail });
          setIsEditing(false);
          setSubmitting(false);
        }}>
        {({ values, errors, touched, handleChange, handleSubmit }) => (
          <Form className={styles.form}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  disabled={!isEditing}
                  variant='outlined'
                  fullWidth
                  required
                  label='Email cím'
                  id='contactEmail'
                  name='contactEmail'
                  type='email'
                  value={isEditing ? values.contactEmail : emailData?.email}
                  onChange={handleChange}
                  error={touched.contactEmail && Boolean(errors.contactEmail)}
                  helperText={touched.contactEmail && errors.contactEmail}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  disabled={!isEditing}
                  variant='outlined'
                  fullWidth
                  required
                  label='Email cím megerősítése'
                  id='emailConfirm'
                  name='emailConfirm'
                  type='email'
                  value={values.emailConfirm}
                  onChange={handleChange}
                  error={touched.emailConfirm && Boolean(errors.emailConfirm)}
                  helperText={touched.emailConfirm && errors.emailConfirm}
                />
              </Grid>
            </Grid>
            <Grid container className={styles.gridContainer}>
              <Grid item xs={12}>
                <Button disabled={!isEditing} type='submit' fullWidth onClick={handleSubmit} variant='outlined'>
                  {inProgress === 'email' ? (
                    <>
                      Folyamatban
                      <CircularProgress style={{ marginLeft: '0.5rem' }} size={24} />
                    </>
                  ) : (
                    'Email cím frissítése'
                  )}
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </SettingsCard>
  );
};

export default ChangeEmailAddressCard;
