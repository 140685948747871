/** @format */

import { useRef, useEffect } from 'react';
import { Checkbox, FormControlLabel, Grid, TextField, Typography } from '@material-ui/core';
import { Form, Formik } from 'formik';
import billingValidationSchema from 'validation/Registration/BillingSchema';
import styles from './BillingForm.module.css';
import PostalCodeFields from '../PostalCodeFields/PostalCodeFields';
import { errorToast, successToast } from 'components/common/Toast/Toast';

const BillingForm = ({
  billingData,
  setBillingData,
  contactData,
  handleBack,
  setSubmitted,
  newsAccept,
  setNewsAccept,
  subscribeNewsletter
}) => {
  const formikRef = useRef(null);

  useEffect(() => {
    if (!billingData?.billingCity && billingData?.billingCity !== '') {
      setBillingData({
        ...billingData,
        billingCity: contactData?.contactCity
      });
    }
  }, [contactData, setBillingData]);

  useEffect(() => {
    formikRef.current.setFieldValue('billingCity', billingData?.billingCity);
    formikRef.current.setFieldValue('billingZipCode', billingData?.billingZipCode);
  }, [billingData]);

  return (
    <Formik
      innerRef={formikRef}
      enableReinitialize
      initialValues={{
        companyName: billingData?.companyName || '',
        billingVatNumber: billingData?.billingVatNumber || '',
        billingAccountNumber: billingData?.billingAccountNumber || '',
        billingCountry: billingData?.billingCountry || contactData.contactCountry,
        billingCity: billingData?.billingCity || contactData.contactCity,
        billingZipCode: billingData?.billingZipCode || contactData.contactZipCode,
        billingStreetAndNumber: billingData?.billingStreetAndNumber || contactData.contactStreetAndNumber
      }}
      validationSchema={billingValidationSchema}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        if (newsAccept) {
          let value = contactData;
          await subscribeNewsletter({ value }).then((res) => {
            if (res) {
              successToast('Hírlevélre való feliratkozás sikeres');
              setSubmitting(true);
              setBillingData(values);
              setSubmitted(true);
              setSubmitting(false);
            } else {
              errorToast('Hírlevélre való feliratkozás sikertelen');
            }
          });
        } else {
          setSubmitting(true);
          setBillingData(values);
          setSubmitted(true);
          setSubmitting(false);
        }
      }}>
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, resetForm }) => (
        <Form className={styles.formPart}>
          <Grid container xs={12}>
            <Grid item xs={12} md={6} className={styles.control}>
              <Typography variant='h4' className={styles.label}>
                Számlázási név
              </Typography>
              <TextField
                variant='standard'
                className={styles.input}
                required
                autoFocus
                id='companyName'
                name='companyName'
                value={values.companyName}
                onChange={(e) => {
                  handleChange(e);
                  setBillingData({
                    ...billingData,
                    companyName: e.target.value
                  });
                }}
                error={touched.companyName && Boolean(errors.companyName)}
                helperText={touched.companyName && errors.companyName}
              />
            </Grid>
            <Grid item xs={12} md={6} className={styles.control}>
              <Typography variant='h4' className={styles.label}>
                Adószám
              </Typography>
              <TextField
                variant='standard'
                className={styles.input}
                required
                id='billingVatNumber'
                name='billingVatNumber'
                value={values.billingVatNumber}
                onChange={(e) => {
                  handleChange(e);
                  setBillingData({
                    ...billingData,
                    billingVatNumber: e.target.value
                  });
                }}
                error={touched.billingVatNumber && Boolean(errors.billingVatNumber)}
                helperText={touched.billingVatNumber && errors.billingVatNumber}
              />
            </Grid>
            <Grid item xs={12} className={styles.control}>
              <Typography variant='h4' className={styles.label}>
                Bankszámlaszám
              </Typography>
              <TextField
                variant='standard'
                className={styles.input}
                required
                id='billingAccountNumber'
                name='billingAccountNumber'
                value={values.billingAccountNumber}
                onChange={(e) => {
                  handleChange(e);
                  setBillingData({
                    ...billingData,
                    billingAccountNumber: e.target.value
                  });
                }}
                error={touched.billingAccountNumber && Boolean(errors.billingAccountNumber)}
                helperText={touched.billingAccountNumber && errors.billingAccountNumber}
              />
            </Grid>

            <Grid item xs={12} className={styles.control}>
              <Typography variant='h4' className={styles.subtitle}>
                Számlázási cím
              </Typography>
            </Grid>

            {formikRef?.current && (
              <PostalCodeFields
                data={billingData}
                setData={setBillingData}
                billing={true}
                handleChange={handleChange}
                zipHelper={formikRef?.current?.touched?.billingZipCode}
                zipError={formikRef?.current?.errors?.billingZipCode}
                cityHelper={formikRef?.current?.touched?.billingCity}
                citypError={formikRef?.current?.errors?.billingCity}
              />
            )}
            <Grid item xs={12} md={6} className={styles.control}>
              <Typography variant='h4' className={styles.label}>
                Utca, házszám
              </Typography>
              <TextField
                variant='standard'
                className={styles.input}
                required
                id='billingStreetAndNumber'
                name='billingStreetAndNumber'
                fullWidth
                autoComplete='shipping address-line1'
                value={values.billingStreetAndNumber}
                onChange={(e) => {
                  handleChange(e);
                  setBillingData({
                    ...billingData,
                    billingStreetAndNumber: e.target.value
                  });
                }}
                error={touched.billingStreetAndNumber && Boolean(errors.billingStreetAndNumber)}
                helperText={touched.billingStreetAndNumber && errors.billingStreetAndNumber}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant='h4' className={styles.label}>
                Ország
              </Typography>
              <TextField
                variant='standard'
                disabled
                className={styles.input}
                required
                id='billingCountry'
                name='billingCountry'
                fullWidth
                autoComplete='shipping country'
                value={values.billingCountry}
                onChange={handleChange}
                error={touched.billingCountry && Boolean(errors.billingCountry)}
                helperText={touched.billingCountry && errors.billingCountry}
              />
            </Grid>
          </Grid>
          <Grid
            item
            container
            style={{
              margin: '2rem 0 0 0',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-around'
            }}>
            <div>
              <Typography
                variant='h5'
                style={{
                  fontSize: '1rem'
                }}>
                Szeretnék a továbbiakban is értesülni a rendszert érintő újdonságokról, iskolai fotózással kapcsolatos
                fotós szakmai hírekről, tippekről.
              </Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    color='primary'
                    name='submitted'
                    id='submitted'
                    checked={newsAccept}
                    required
                    onChange={(e) => {
                      setNewsAccept(e.target.checked);
                    }}
                    error={!newsAccept}
                  />
                }
                label={
                  <div className={styles.data}>
                    <p>Feliratkozom a hírlevélre</p>
                  </div>
                }
              />
            </div>
            <Grid item xs={6} className={styles.buttonContainer}>
              <button
                onClick={() => {
                  setBillingData({ ...values });
                  handleBack();
                }}
                variant='outlined'
                className={styles.button}>
                Vissza
              </button>
            </Grid>
            <Grid item xs={6} className={styles.buttonContainer}>
              <button type='submit' onClick={handleSubmit} variant='outlined' className={styles.button}>
                Regisztrálok
              </button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default BillingForm;
