import ChangeBillingNameCard from '../ChangeBillingNameCard/ChangeBillingNameCard';
import ChangeVatNumberCard from '../ChangeVatNumberCard/ChangeVatNumberCard';
import ChangeBankAccountNumberCard from '../ChangeBankAccountNumberCard/ChangeBankAccountNumberCard';
import ChangeBillingAddressCard from '../ChangeBillingAddressCard/ChangeBillingAddressCard';
import styles from './BillingCardsContainer.module.css';

const BillingCardsContainer = ({ user, inProgress, setInProgress, showDialog }) => {
  return (
    <div className={styles.cardContainer}>
      <div className={styles.cards}>
        <ChangeBillingNameCard
          user={user}
          inProgress={inProgress}
          setInProgress={setInProgress}
          showDialog={showDialog}
        />
      </div>
      <div className={styles.cards}>
        <ChangeVatNumberCard
          user={user}
          inProgress={inProgress}
          setInProgress={setInProgress}
          showDialog={showDialog}
        />
      </div>
      <div className={styles.cards}>
        <ChangeBankAccountNumberCard
          user={user}
          inProgress={inProgress}
          setInProgress={setInProgress}
          showDialog={showDialog}
        />
      </div>
      <div className={styles.addressCard}>
        <ChangeBillingAddressCard
          user={user}
          inProgress={inProgress}
          setInProgress={setInProgress}
          showDialog={showDialog}
        />
      </div>
    </div>
  );
};

export default BillingCardsContainer;
