import { Autocomplete, TextField } from '@mui/material';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import styles from './GroupNameEditor.module.css';
import { useGroupNameEditorLogic } from './hooks/useGroupNameEditorLogic';

const GroupNameEditor = ({ option, setOption }) => {
  const { fetchGroups, groups, groupLoading } = useGroupNameEditorLogic();

  const [selectedSchool, setSelectedSchool] = useState(null);
  const [schoolOptions, setSchoolOptions] = useState([]);

  useEffect(() => {
    fetchGroups();
  }, []);

  useEffect(() => {
    if (groups) {
      setSchoolOptions(groups);
    }
  }, [groups]);
  useEffect(() => {
    if (option.value !== '' && groups) {
      setSelectedSchool(groups.find((g) => option.value.includes(g.name)));
    }
  }, [groups]);

  useEffect(() => {
    if (selectedSchool) {
      const newValue = `${option.name}=${selectedSchool.name}`;
      if (decodeURI(option.value) !== decodeURI(newValue)) {
        setOption({ ...option, value: encodeURI(newValue) });
      }
    }
  }, [selectedSchool]);

  return (
    <div className={styles.mainContainer}>
      {groupLoading ? (
        <p>Loading...</p>
      ) : (
        <Autocomplete
          disabled={!groups || groups?.length === 0}
          disablePortal
          disableClearable
          id='school-autocomplete'
          options={schoolOptions}
          getOptionLabel={(option) => option?.name || ''}
          sx={{ width: 300, fontFamily: 'var(--font-main)' }}
          value={selectedSchool}
          className={styles.autocomplete}
          onChange={(event, selectedSchool) => {
            setSelectedSchool(selectedSchool);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                style: { fontFamily: 'var(--font-main)' }
              }}
              label='Iskola'
            />
          )}
        />
      )}
    </div>
  );
};

export default GroupNameEditor;
