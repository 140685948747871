import { useNavigate } from 'react-router-dom';
import styles from './HeaderProfile.module.css';
import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { MdLogout, MdPayment, MdPerson, MdSettings, MdStore } from 'react-icons/md';
import ProfileAvatar from './ProfileAvatar/ProfileAvatar';
import AvatarIcon from './AvatarIcon/AvatarIcon';
// import { routes } from '../../../../config/routes';
import { useSelector } from 'react-redux';

const HeaderProfile = ({ user, handleLogout }) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const shopId = useSelector((state) => state.nonPersistedReducers?.shopSliceReducer?.shopId);

  return (
    <div className={styles.container}>
      <Tooltip title='Profil'>
        <IconButton
          onClick={handleClick}
          size='small'
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={open ? 'true' : undefined}>
          <AvatarIcon width={32} height={32} />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id='account-menu'
        open={open}
        disableScrollLock
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1
            },
            '&::before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0
            }
          }
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
        <ProfileAvatar />
        <Divider />
        <MenuItem
          onClick={() => {
            navigate(`/settings/profile`);
            handleClose();
          }}
          style={{ marginTop: '0.5rem' }}>
          <ListItemIcon>
            <MdPerson />
          </ListItemIcon>
          Profil beállítások
        </MenuItem>
        {/* <MenuItem
          onClick={() => {
            navigate(`/settings/subscriptions`);
            handleClose();
          }}>
          <ListItemIcon>
            <MdPayment />
          </ListItemIcon>
          Előfizetői beállítások
        </MenuItem> */}
        <Divider />
        <MenuItem
          onClick={() => {
            navigate(`/shops`);
            handleClose();
          }}
          disabled={user?.ownedShops?.length === 1 && user?.maintainedShops?.length === 0}>
          <ListItemIcon>
            <MdStore size={18} />
          </ListItemIcon>
          Boltok
        </MenuItem>
        <MenuItem
          onClick={() => {
            navigate(`/shops/${shopId}/administration/orders`);
            handleClose();
          }}
          disabled={shopId === -1}>
          <ListItemIcon style={{ position: 'relative' }}>
            <MdStore size={18} />
            <div className={styles.special_icon_wrapper}>
              <MdSettings className={styles.specialIcon} />
            </div>
          </ListItemIcon>
          Bolt adminisztráció
        </MenuItem>

        <MenuItem
          onClick={() => {
            handleLogout();
            handleClose();
          }}>
          <ListItemIcon>
            <MdLogout />
          </ListItemIcon>
          Kijelentkezés
        </MenuItem>
      </Menu>
    </div>
  );
};

export default HeaderProfile;
