import { Button, Dialog, MenuItem, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import styles from './ProductTypeDialog.module.css';
import Divider from 'components/common/Divider/Divider';
import { Formik } from 'formik';

const ProductTypeDialog = ({ open, setOpen }) => {
  const [formDataDialog, setFormDataDialog] = useState({
    name: '',
    description: '',
    category: ''
  });

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} className={styles.dialog}>
      <h3 className={styles.dialogH3}>Új termék hozzáadása</h3>
      <Divider isHorizontal={true} />
      <Formik
        enableReinitialize
        initialValues={formDataDialog}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          setSubmitting(false);
          resetForm();
        }}>
        {({ values, handleChange, handleSubmit }) => (
          <form className={styles.dialogContainer}>
            <TextField
              variant='outlined'
              label='Név'
              size='small'
              value={values.name}
              onChange={(e) => {
                handleChange(e);
              }}></TextField>
            <TextField
              variant='outlined'
              label='Leírás'
              size='small'
              value={values.description}
              onChange={(e) => {
                handleChange(e);
              }}></TextField>
            <TextField
              select
              variant='outlined'
              label='Kategória címke'
              size='small'
              value={values.category}
              onChange={(e) => {
                handleChange(e);
              }}>
              <MenuItem value='Formátum'>Formátum</MenuItem>
              <MenuItem value='Méret'>Méret</MenuItem>
            </TextField>
            <Button
              className={styles.buttonRed}
              type='submit'
              onClick={() => {
                handleClose();
                handleSubmit();
              }}>
              Termék létrehozása
            </Button>
            <Button
              className={styles.buttonDark}
              onClick={() => {
                handleClose();
              }}>
              Mégsem
            </Button>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};

export default ProductTypeDialog;
