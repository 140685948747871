import { Button, CircularProgress, Tab, Tabs, Typography } from '@material-ui/core';
import { useUserApi } from 'hooks/useUserApi';
import { useState } from 'react';
import styles from './Profile.module.css';
import ConfirmDialog from 'components/common/ConfirmDialog/ConfirmDialog';
import { errorToast, successToast } from 'components/common/Toast/Toast';
import LoadIndicator from 'components/common/LoadIndicator/LoadIndicator';
import { useSelector } from 'react-redux';
import BillingCardsContainer from 'components/PhotoAdminComponents/Settings/Profile/BillingCards/BillingCardsContainer/BillingCardsContainer';
import ContactCardsContainer from 'components/PhotoAdminComponents/Settings/Profile/ContactCards/ContactCardsContainer/ContactCardsContainer';

const Profile = () => {
  const [inProgress, setInProgress] = useState();
  const { deleteCurrentUser, userError } = useUserApi();

  const [inactiveDialogOpen, setInactiveDialogOpen] = useState(false);

  const user = useSelector((state) => state.nonPersistedReducers?.userSliceReducer?.currentUser);

  const [activeTab, setActiveTab] = useState(0);

  const showDialog = (status) => {
    if (status === 200 || userError) {
      successToast('Adatait sikeresen frissítette');
    } else {
      errorToast('Adatainak frissítése sikertelen');
    }
  };

  const putInactiveWithDialog = async () => {
    setInProgress('delete');
    await deleteCurrentUser();
    setInactiveDialogOpen(false);
  };

  return (
    <div className={styles.mainContainer}>
      <Typography className={styles.title}>Profil beállítások</Typography>
      <Typography style={{ fontStyle: 'oblique' }}>#Felhasználónév: {user?.username}</Typography>
      {user ? (
        <>
          <Tabs
            TabIndicatorProps={{
              style: { background: 'var(--primary-main)' }
            }}
            value={activeTab}>
            <Tab
              selected={activeTab === 0}
              label='Kapcsolati adatok'
              onClick={() => {
                setActiveTab(0);
              }}
            />
            <Tab
              selected={activeTab === 1}
              label='Számlázási adatok'
              onClick={() => {
                setActiveTab(1);
              }}
            />
          </Tabs>
          {activeTab === 0 && (
            <ContactCardsContainer
              user={user}
              inProgress={inProgress}
              setInProgress={setInProgress}
              showDialog={showDialog}
            />
          )}
          {activeTab === 1 && (
            <BillingCardsContainer
              user={user}
              inProgress={inProgress}
              setInProgress={setInProgress}
              showDialog={showDialog}
            />
          )}
          <div className={styles.deleteContainer}>
            <Button onClick={() => setInactiveDialogOpen(true)} variant='outlined' className={styles.buttonDelete}>
              {inProgress ? (
                <>
                  Folyamatban
                  <CircularProgress style={{ marginLeft: '0.5rem' }} size={24} />
                </>
              ) : (
                'Felhasználói fiók törlése'
              )}
            </Button>
          </div>
        </>
      ) : (
        <LoadIndicator />
      )}
      <ConfirmDialog
        title={
          'Figyelem! Amennyiben törli fiókját, minden ehhez a fiókhoz tartozó adat, galéria és termék eltávolításra kerül oldalunkról, így azokat a későbbiekben már nem fogja tudni elérni! Biztosan törölni szeretné?'
        }
        open={inactiveDialogOpen}
        onOk={() => {
          putInactiveWithDialog();
          setInactiveDialogOpen(false);
        }}
        onClose={() => setInactiveDialogOpen(false)}
        functionButtonColor={'var(--primary-success)'}
        functionButtonText={'Igen'}
        closeButtonText={'Nem'}
        multipleButtons
      />
    </div>
  );
};

export default Profile;
