import React, { useState } from 'react';
import logoSvg from 'assets/images/logo.svg';
import styles from './BundleCard.module.css';
import { MdCheck, MdClose } from 'react-icons/md';
import SubscriptionDialog from '../SubscriptionDialog/SubscriptionDialog';

const BundleCard = ({ bundleData, currentTier, assign, unassign }) => {
  const [open, setOpen] = useState();

  return (
    <div className={styles.outsideCard}>
      <div
        className={`${currentTier?.id !== bundleData?.id ? styles.mainContainer : styles.mainContainerOutlined} ${
          styles.fadeIn
        }`}>
        {currentTier?.id === bundleData?.id && <div className={styles.current_banner}>Aktuális előfizetés</div>}
        <div className={styles.innerPart}>
          <div className={styles.logoContainer}>
            <img src={logoSvg} alt='logo' />
          </div>
          <h2>{bundleData?.name}</h2>
          <div className={styles.infoSection}>
            <div className={styles.info_item}>
              Galéria létrehozás: <MdCheck color='green' size={20} />
            </div>
            <div className={styles.info_item}>
              Képfeltöltés: <MdCheck color='green' size={20} />
            </div>
            <div className={styles.info_item}>
              Saját termék definiálás: <MdCheck color='green' size={20} />
            </div>
            <div className={styles.info_item}>
              Rendelési határidő beállítás:{' '}
              {bundleData?.galleryExpiryDate ? <MdCheck color='green' size={20} /> : <MdClose color='red' size={20} />}
            </div>
            <div className={styles.info_item}>
              Online fizetés:{' '}
              {bundleData?.onlinePayment ? <MdCheck color='green' size={20} /> : <MdClose color='red' size={20} />}
            </div>
            <div className={styles.info_item}>
              Árazási sablon létrehozás:{' '}
              {bundleData?.pricingTemplates ? <MdCheck color='green' size={20} /> : <MdClose color='red' size={20} />}
            </div>
            <div className={styles.info_item}>
              Automata számlázás:{' '}
              {bundleData?.automaticInvoice ? <MdCheck color='green' size={20} /> : <MdClose color='red' size={20} />}
            </div>
            <div className={styles.info_item}>
              Galéria limit:{' '}
              <span>{bundleData?.galleryLimit > 100 ? <b>Korlátlan</b> : `${bundleData?.galleryLimit} db`}</span>
            </div>
            <div className={styles.info_item}>
              Képfeltöltési limit:{' '}
              <span>{bundleData?.productsLimit > 1000 ? <b>Korlátlan</b> : `${bundleData?.productsLimit} db`}</span>
            </div>
          </div>
          <div className={styles.payment}>{bundleData.paymentMonth}1000 Ft/hó</div>
          <div className={styles.payment}>{bundleData.paymentYear}10000 Ft/év</div>
          <button className={styles.introButton} onClick={() => setOpen(true)}>
            {'Előfizetés kiválasztása'}
          </button>
        </div>
      </div>
      <SubscriptionDialog open={open} setOpen={setOpen} bundleData={bundleData} assign={assign} unassign={unassign} />
    </div>
  );
};

export default BundleCard;
