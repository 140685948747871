import { MenuItem, Select } from '@material-ui/core';
import React, { useState } from 'react';
import styles from './SelectEditor.module.css';

const SelectEditor = ({ option, setOption }) => {
  const [currentOption, setCurrentOption] = useState(option.value === '' ? -1 : option.value.split('=')[1]);
  return (
    <div className={styles.mainContainer}>
      {option.values.length > 0 ? (
        <Select
          required
          variant='outlined'
          onChange={(e) => {
            setOption({ ...option, value: `${option.name}=${e.target.value}` });
            setCurrentOption(e.target.value);
          }}
          value={currentOption}
          fullWidth
          className={styles.menuFont}>
          <MenuItem fullWidth key={`default_template_option`} value={-1} disabled className={styles.menuFont}>
            Kérem válasszon...
          </MenuItem>
          {option.values?.map((value, idx) => (
            <MenuItem fullWidth key={`${option.name}_option`} value={value} className={styles.menuFont}>
              {option.displayedValues[idx]}
            </MenuItem>
          ))}
        </Select>
      ) : (
        <Select variant='outlined' defaultValue={-1} fullWidth>
          <MenuItem fullWidth key={`no_items`} value={-1} disabled>
            Nincs megjeleníthető elem
          </MenuItem>
        </Select>
      )}
    </div>
  );
};

export default SelectEditor;
