/** @format */

import { Button, Checkbox, CircularProgress, Grid, Tooltip, Typography } from '@material-ui/core';
import SettingsCard from 'components/PhotoAdminComponents/Settings/Card/SettingsCard';
import React, { useEffect, useRef, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import InfoIcon from '@mui/icons-material/Info';
import { Form, Formik } from 'formik';
import styles from './InvoiceVendorForm.module.css';
import validationSchema from 'validation/InvoiceVendor/Schema';
import { useSelector } from 'react-redux';
import PostalCodeFields from '../PostalCodeFields/PostalCodeFields';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import { MenuItem } from '@mui/material';

const InvoiceVendorForm = ({ handleSave, invoiceVendor, user, loading, setOpen, disabled }) => {
  const formikRef = useRef(null);
  const shopId = useSelector((state) => state.nonPersistedReducers?.shopSliceReducer?.shopId);

  const [isChecked, setIsChecked] = useState(false);
  const [showAPI, setShowAPI] = React.useState(false);
  const [showUser, setShowUser] = React.useState(false);

  const [vendorData, setVendorData] = useState({
    city: '',
    zipcode: ''
  });

  useEffect(() => {
    if (invoiceVendor) {
      setVendorData({
        city: invoiceVendor?.businessContact?.address?.city,
        zipcode: invoiceVendor?.businessContact?.address?.zipcode
      });
    }
  }, [invoiceVendor, formikRef]);

  useEffect(() => {
    formikRef.current.values.city = vendorData?.city;
    formikRef.current.values.zipcode = vendorData?.zipcode;
  }, [vendorData, formikRef]);

  useEffect(() => {
    if (isChecked && user) {
      setVendorData({
        city: user?.billingInfo?.billingAddress?.city,
        zipcode: user?.billingInfo?.billingAddress?.zipCode
      });

      formikRef.current.values.street = user?.billingInfo?.billingAddress?.streetAndNumber;
      formikRef.current.values.streetNo = '';
      formikRef.current.values.vatNumber = user?.billingInfo?.vatNumber;
    } else {
      formikRef.current.values.street = invoiceVendor ? invoiceVendor?.businessContact?.address?.street : '';
      formikRef.current.values.streetNo = invoiceVendor ? invoiceVendor?.businessContact?.address?.streetNo : '';
      formikRef.current.values.vatNumber = invoiceVendor ? invoiceVendor?.businessContact?.vatNumber : '';
      setVendorData({
        city: invoiceVendor ? invoiceVendor?.businessContact?.address?.city : '',
        zipcode: invoiceVendor ? invoiceVendor?.businessContact?.address?.zipcode : ''
      });
    }
  }, [isChecked, user, invoiceVendor, formikRef]);

  return (
    <SettingsCard>
      <Formik
        innerRef={formikRef}
        initialValues={{
          id: invoiceVendor?.id || '',
          name: invoiceVendor?.name || '',
          isActive: invoiceVendor?.isActive || false,
          // statusUrl: invoiceVendor?.statusUrl || "https://szamlazz.hu",
          vendorType: invoiceVendor?.vendorType || 'SZAMLAZZ_HU',
          vat: invoiceVendor?.vat || '0',
          shopId: invoiceVendor?.shopId || shopId,
          companyName: invoiceVendor?.businessContact?.companyName || '',
          vatNumber: invoiceVendor?.businessContact?.vatNumber || '',
          country: 'Magyarország',
          city: invoiceVendor?.businessContact?.address?.city || '',
          zipcode: invoiceVendor?.businessContact?.address?.zipcode || '',
          street: invoiceVendor?.businessContact?.address?.street || '',
          streetNo: invoiceVendor?.businessContact?.address?.streetNo || '',
          bankName: invoiceVendor?.businessContact?.bankAccount?.bankName || '',
          accountNumber: invoiceVendor?.businessContact?.bankAccount?.accountNumber || '',
          iban: invoiceVendor?.businessContact?.bankAccount?.iban || '',
          type: 'client',
          clientId: invoiceVendor ? invoiceVendor?.credentials?.clientId : '',
          clientSecret: invoiceVendor ? invoiceVendor?.credentials?.clientSecret : '',
          invoices: invoiceVendor?.invoices || []
        }}
        validate={false}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          handleSave({
            body: {
              id: values.id,
              name: values.name,
              isActive: values.isActive,
              statusUrl: values.statusUrl,
              vendorType: values.vendorType,
              vat: values.vat,
              shopId: values.shopId || shopId,
              businessContact: {
                companyName: values.companyName,
                vatNumber: values.vatNumber,
                address: {
                  country: values.country,
                  city: values.city,
                  zipcode: values.zipcode,
                  street: values.street,
                  streetNo: values.streetNo
                },
                bankAccount: {
                  bankName: values.bankName,
                  accountNumber: values.accountNumber,
                  iban: values.iban
                }
              },
              credentials: {
                type: values.type,
                clientId: values.clientId,
                clientSecret: values.clientSecret
              },
              invoices: values.invoices
            },
            setOpen: setOpen
          });
          setSubmitting(false);
          resetForm();
        }}>
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, resetForm }) => (
          <Form className={styles.form}>
            <h1 className={styles.cardTitle}>
              {' '}
              {invoiceVendor ? invoiceVendor?.name : 'Új számlakibocsátó beállítása'}
              {disabled && (
                <p className={styles.disabledMessage}>
                  Demo bolt esetén a számlakibocsátó szolgáltatás módosítása nem lehetséges!
                </p>
              )}
            </h1>
            <Grid container spacing={2} alignContent='center'>
              <Grid item xs={12}>
                <Typography
                  style={{
                    fontWeight: 'bold',
                    fontSize: '1.2rem'
                  }}>
                  Integráció adatai
                </Typography>
              </Grid>
              <Grid container item xs={12} md={6}>
                <Grid item xs={12}>
                  <Typography color='primary' variant='body1'>
                    Integráció neve (Pl. Számlázz.hu)
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    disabled={loading || disabled}
                    variant='outlined'
                    margin='normal'
                    fullWidth
                    required
                    id='name'
                    name='name'
                    value={values.name}
                    onChange={(e) => {
                      handleChange(e);
                      setVendorData({
                        ...vendorData,
                        name: e.target.value
                      });
                    }}
                    error={Boolean(errors.name)}
                    helperText={touched.name && errors.name}
                  />
                </Grid>
              </Grid>
              {/* <Grid container spacing={0} item xs={12} md={6}>
                <Grid item xs={12}>
                  <Typography color="primary" variant="body1">
                    Szolgáltatás státusz URL-je
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    disabled
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="statusUrl"
                    name="statusUrl"
                    value={values.statusUrl}
                    onChange={(e) => {
                      handleChange(e);
                      setVendorData({
                        ...vendorData,
                        statusUrl: e.target.value,
                      });
                    }}
                    error={Boolean(errors.statusUrl)}
                    helperText={touched.statusUrl && errors.statusUrl}
                  />
                </Grid>
              </Grid> */}
              <Grid container item xs={12} md={6}>
                <Grid item xs={12}>
                  <Typography color='primary' variant='body1'>
                    Szolgáltató típusa
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    disabled={loading || disabled}
                    variant='outlined'
                    margin='normal'
                    fullWidth
                    required
                    select
                    id='vendorType'
                    name='vendorType'
                    value={values.vendorType}
                    onChange={(e) => {
                      handleChange(e);
                      setVendorData({
                        ...vendorData,
                        vendorType: e.target.value
                      });
                    }}
                    error={Boolean(errors.vendorType)}
                    helperText={touched.vendorType && errors.vendorType}>
                    <MenuItem value={'SZAMLAZZ_HU'}>SZAMLAZZ_HU</MenuItem>
                    <MenuItem value={'BILLINGO'}>BILLINGO</MenuItem>
                  </TextField>
                </Grid>
              </Grid>
              <Grid container item xs={12} md={6}>
                <Grid item xs={12}>
                  <Tooltip
                    title={'Más néven Számla Agent kulcs. A számlázz.hu fiókjában a vezérlőpult alján találhatja meg!'}>
                    <Typography color='primary' variant='body1'>
                      API kulcs
                      <InfoIcon
                        style={{
                          fontSize: '0.9rem',
                          opacity: '0.5',
                          marginLeft: '0.5rem'
                        }}
                      />
                    </Typography>
                  </Tooltip>
                </Grid>
                <Grid item xs={12}>
                  <OutlinedInput
                    disabled={loading || disabled}
                    type={showAPI ? 'text' : 'password'}
                    endAdornment={
                      <InputAdornment position='end'>
                        <IconButton
                          aria-label='toggle password visibility'
                          onClick={
                            disabled
                              ? () => {}
                              : () => {
                                  setShowAPI(!showAPI);
                                }
                          }
                          edge='end'>
                          {showAPI ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    variant='outlined'
                    margin='normal'
                    fullWidth
                    required
                    id='clientSecret'
                    name='clientSecret'
                    value={values.clientSecret}
                    onChange={(e) => {
                      handleChange(e);
                      setVendorData({
                        ...vendorData,
                        clientSecret: e.target.value
                      });
                    }}
                    error={Boolean(errors.clientSecret)}
                    helperText={touched.clientSecret && errors.clientSecret}
                  />
                </Grid>
              </Grid>
              <Grid container item xs={12} md={6}>
                <Grid item xs={12}>
                  <Tooltip
                    title={
                      'A számla előtag arra szolgál, hogy megkönnyítse a számlázz.hu felületen a számlák megkülönböztetését. A számlázz.hu felületen is használt előtagot adja meg!'
                    }>
                    <Typography color='primary' variant='body1'>
                      Számla előtag
                      <InfoIcon
                        style={{
                          fontSize: '0.9rem',
                          opacity: '0.5',
                          marginLeft: '0.5rem'
                        }}
                      />
                    </Typography>
                  </Tooltip>
                </Grid>
                <Grid item xs={12}>
                  <OutlinedInput
                    disabled={loading || disabled}
                    type={showUser ? 'text' : 'password'}
                    endAdornment={
                      <InputAdornment position='end'>
                        <IconButton
                          aria-label='toggle password visibility'
                          onClick={
                            disabled
                              ? () => {}
                              : () => {
                                  setShowUser(!showUser);
                                }
                          }
                          edge='end'>
                          {showUser ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    variant='outlined'
                    margin='normal'
                    fullWidth
                    required
                    id='clientId'
                    name='clientId'
                    value={values.clientId}
                    onChange={(e) => {
                      handleChange(e);
                      setVendorData({
                        ...vendorData,
                        clientId: e.target.value
                      });
                    }}
                    error={Boolean(errors.clientId)}
                    helperText={touched.clientId && errors.clientId}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  style={{
                    fontWeight: 'bold',
                    fontSize: '1.2rem'
                  }}>
                  Számlázási adatok
                </Typography>
              </Grid>{' '}
              {user && (
                <Grid style={{ margin: '1rem 0' }}>
                  <Checkbox
                    checked={isChecked}
                    color='primary'
                    onChange={() => {
                      setIsChecked(!isChecked);
                    }}
                  />
                  Szeretném a korábban megadott számlázási adataimat használni
                </Grid>
              )}
              <PostalCodeFields
                isEditing={!loading && !disabled}
                data={vendorData}
                setData={setVendorData}
                zipHelper={formikRef?.current?.touched?.zipcode}
                zipError={formikRef?.current?.errors?.zipcode}
                cityHelper={formikRef?.current?.touched?.city}
                citypError={formikRef?.current?.errors?.city}
              />
              <Grid container item xs={12} md={6}>
                <Grid item xs={12}>
                  <Typography color='primary' variant='body1'>
                    Utca
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    disabled={loading || disabled}
                    variant='outlined'
                    margin='normal'
                    fullWidth
                    required
                    id='street'
                    name='street'
                    value={values.street}
                    onChange={(e) => {
                      handleChange(e);
                      setVendorData({
                        ...vendorData,
                        street: e.target.value
                      });
                    }}
                    error={Boolean(errors.street)}
                    helperText={touched.street && errors.street}
                  />
                </Grid>
              </Grid>
              <Grid container item xs={12} md={6}>
                <Grid item xs={12}>
                  <Typography color='primary' variant='body1'>
                    Házszám
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    disabled={loading || disabled}
                    variant='outlined'
                    margin='normal'
                    fullWidth
                    required
                    id='streetNo'
                    name='streetNo'
                    value={values.streetNo}
                    onChange={(e) => {
                      handleChange(e);
                      setVendorData({
                        ...vendorData,
                        streetNo: e.target.value
                      });
                    }}
                    error={Boolean(errors.streetNo)}
                    helperText={touched.streetNo && errors.streetNo}
                  />
                </Grid>
              </Grid>
              <Grid container item xs={12} md={6}>
                <Grid item xs={12}>
                  <Typography color='primary' variant='body1'>
                    Ország
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    disabled
                    variant='outlined'
                    margin='normal'
                    fullWidth
                    required
                    id='country'
                    name='country'
                    value={'Magyarország'}
                    onChange={handleChange}
                    error={Boolean(errors.country)}
                    helperText={touched.country && errors.country}
                  />
                </Grid>
              </Grid>{' '}
              <Grid container item xs={12} md={6}>
                <Grid item xs={12}>
                  <Typography color='primary' variant='body1'>
                    Adószám
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    disabled={loading || disabled}
                    variant='outlined'
                    margin='normal'
                    fullWidth
                    required
                    id='vatNumber'
                    name='vatNumber'
                    value={values.vatNumber}
                    onChange={(e) => {
                      handleChange(e);
                      setVendorData({
                        ...vendorData,
                        vatNumber: e.target.value
                      });
                    }}
                    error={Boolean(errors.vatNumber)}
                    helperText={touched.vatNumber && errors.vatNumber}
                  />
                </Grid>
              </Grid>
              <Grid container item xs={12} md={6}>
                <Grid item xs={12}>
                  <Typography color='primary' variant='body1'>
                    Áfa kulcs
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant='outlined'
                    disabled={loading || disabled}
                    margin='normal'
                    fullWidth
                    required
                    select
                    id='vat'
                    name='vat'
                    value={values.vat}
                    onChange={(e) => {
                      handleChange(e);
                      setVendorData({
                        ...vendorData,
                        vat: e.target.value
                      });
                    }}
                    error={Boolean(errors.vat)}
                    helperText={touched.vat && errors.vat}>
                    <MenuItem value={'0'}>0%</MenuItem>
                    <MenuItem value={'27'}>27%</MenuItem>
                    <MenuItem value={'AAM'}>AAM</MenuItem>
                  </TextField>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  style={{
                    fontWeight: 'bold',
                    fontSize: '1.2rem'
                  }}>
                  Bankszámla adatok
                </Typography>
              </Grid>
              <Grid container item xs={12} md={6}>
                <Grid item xs={12}>
                  <Typography color='primary' variant='body1'>
                    Bank neve
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    disabled={loading || disabled}
                    variant='outlined'
                    margin='normal'
                    fullWidth
                    required
                    id='bankName'
                    name='bankName'
                    value={values.bankName}
                    onChange={(e) => {
                      handleChange(e);
                      setVendorData({
                        ...vendorData,
                        bankName: e.target.value
                      });
                    }}
                    error={Boolean(errors.bankName)}
                    helperText={touched.bankName && errors.bankName}
                  />
                </Grid>
              </Grid>
              <Grid container item xs={12} md={6}>
                <Grid item xs={12}>
                  <Typography color='primary' variant='body1'>
                    Bankszámlaszám
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    disabled={loading || disabled}
                    variant='outlined'
                    margin='normal'
                    fullWidth
                    required
                    id='accountNumber'
                    name='accountNumber'
                    value={values.accountNumber}
                    onChange={(e) => {
                      handleChange(e);
                      setVendorData({
                        ...vendorData,
                        accountNumber: e.target.value
                      });
                    }}
                    error={Boolean(errors.accountNumber)}
                    helperText={touched.accountNumber && errors.accountNumber}
                  />
                </Grid>
              </Grid>
              <Grid container item xs={12} md={6}>
                <Grid item xs={12}>
                  <Tooltip
                    title={
                      'Nemzetközi bankszámlaszám, amely minden esetben az országod azonosító ISO kódjából és a számlaszámodból tevődik össze. Könnyen ellenőrizheted a netbankban, banki papírjaidon, vagy bankod ügyfélszolgálatánál'
                    }>
                    <Typography color='primary' variant='body1'>
                      IBAN
                      <InfoIcon
                        style={{
                          fontSize: '0.9rem',
                          opacity: '0.5',
                          marginLeft: '0.5rem'
                        }}
                      />
                    </Typography>
                  </Tooltip>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    disabled={loading || disabled}
                    variant='outlined'
                    margin='normal'
                    fullWidth
                    required
                    id='iban'
                    name='iban'
                    value={values.iban}
                    onChange={(e) => {
                      handleChange(e);
                      setVendorData({
                        ...vendorData,
                        iban: e.target.value
                      });
                    }}
                    error={Boolean(errors.iban)}
                    helperText={touched.iban && errors.iban}
                  />
                </Grid>
              </Grid>
              <Grid container item xs={12}>
                <Grid container style={{ margin: '1rem 0' }}>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    style={{
                      display: 'flex',
                      justifyContent: 'center'
                    }}>
                    <Button
                      disabled={loading}
                      variant='contained'
                      color='primary'
                      onClick={setOpen}
                      className={styles.controlButton}>
                      Mégse
                    </Button>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    style={{
                      display: 'flex',
                      justifyContent: 'center'
                    }}>
                    <Button
                      disabled={loading || disabled}
                      variant='contained'
                      color='primary'
                      type='submit'
                      className={styles.controlButton}>
                      {loading ? (
                        <CircularProgress
                          size={24}
                          style={{
                            color: 'var(--primary-text-primary)'
                          }}
                        />
                      ) : (
                        'Mentés'
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </SettingsCard>
  );
};

export default InvoiceVendorForm;
