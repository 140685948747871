import ChangeContactNameCard from '../ChangeContactNameCard/ChangeContactNameCard';
import ChangeContactPhoneCard from '../ChangeContactPhoneCard/ChangeContactPhoneCard';
import ChangeEmailAddressCard from '../ChangeEmailAddressCard/ChangeEmailAddressCard';
import ChangeContactAddressCard from '../ChangeContactAddressCard/ChangeContactAddressCard';
import ChangePasswordCard from '../ChangePasswordCard/ChangePasswordCard';
import styles from './ContactCardsContainer.module.css';

const ContactCardsContainer = ({ user, inProgress, setInProgress, showDialog }) => {
  return (
    <div className={styles.cardContainer}>
      <div className={styles.cards}>
        <ChangeContactNameCard
          user={user}
          inProgress={inProgress}
          setInProgress={setInProgress}
          showDialog={showDialog}
        />
      </div>
      <div className={styles.cards}>
        <ChangeContactPhoneCard
          user={user}
          inProgress={inProgress}
          setInProgress={setInProgress}
          showDialog={showDialog}
        />
      </div>
      <div className={styles.cards}>
        <ChangeEmailAddressCard
          user={user}
          inProgress={inProgress}
          setInProgress={setInProgress}
          showDialog={showDialog}
        />
      </div>
      <div className={styles.cards}>
        <ChangePasswordCard user={user} inProgress={inProgress} setInProgress={setInProgress} showDialog={showDialog} />
      </div>
      <div className={styles.addressCard}>
        <ChangeContactAddressCard
          user={user}
          inProgress={inProgress}
          setInProgress={setInProgress}
          showDialog={showDialog}
        />
      </div>
    </div>
  );
};

export default ContactCardsContainer;
