import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Edit from '@material-ui/icons/Edit';
import Button from '@material-ui/core/Button';
import { useEffect, useState, useRef } from 'react';
import SettingsCard from 'components/PhotoAdminComponents/Settings/Card/SettingsCard';
import ActionButton from 'components/common/ActionButton/ActionButton';
import { Form, Formik } from 'formik';
import { CircularProgress } from '@material-ui/core';
import vatNumberChangeValidationSchema from 'validation/UserSettings/VatNumberChangeValidationSchema';
import { useUserApi } from 'hooks/useUserApi';
import styles from './ChangeVatNumberCard.module.css';

const ChangeVatNumberCard = ({ user, setInProgress, inProgress, showDialog }) => {
  const [isEditing, setIsEditing] = useState(false);
  const toggleIsEditing = () => setIsEditing(!isEditing);
  const formikRef = useRef(null);
  const { getCurrentUser, putUserVatNumber } = useUserApi();

  const [vatNumberData, setVatNumberData] = useState({ vatNumber: '' });

  const putVatNumberWithDialog = async (body) => {
    setInProgress('vatNumber');
    const status = await putUserVatNumber(body);
    getCurrentUser();
    showDialog(status);
    setInProgress(null);
  };

  useEffect(() => {
    if (user && formikRef.current) {
      formikRef.current.values.vatNumber = vatNumberData?.vatNumber;
    }
  }, [user, formikRef]);

  useEffect(() => {
    if (isEditing) {
      setVatNumberData({ vatNumber: '' });
    } else {
      setVatNumberData({
        vatNumber: user?.billingInfo?.vatNumber
      });
    }
  }, [user, isEditing]);

  return (
    <SettingsCard
      title={'Adószám'}
      actionButton={<ActionButton icon={<Edit color='primary' />} clickHandler={toggleIsEditing} />}>
      <Formik
        innerRef={formikRef}
        enableReinitialize
        initialValues={{
          vatNumber: ''
        }}
        validationSchema={vatNumberChangeValidationSchema}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          setSubmitting(true);

          await putVatNumberWithDialog({
            ...user,
            billingInfo: {
              ...user?.billingInfo,
              vatNumber: values.vatNumber
            }
          });
          setIsEditing(false);
          setSubmitting(false);
        }}>
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
          <Form className={styles.form}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  disabled={!isEditing}
                  variant='outlined'
                  fullWidth
                  required
                  label={'Adószám'}
                  id='vatNumber'
                  name='vatNumber'
                  value={isEditing ? values.vatNumber : vatNumberData?.vatNumber}
                  onChange={handleChange}
                  error={touched.vatNumber && Boolean(errors.vatNumber)}
                  helperText={touched.vatNumber && errors.vatNumber}
                />
              </Grid>
            </Grid>
            <Grid container className={styles.gridContainer}>
              <Grid item xs={12}>
                <Button
                  disabled={!isEditing}
                  type='submit'
                  fullWidth
                  onClick={handleSubmit}
                  variant='outlined'
                  className={styles.saveButton}>
                  {inProgress === 'vatNumber' ? (
                    <>
                      Folyamatban
                      <CircularProgress style={{ marginLeft: '0.5rem' }} size={24} />
                    </>
                  ) : (
                    'Adószám frissítése'
                  )}
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </SettingsCard>
  );
};

export default ChangeVatNumberCard;
