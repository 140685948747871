/** @format */

import { useGetQueryString } from 'hooks/useGetQueryString';
import React from 'react';
import { useState } from 'react';
import QueryOptions from './config/QueryOptions';
import AdminQueryOptions from './config/AdminQueryOptions';
import QueryParam from './QueryParam/QueryParam';
import AddIcon from '@mui/icons-material/Add';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import styles from './ChartQueryBuilder.module.css';
import { Button, Tooltip, Typography } from '@material-ui/core';
import { useEffect } from 'react';
import RemoveIcon from '@mui/icons-material/Remove';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Info } from '@material-ui/icons';

const ChartQueryBuilder = ({ children, siteType, chartName, accountingData, disabled, refetch }) => {
  const [search, setSearch] = useState(localStorage.getItem(chartName));

  const resetQueryString = () => {
    localStorage.setItem(chartName, '');
    setSearch(localStorage.getItem(chartName));
    refetch();
    setOptions(
      getInitialOptions({
        options: siteType === 'admin' ? AdminQueryOptions : QueryOptions
      })
    );
  };
  const { getQueryString } = useGetQueryString();

  //TODO check value validity
  const getInitialOptions = ({ options }) => {
    return options?.map((o) => {
      return { ...o, used: false, value: '' };
    });
  };

  const [options, setOptions] = useState(
    getInitialOptions({
      options: siteType === 'admin' ? AdminQueryOptions : QueryOptions
    })
  );
  const [currentQueryString, setCurrentQueryString] = useState(localStorage.getItem(chartName));
  const [isAddDisabled, setIsAddDisabled] = useState(false);
  const [isAddOptionOpen, setIsAddOptionOpen] = useState(false);

  const toggleIsAddOptionOpen = () => setIsAddOptionOpen(!isAddOptionOpen);

  useEffect(() => {
    setCurrentQueryString(getQueryString(options));
  }, [options]);

  useEffect(() => {
    if (search !== currentQueryString) {
      localStorage.setItem(`${chartName}`, currentQueryString);
    }
    refetch();
    setSearch(localStorage.getItem(chartName));
  }, [currentQueryString]);

  const setOption = (option) => {
    setOptions(
      options.map((currentOption) => {
        if (currentOption.name === option.name) {
          return option;
        } else {
          return currentOption;
        }
      })
    );
  };

  const disableOption = (option) => {
    setOptions(
      options.map((currentOption) => {
        if (currentOption.name === option.name) {
          currentOption.used = false;
          currentOption.value = '';
        }
        return currentOption;
      })
    );
  };
  const enableOption = (name) => {
    setOptions(
      options.map((currentOption) => {
        if (currentOption.name === name) {
          currentOption.used = true;
        }
        return currentOption;
      })
    );
    setIsAddOptionOpen(false);
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.groupContainer}>
        <div
          className={styles.searchContainer}
          style={{ backgroundColor: siteType === 'group' ? 'transparent' : 'var(--primary-background-secondary)' }}>
          <div className={styles.titleContainer}>
            <Typography
              component='div'
              variant='h2'
              style={{
                color: siteType === 'group' ? 'var(--primary-background-secondary)' : 'var(--primary-contrast-text)',
                fontFamily: `var(--font-main), Sans-serif, Arial`,
                fontSize: '2.5rem'
              }}>
              Keresés
              <Tooltip title={'Alapértelmezetten az utolsó adatot tartalmazó hét információit jelenítjük meg!'}>
                <Info style={{ fontSize: '20px', marginBottom: '1rem' }}></Info>
              </Tooltip>
            </Typography>
          </div>
          <div className={styles.selectedContainer}>
            {options.map(
              (option) =>
                option.used &&
                option.type !== 'pagination' && (
                  <QueryParam
                    key={`${option.name}_queryparam`}
                    option={{ ...option }}
                    setOption={setOption}
                    disableOption={() => disableOption(option)}
                    setIsAddDisabled={setIsAddDisabled}
                    accountingData={accountingData}
                  />
                )
            )}
          </div>
          <div className={styles.addContainer}>
            <Tooltip title={'Keresési szűrő hozzáadása'}>
              <Button
                className={styles.addButton}
                variant='outlined'
                style={{
                  backgroundColor: isAddOptionOpen ? 'var(--primary-main)' : 'var(--primary-background-content)',
                  color: isAddOptionOpen ? 'var(--primary-background-content)' : 'var(--primary-main)'
                }}
                onClick={toggleIsAddOptionOpen}
                disabled={isAddDisabled || disabled}>
                {isAddOptionOpen ? <RemoveIcon /> : <AddIcon />}
                Szűrő hozzáadása
              </Button>
            </Tooltip>
            <Tooltip title={'Keresés alaphelyzetbe állítása'}>
              <Button
                className={styles.deleteButton}
                variant='outlined'
                onClick={() => {
                  resetQueryString();
                }}>
                <RestartAltIcon style={{ fontSize: '1.5rem' }} />
                Szűrők törlése
              </Button>
            </Tooltip>
            <Tooltip title={'Tételek frissítése'}>
              <Button variant='outlined' className={styles.refreshButton} onClick={() => refetch()}>
                <RefreshIcon style={{ fontSize: '1.5rem' }} />
                Adatok frissítése
              </Button>
            </Tooltip>
            {isAddOptionOpen && (
              <ul className={styles.optionsContainer}>
                {options.map(
                  (option, idx) =>
                    !option.used &&
                    option.type !== 'pagination' && (
                      <li className={styles.option} key={idx} onClick={(e) => enableOption(option.name)}>
                        {option.displayName}
                      </li>
                    )
                )}
              </ul>
            )}
          </div>
        </div>
        <div className={styles.actionContainer}>
          <div>
            {
              // Placeholder.
            }
          </div>
        </div>
        {children}
      </div>
    </div>
  );
};

export default ChartQueryBuilder;
