/** @format */

import {
  Card,
  CardActionArea,
  Typography,
  Zoom,
  Paper,
  DialogContent,
  DialogContentText,
  Button,
  Dialog,
  Checkbox,
  FormControlLabel,
  Slide
} from '@material-ui/core';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import LoadIndicator from 'components/common/LoadIndicator/LoadIndicator';
import React, { forwardRef, useEffect, useRef } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './AddShopCard.module.css';
import { useDocumentApi } from 'pages/PhotoAdmin/AdminMenu/Documents/hooks/useDocumentApi';
import { useSelector } from 'react-redux';

export const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const AddShopCard = ({ user, createShop, shopLoading }) => {
  const navigate = useNavigate();

  const userId = useSelector((state) => state.nonPersistedReducers?.userSliceReducer?.currentUser?.id);

  const [open, setOpen] = useState(false);
  const [isDemo, setIsDemo] = useState(false);
  const [isAccepted, setIsAccepted] = useState();
  const { getDocuments, documents, downloadDocument } = useDocumentApi();

  const handleShopCreate = () => {
    const body = {
      name: `${user?.username}_shop`,
      aboutMeDescription: 'About me!',
      footerDescription: 'Footer me!',
      owner: { username: user?.username },
      isDemo: isDemo
    };
    createShop({ body: body });
  };

  const handleOpen = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (userId) {
      getDocuments();
    }
  }, [userId]);

  const buttonRef = useRef(null);

  return (
    <>
      <a ref={buttonRef} />
      <Zoom in={true} timeout={1000}>
        <Card className={styles.cardStyle} component={Paper}>
          <CardActionArea
            className={styles.cardActionArea}
            onClick={
              !user?.billingInfo?.bankAccountNumber ||
              !user?.billingInfo?.companyName ||
              !user?.billingInfo?.vatNumber ||
              !user?.billingInfo?.billingAddress?.country ||
              !user?.billingInfo?.billingAddress?.city
                ? () => {
                    navigate('/settings/profile');
                  }
                : handleOpen
            }
            disabled={shopLoading}>
            {
              //TODO Real billing first and lastname will be needed instead of companyName
              !user?.billingInfo?.companyName ||
              !user?.billingInfo?.vatNumber ||
              !user?.billingInfo?.bankAccountNumber ||
              !user?.billingInfo?.billingAddress?.country ||
              !user?.billingInfo?.billingAddress?.city ? (
                <>
                  <ManageAccountsIcon
                    style={{
                      color: 'var(--primary-background-secondary)',
                      fontSize: '5rem',
                      margin: '-6rem  0 -2rem 0'
                    }}
                  />
                  <Typography className={styles.cardTitle}>
                    Boltot csak azután tudsz létrehozni hogy megadtad a számlázási adataid! Ezt a "Profil beállítások"
                    oldalon teheted meg.
                  </Typography>
                </>
              ) : shopLoading ? (
                <LoadIndicator />
              ) : (
                <>
                  <AddCircleIcon
                    style={{
                      color: 'var(--primary-background-secondary)',
                      fontSize: '5rem'
                    }}
                  />
                  <Typography className={styles.cardTitle}>Bolt létrehozása</Typography>
                </>
              )
            }
          </CardActionArea>
        </Card>
      </Zoom>

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClick={(e) => e.stopPropagation()}
        onClose={() => {}}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'>
        <div className={styles.dialogContainer} onClick={(e) => e.stopPropagation()}>
          <DialogContent className={styles.dialogContent}>
            <DialogContentText>
              <h1 className={styles.title1}>Biztosan boltot szeretnél létrehozni?</h1>
              <h1 className={styles.title2}>
                Felhívjuk figyelmed hogy saját bolt létrehozásához el kell fogadnod az alábbi dokumentumokat, melyeket
                az adataiddal kitöltve továbbítani fogunk az általad megadott email címre!
              </h1>
            </DialogContentText>
            <FormControlLabel
              control={<Checkbox color='primary' required onChange={(e) => setIsAccepted(e.target.checked)} />}
              label={
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    color: isAccepted ? 'inherit' : 'rgba(255, 0, 0, 0.88)'
                  }}>
                  <p style={{ margin: 0 }}>
                    Az alábbi
                    <br />
                    {documents &&
                      documents?.map((d) => (
                        <>
                          <a
                            href='/'
                            className={styles.downloadLink}
                            key={`downloadable_document_${d?.id}`}
                            target={'_blank'}
                            rel='noreferrer'
                            onClick={(event) => {
                              event.preventDefault();
                              downloadDocument(d?.id, buttonRef, d?.name);
                            }}>
                            {d?.name}
                          </a>{' '}
                          <br />{' '}
                        </>
                      ))}{' '}
                    dokumentumokban foglaltakat elfogadom!
                  </p>
                </div>
              }
            />
            <DialogContentText>
              <h1 className={styles.title2}>
                <span style={{ color: 'rgba(255, 0, 0, 0.88)' }}>FIGYELEM!</span> <br />
                Lehetőséged van Demo bolt létrehozására is, ahol tesztelési céllal létrehozhatsz galériákat, képeket
                tölthetsz fel és megismerheted az oldal működését, megtapasztalhatod a digitális iskolai fotózás
                nyújtotta lehetőségeket! Demo bolt esetén maximum 6 galériád lehet beleértve az 'Iskola', az 'Osztály'
                és a 'Csoportképek' galériákat, egy galérián belül pedig maximum 6db fotót tölthetsz fel.
                <br /> Demo boltod bármikor éles üzembe állíthatod!
              </h1>
            </DialogContentText>
            <FormControlLabel
              style={{ marginBottom: '1rem' }}
              control={<Checkbox color='primary' required onChange={(e) => setIsDemo(!isDemo)} />}
              label={
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    color: 'inherit'
                  }}>
                  <p style={{ margin: 0 }}>Demo boltot szeretnék</p>
                </div>
              }
            />
            <div className={styles.buttonsContainer}>
              <Button
                disabled={!isAccepted}
                variant='contained'
                color='primary'
                onClick={() => {
                  handleShopCreate();
                  handleOpen();
                }}>
                Bolt létrehozása
              </Button>
              <Button variant='contained' onClick={handleOpen}>
                Bezárás
              </Button>
            </div>
          </DialogContent>
        </div>
      </Dialog>
    </>
  );
};

export default AddShopCard;
