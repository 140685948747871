import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';

export const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName='.Mui-focusVisible' disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    transform: 'translate(-7px, -7px)',
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translate(9px, -7px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5
      }
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600]
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3
    }
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500
    })
  }
}));

export const useStyles = makeStyles((theme) => ({
  media: {
    width: '100%',
    height: '200px',
    margin: '0 auto'
  },
  cardActionArea: {
    borderRadius: '5px'
  },
  cardContent: {
    width: '100%',
    height: '100%',

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between'
  },

  itemData: {
    transition: 'opacity 1s linear',
    opacity: 0
  },

  cardStyle: {
    fontSize: '1rem',
    position: 'relative',
    width: '100%',
    transition: 'all 1s',
    boxShadow: '0 4px 15px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
    zIndex: '1',
    flex: '0 0 calc(100% - 20px)',

    margin: '20px 10px',
    [theme.breakpoints.up('sm')]: {
      flex: '0 0 calc(50% - 20px)'
    },
    [theme.breakpoints.up('md')]: {
      flex: '0 0 calc(33% - 20px)'
    },
    [theme.breakpoints.up('lg')]: {
      flex: '0 0 calc(25% - 20px)'
    },
    [theme.breakpoints.up('xl')]: {
      flex: '0 0 calc(16.66% - 20px)'
    }
  },
  cardTitle: {
    fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
    fontWeight: 'bold',
    letterSpacing: '.1rem',
    lineHeight: '1.54',
    textAlign: 'center',
    display: 'block',
    color: theme.palette.primary.backgroundSecondary,
    height: '1.8rem',
    width: '100%',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  },

  countwrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    bottom: '2rem',
    flexWrap: 'nowrap',
    justifyContent: 'space-between'
  },
  switch: {
    position: 'absolute',
    zIndex: '2',
    margin: '0.5rem'
  },
  delete: {
    position: 'absolute',
    top: '0.9rem',
    right: '1.2rem',
    zIndex: '2',
    cursor: 'pointer',
    background: 'white',
    borderRadius: '11px',
    transition: '250ms ease-in',
    '&:hover ': {
      background: 'red',
      transition: '250ms ease-in'
    }
  },
  customButton: {
    backgroundColor: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    textTransform: 'uppercase',
    fontSize: '.7rem',
    letterSpacing: '.1rem',
    lineHeight: '1.54',
    width: '100%',
    transition: '250ms ease-in',
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.contrastText,
      color: theme.palette.primary.backgroundSecondary
    }
  },
  customDialogTitle: {
    fontSize: '.65rem',
    letterSpacing: '.1rem',
    lineHeight: '1.54'
  },
  dialogActions: {
    margin: '0 auto',
    maxWidth: '100%',
    padding: '0 5rem 1rem 5rem'
  },
  checkbox: {
    padding: '0 1rem 0 1rem'
  },
  validThru: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    rowGap: '0.5rem'
  },
  validThruInner: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: '0.2rem'
  }
}));

export const SelectWrapper = styled.div`
  position: relative;
  margin: 0 auto;
  margin-bottom: -6.25rem;
  z-index: 1000;
  display: flex;
  justify-content: center;
  clip-path: polygon(0% 0%, 100% 0%, 50% 50%);
  overflow: hidden;
  height: 100px;
  width: 100px;
  background: #fefefe;
`;

export const GroupSelect = styled.input`
  margin-top: 0.7rem;
  width: 1rem;
  height: 1rem;
  cursor: pointer;
`;
export const SearchKeyWrapper = styled.div`
  display: flex;
  align-items: center;
`;
