import React from 'react';
import { DatePickerField } from 'components/common/DatePickerField/DatePickerField';
import { useState } from 'react';
import { useEffect } from 'react';
import { useGetQueryString } from 'hooks/useGetQueryString';
import styles from './ReportingDateEditor.module.css';

const ReportingDateEditor = ({ option, setOption }) => {
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());

  const { getDateString } = useGetQueryString();
  const getFromDate = (option) => {
    if (option.value === '') return new Date(new Date().setUTCHours(0, 0, 0, 0));
    return new Date(option.value?.split('&')[0].split('=')[1]);
  };
  const getToDate = (option) => {
    if (option.value === '') return new Date(new Date().setUTCHours(23, 59, 59, 999));
    return new Date(option.value?.split('&')[1].split('=')[1]);
  };
  useEffect(() => {
    setFromDate(getFromDate(option));
    setToDate(getToDate(option));
    setOption({
      ...option,
      value: `fromDateTime=${getDateString(getFromDate(option))}&toDateTime=${getDateString(getToDate(option))}`
    });
  }, []);

  return (
    <div className={styles.mainContainer}>
      <DatePickerField
        name={`${option.name}_from`}
        openTo='month'
        setDate={(dateValue) => {
          let newDateValue = new Date(dateValue.setUTCHours(0, 0, 0, 0));
          setFromDate(newDateValue);
          setOption({
            ...option,
            value: `fromDateTime=${getDateString(newDateValue)}&toDateTime=${getDateString(toDate)}`
          });
        }}
        views={['year', 'month', 'day']}
        value={fromDate}
        fullWidth
      />
      <DatePickerField
        name={`${option.name}_to`}
        openTo='month'
        minDate={fromDate}
        setDate={(dateValue) => {
          let newDateValue = new Date(dateValue.setUTCHours(23, 59, 59, 999));
          newDateValue.setUTCDate(newDateValue.getUTCDate() + 1);
          setToDate(newDateValue);
          setOption({
            ...option,
            value: `fromDateTime=${getDateString(fromDate)}&toDateTime=${getDateString(newDateValue)}`
          });
        }}
        views={['year', 'month', 'day']}
        value={new Date(toDate).setUTCDate(toDate.getUTCDate() - 1)}
        fullWidth
      />
    </div>
  );
};

export default ReportingDateEditor;
