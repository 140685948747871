/** @format */

import React, { forwardRef } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { Dialog, Slide } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import styles from './ConfirmDialog.module.css';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const ConfirmDialog = ({
  open,
  onOk,
  onClose,
  title,
  text,
  text2,
  disabled,
  demo,
  functionButtonText,
  functionButtonColor,
  closeButtonText,
  multipleButtons
}) => {
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClick={(e) => e.stopPropagation()}
      onClose={onClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'>
      <div className={styles.contentContainer} onClick={(e) => e.stopPropagation()}>
        <DialogContent>
          <DialogContentText component={'div'}>
            <h1 className={styles.title1}>{title}</h1>
            <h1 className={styles.title2}>{text}</h1>
            <h1 className={styles.title2}>{text2}</h1>
            {demo && <p className={styles.demoShop}>Demo bolt esetén a nyomtatóra küldés nem lehetséges!</p>}
          </DialogContentText>
          <div className={styles.buttonsContainer}>
            {multipleButtons && (
              <Button style={{ color: functionButtonColor }} onClick={onOk} disabled={demo}>
                {functionButtonText}
              </Button>
            )}
            <Button disabled={disabled} onClick={onClose}>
              {closeButtonText}
            </Button>
          </div>
        </DialogContent>
      </div>
    </Dialog>
  );
};

export default ConfirmDialog;
