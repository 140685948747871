import { Zoom } from '@material-ui/core';
import { MdImage, MdAdd, MdEmail } from 'react-icons/md';
import React from 'react';
import styles from './ProductTypes.module.css';
import { Grow } from '@mui/material';

const ProductTypes = ({ modifiers, setSelectedEdit, setIsEdit, setDatagridData }) => {
  const createDatagridData = (data) => {
    setDatagridData([]);
    const datas = modifiers
      .filter((el) => {
        const match = el.modifierUriSegment.match(/mod#id:(\d+)/);
        const segmentId = match ? parseInt(match[1], 10) : null;
        return el.priority === 2 && data.compatibleWith.includes(segmentId);
      })
      .map((el) => ({
        id: el.id,
        name: el.name,
        description: el.description,
        category: el.category
      }));
    setDatagridData(datas);
  };

  return (
    <Grow in={true}>
      <div className={styles.left}>
        <div className={styles.title}>Termék típusok szerkesztése</div>
        <div className={styles.productTypeContainer}>
          {modifiers?.map((el) => {
            if (el.priority === 1) {
              return (
                <div
                  key={el.id}
                  className={styles.card}
                  onClick={() => {
                    setSelectedEdit(el);
                    createDatagridData(el);
                    setIsEdit(true);
                  }}>
                  <div>
                    {el.deliveryTypeDTO === 'POST' ? (
                      <MdImage size={'75px'} color='#383838' />
                    ) : (
                      <MdEmail size={'75px'} color='#383838' />
                    )}
                  </div>
                  <div>{el.name}</div>
                </div>
              );
            }
          })}
          <div className={styles.card}>
            <MdAdd
              size={'100px'}
              onClick={() => {
                setSelectedEdit(null);
                setIsEdit(true);
              }}
            />
          </div>
        </div>
      </div>
    </Grow>
  );
};

export default ProductTypes;
