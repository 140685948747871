import { useUploaderBl } from 'components/PhotoAdminComponents/Group/Uploader/hooks/useUploaderBl';
import React from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import CircularProgress from '@mui/material/CircularProgress';
import styles from './ResultItem.module.css';
import { Button } from '@material-ui/core';
import { useSelector } from 'react-redux';

const ResultItem = ({ name, success, promiseConfig }) => {
  const { handleRetry } = useUploaderBl();
  const uploadLoading = useSelector((state) => state.nonPersistedReducers.uploadSliceReducer.loading);

  return (
    <div className={styles.mainContainer}>
      <h3 className={styles.name}>{name}</h3>
      {success === 'loading' ? (
        <CircularProgress size={'1.25rem'} color='primary' />
      ) : (
        <>
          {success === 'true' ? (
            <CheckCircleIcon style={{ height: '1.25rem', width: 'auto' }} color='success' />
          ) : (
            <div className={styles.buttonContainer}>
              <Button outlined disabled={uploadLoading} onClick={() => handleRetry({ promiseConfig: promiseConfig })}>
                Újra
              </Button>
              <CancelIcon style={{ height: '1.25rem', width: 'auto' }} color='error' />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ResultItem;
