import { Button, Checkbox, CircularProgress, Tooltip } from '@material-ui/core';
import React, { useState } from 'react';
import styles from './CardRegister.module.css';
import chip from '../../../assets/images/card_chip.svg';
import x from '../../../assets/images/x.svg';
import tick from '../../../assets/images/tick.svg';
import simpleLogo from '../../../assets/images/simpleNew.png';
import { useSelector } from 'react-redux';
import Accordion from '../Accordion/Accordion';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import queries from '../../../api/queries/PaymentQueries';
import { useToasterLogic } from 'utils/useToasterLogic';
import ConfirmDialog from 'components/common/ConfirmDialog/ConfirmDialog';

const CardRegister = () => {
  const user = useSelector((state) => state.nonPersistedReducers?.userSliceReducer?.currentUser);
  const shopId = useSelector((state) => state.nonPersistedReducers?.shopSliceReducer?.shopId);
  const [accepted, setAccepted] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [read, setRead] = useState(false);
  const { successToast } = useToasterLogic();
  const queryClient = useQueryClient();

  const addCard = useMutation({
    mutationFn: () => queries.registerCard({ shopId: shopId }),
    onSuccess: (data) => {
      successToast('Sikeres kártya regisztráció!');
      if (data.data.paymentRedirectURL) {
        window.location.href = data.data.paymentRedirectURL;
      }
      queryClient.invalidateQueries(['cardRegistration']);
    }
  });
  const deleteCard = useMutation({
    mutationFn: () => queries.deleteRegistration({ shopId: user.ownedShops[0].id }),
    onSuccess: () => {
      successToast('Sikeres kártya törlés!');
      setOpenDialog(false);
      queryClient.invalidateQueries(['cardRegistration']);
    }
  });
  const fetchCardRegister = useQuery({
    queryKey: ['cardRegistration'],
    queryFn: () => queries.getRegistration({ shopId: user.ownedShops[0].id }),
    onSuccess: (data) => {
      console.log(data);
    },
    refetchOnWindowFocus: false,
    enabled: user?.ownedShops?.length > 0
  });

  const registerCard = () => {
    addCard.mutate();
  };
  const removeCard = () => {
    deleteCard.mutate();
  };

  return (
    <div className={styles.cardDataContainer}>
      <div className={styles.topContainer}>
        <div className={styles.cardWrapper}>
          <div
            className={styles.creditCard}
            style={{
              backgroundColor:
                fetchCardRegister?.data?.data?.isCardRegistering === undefined
                  ? 'var(--primary-error)'
                  : fetchCardRegister?.data?.data?.tokens.length > 0
                  ? 'var(--primary-success)'
                  : 'var(--primary-background-secondary)'
            }}>
            <img className={styles.chip} src={chip} alt='card_chip' width={90} />
            {fetchCardRegister?.data?.data?.tokens.length > 0 ||
            (fetchCardRegister?.data?.data?.isCardRegistering === undefined && !addCard.isPending) ? (
              <img
                src={fetchCardRegister?.data?.data?.isCardRegistering === true ? tick : x}
                alt='tick_sign'
                width={'100px'}
              />
            ) : (
              <div className={styles.progressYellow}>
                <CircularProgress size={30} />
                <div>Kártya regisztrálás folyamatban</div>
              </div>
            )}
          </div>
        </div>
        <div className={styles.acceptContainer}>
          {fetchCardRegister?.data?.data?.tokens.length > 0 ? (
            <div className={styles.buttonWrapperDelete}>
              <Button
                width={200}
                className={styles.buttonDark}
                disabled={deleteCard.isPending}
                onClick={() => {
                  setOpenDialog(true);
                }}>
                {deleteCard.isPending ? <CircularProgress size={25} /> : 'Kártyaregisztráció törlése'}
              </Button>
              <img
                style={{ cursor: 'pointer' }}
                src={simpleLogo}
                alt='SimplePay_logo'
                width={110}
                height={40}
                onClick={() => {
                  window.open('https://simplepartner.hu/PaymentService/Fizetesi_tajekoztato.pdf');
                }}
              />
            </div>
          ) : (
            <div className={styles.buttonWrapper}>
              {user?.ownedShops?.length > 0 && !addCard.isPending && user?.billingInfo ? (
                <Button
                  width={200}
                  className={styles.buttonDark}
                  disabled={!read}
                  onClick={() => {
                    if (read) {
                      registerCard();
                      setAccepted(true);
                    } else {
                      setAccepted(false);
                    }
                  }}>
                  {addCard.isPending ? <CircularProgress size={24} /> : 'Kártya regisztráció'}
                </Button>
              ) : (
                <Tooltip title='Előbb meg kell adnod a számlázási adataidat és boltot létrehoznod'>
                  <span>
                    <Button width={200} className={styles.buttonDark} disabled={true}>
                      {addCard.isPending ? <CircularProgress size={24} /> : 'Kártya regisztráció'}
                    </Button>
                  </span>
                </Tooltip>
              )}
              <img
                style={{ cursor: 'pointer' }}
                src={simpleLogo}
                alt='SimplePay_logo'
                width={110}
                height={40}
                onClick={() => {
                  window.open('https://simplepartner.hu/PaymentService/Fizetesi_tajekoztato.pdf');
                }}
              />
            </div>
          )}
          {fetchCardRegister?.data?.data?.isCardRegistering === undefined ? (
            <div className={!accepted ? styles.checkboxRed : styles.checkbox}>
              <Checkbox
                value={read}
                style={{ color: 'var(--primary-background-primary)' }}
                onChange={() => {
                  setRead(!read);
                }}></Checkbox>
              <span style={{ width: '100%', lineHeight: '1.5rem' }}>
                {`Tudomásul veszem, hogy a(z) Datatronic Kft. (1163 Budapest, Lillafüred utca 10/b.)
                    adatkezelő által, a datatronic.cloud felhasználói adatbázisában tárolt alábbi
                    személyes adataim átadásra kerülnek az OTP Mobil Kft., mint adatfeldolgozó részére.

                    Az adatkezelő által továbbított adatok köre az alábbi: email cím. Az adatfeldolgozó
                    által végzett adatfeldolgozási tevékenység jellege és célja a SimplePay Adatkezelési
                    tájékoztatóban, az alábbi linken tekinthető meg:`}
                <a href='http://simplepay.hu/vasarlo-aff' target='blank'>
                  <p style={{ textDecoration: 'underline', margin: 0, color: 'blue' }}>
                    http://simplepay.hu/vasarlo-aff
                  </p>
                </a>
              </span>{' '}
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>

      <div className={styles.accordion_container}>
        <Accordion title={'További információk a kártya regisztrációról és az ismétlődő fizetésről'}>
          <div className={styles.details}>
            {' '}
            {
              'Az ismétlődő bankkártyás fizetés egy, a SimplePay által biztosított bankkártya elfogadáshoz tartozó funkció, mely azt jelenti, hogy az Ügyfél által a regisztrációs tranzakció során megadott bankkártyaadatokkal a jövőben újabb fizetéseket lehet kezdeményezni a bankkártyaadatok újbóli megadása nélkül. Ismétlődő bankkártyás fizetés esetén a megrendelés adatai megadásra kerülnek az OTP Mobil Kft. (1138 Budapest, Váci út 135-139. B. ép. 5. em.)., mint harmadik fél részére. A bankkártyás fizetések az OTP Bank online fizetési rendszerén (Simple Pay) keresztül valósulnak meg. A fizetéshez szükséges bankkártya-adatokat Ön az OTP fizetési oldalán adja meg, így mi semmilyen adatkezelési műveletet nem végzünk az Ön bankkártya adataival. Amint Ön átlép az OTP SimplePay alkalmazásba, úgy az ott megadott, a bankkártyás fizetéshez szükséges személyes adatok tekintetében az OTP Mobil Kft. önálló adatkezelőnek minősül. A bankkártyás fizetés során mi adatfeldolgozást, adatgyűjtést, egyéb adatkezelési tevékenységet nem végzünk, e személyes adatokhoz nem férünk hozzá.'
            }
            <br />
            <br />
            {
              'A kártyaregisztráció során X db tokent hozunk létre, melyek N-ig érvényesek. Összesen X db vásárlást tesznek lehetővé, külön-külön maximum Y értékben.'
            }
          </div>
        </Accordion>
      </div>
      <ConfirmDialog
        open={openDialog}
        title={'Biztosan törölni akarja a kártya adatokat?'}
        functionButtonText={'Kártya törlés'}
        functionButtonColor={'green'}
        closeButtonText={'Mégse'}
        onOk={() => {
          removeCard();
        }}
        onClose={() => {
          setOpenDialog(false);
        }}
        multipleButtons
      />
    </div>
  );
};

export default CardRegister;
