import { Button, MenuItem, TextField, Zoom } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { MdAdd, MdClose } from 'react-icons/md';
import styles from './ProductTypeEditor.module.css';
import { Formik } from 'formik';
import Divider from 'components/common/Divider/Divider';
import { DataGrid } from '@mui/x-data-grid';
import ProductTypeDialog from '../ProductTypeDialog/ProductTypeDialog';
import { Grow } from '@mui/material';

const ProductTypeEditor = ({ selectedEdit, setIsEdit, datagridData }) => {
  const [open, setOpen] = useState(false);
  const columns = [
    { field: 'id', headerName: 'ID', flex: 1 },
    {
      field: 'name',
      headerName: 'Név',
      flex: 2,
      editable: true
    },
    {
      field: 'description',
      headerName: 'Leírás',
      flex: 4,
      editable: true
    },
    {
      field: 'category',
      headerName: 'Címke',
      flex: 2,
      editable: true
    }
  ];

  const [formDataEdit, setFormDataEdit] = useState({
    name: '',
    description: '',
    category: '',
    deliveryTypeDTO: ''
  });

  useEffect(() => {
    if (selectedEdit !== null) {
      setFormDataEdit({
        name: selectedEdit.name,
        description: selectedEdit.description,
        category: selectedEdit.category,
        deliveryTypeDTO: selectedEdit.deliveryTypeDTO
      });
    }
  }, [selectedEdit]);

  return (
    <Grow in={true}>
      <div className={styles.editContainer}>
        <div className={styles.editHeader}>
          <div className={styles.title}>Termék kategória szerkesztése</div>
          <Button
            className={styles.buttonClose}
            onClick={() => {
              setIsEdit(false);
            }}>
            <MdClose size={'30px'} />
          </Button>
        </div>
        <div className={styles.editMainContainer}>
          <Formik
            enableReinitialize
            initialValues={formDataEdit}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              setSubmitting(true);
              setSubmitting(false);
              resetForm();
            }}>
            {({ values, handleChange, handleSubmit }) => (
              <form className={styles.leftEdit}>
                <TextField
                  variant='outlined'
                  label='Név'
                  size='small'
                  value={values.name}
                  onChange={(e) => {
                    handleChange(e);
                  }}></TextField>
                <TextField
                  variant='outlined'
                  label='Leírás'
                  size='small'
                  value={values.description}
                  onChange={(e) => {
                    handleChange(e);
                  }}></TextField>
                <TextField
                  select
                  variant='outlined'
                  label='Kategória címke'
                  size='small'
                  value={values.category}
                  onChange={(e) => {
                    handleChange(e);
                  }}>
                  <MenuItem value='Formátum'>Formátum</MenuItem>
                  <MenuItem value='Méret'>Méret</MenuItem>
                </TextField>
                <TextField
                  select
                  variant='outlined'
                  label='Előállítási mód'
                  size='small'
                  value={values.deliveryTypeDTO}
                  onChange={(e) => {
                    handleChange(e);
                  }}>
                  <MenuItem value='POST'>Nyomtatott</MenuItem>
                  <MenuItem value='EMAIL'>Digitális</MenuItem>
                </TextField>
                <Button onClick={handleSubmit} type='submit'>
                  {selectedEdit === null ? 'Kategória létrehozása' : 'Kategória szerkesztése'}
                </Button>
              </form>
            )}
          </Formik>
          <Divider />
          <div className={styles.rightEdit}>
            <Button
              className={styles.buttonAdd}
              onClick={() => {
                setOpen(true);
              }}>
              Új termék hozzáadása
              <MdAdd size={'25px'} />
            </Button>
            <DataGrid columns={columns} rows={datagridData} filterMode='client'></DataGrid>
          </div>
        </div>
        <ProductTypeDialog open={open} setOpen={setOpen} />
      </div>
    </Grow>
  );
};

export default ProductTypeEditor;
