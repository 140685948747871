import { Autocomplete, TextField } from '@mui/material';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import styles from './GroupNameEditor.module.css';

const GroupNameEditor = ({ option, setOption, accountingData }) => {
  const [selectedSchool, setSelectedSchool] = useState(null);
  const [schoolOptions, setSchoolOptions] = useState([]);

  useEffect(() => {
    const uniqueNames = Array.from(new Set(accountingData.map((entry) => entry.schoolName)));
    setSchoolOptions(uniqueNames);
    //setSchoolOptions(accountingData);
  }, [accountingData]);

  useEffect(() => {
    if (selectedSchool) {
      const newValue = `schoolName=${selectedSchool}`;
      if (decodeURI(option.value) !== decodeURI(newValue)) {
        setOption({ ...option, value: encodeURI(newValue) });
      }
    }
  }, [selectedSchool]);

  return (
    <div className={styles.mainContainer}>
      <Autocomplete
        disabled={!accountingData || accountingData?.length === 0}
        disablePortal
        disableClearable
        id='school-autocomplete'
        options={schoolOptions}
        getOptionLabel={(option) => option}
        sx={{ width: 300, fontFamily: 'var(--font-main)' }}
        value={selectedSchool}
        className={styles.autocomplete}
        onChange={(event, selectedSchool) => {
          setSelectedSchool(selectedSchool);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              style: { fontFamily: 'var(--font-main)' }
            }}
            label='Iskola'
          />
        )}
      />
    </div>
  );
};

export default GroupNameEditor;
