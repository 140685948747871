import { Typography, CircularProgress } from '@material-ui/core';
import styles from './ErrorPage.module.css';
import { useEffect, useState } from 'react';

const ErrorPage = (props) => {
  const { errorCode, errorText } = props;
  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    if (!!props?.user) {
      let timer = setTimeout(() => setNotFound(true), 2000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [props?.user]);
  return (
    <div className={styles.mainContainer}>
      {notFound ? (
        <>
          <Typography component='h1' variant='h1' className={styles.mainText}>
            {errorCode}
          </Typography>
          <Typography component='h2' variant='h2' className={styles.secondaryText}>
            {errorText}
          </Typography>
        </>
      ) : (
        <div className={styles.overlay}>
          <CircularProgress size={100} />
        </div>
      )}
    </div>
  );
};

export default ErrorPage;
