/** @format */

import { useEffect, useRef } from 'react';
import { Checkbox, Grid, InputAdornment, TextField, Tooltip, Typography, FormControlLabel } from '@material-ui/core';
import { Form, Formik } from 'formik';
import { errorToast, successToast } from 'components/common/Toast/Toast';
import styles from './ContactForm.module.css';
import AuthService from 'services/AuthService';
import InfoIcon from '@mui/icons-material/Info';
import PostalCodeFields from '../PostalCodeFields/PostalCodeFields';
import contactValidationSchema from 'validation/Registration/ContactSchema';

const ContactForm = ({
  contactData,
  setContactData,
  handleNext,
  setSubmitted,
  wantShop,
  setWantShop,
  setOpen,
  newsAccept,
  setNewsAccept,
  subscribeNewsletter
}) => {
  const formikRef = useRef(null);

  const handleRedirect = () => {
    AuthService.doLogin();
  };

  useEffect(() => {
    setContactData({ ...contactData });
  }, []);

  useEffect(() => {
    formikRef.current.values.username = contactData.username;
    formikRef.current.values.password = contactData.password;
    formikRef.current.values.passwordConfirm = contactData.passwordConfirm;
    formikRef.current.values.emailConfirm = contactData.emailConfirm;
    formikRef.current.values.contactFirstName = contactData.contactFirstName;
    formikRef.current.values.contactLastName = contactData.contactLastName;
    formikRef.current.values.contactEmail = contactData.contactEmail;
    formikRef.current.values.contactPhone = contactData.contactPhone;
    formikRef.current.values.contactCountry = contactData.contactCountry;

    formikRef.current.values.contactStreetAndNumber = contactData.contactStreetAndNumber;
    formikRef.current.setFieldValue('contactCity', contactData.contactCity);
    formikRef.current.setFieldValue('contactZipCode', contactData.contactZipCode);
  }, [contactData]);

  return (
    <>
      <Formik
        innerRef={formikRef}
        enableReinitialize
        initialValues={{
          username: '',
          password: '',
          passwordConfirm: '',
          emailConfirm: '',
          contactFirstName: '',
          contactLastName: '',
          contactEmail: '',
          contactPhone: '',
          contactCountry: 'Magyarország',
          contactCity: '',
          contactZipCode: '',
          contactStreetAndNumber: ''
        }}
        validationSchema={contactValidationSchema}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          if (newsAccept && !wantShop) {
            let value = values;
            await subscribeNewsletter({ value }).then((res) => {
              if (res) {
                successToast('Hírlevélre való feliratkozás sikeres');
                setSubmitting(true);
                setContactData(values);
                wantShop ? handleNext() : setOpen(true);
                setSubmitted(true);
                setSubmitting(false);
              } else {
                errorToast('Hírlevélre való feliratkozás sikertelen');
              }
            });
          } else {
            setSubmitting(true);
            setContactData(values);
            wantShop ? handleNext() : setOpen(true);
            setSubmitted(true);
            setSubmitting(false);
          }
        }}>
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, resetForm }) => (
          <Form className={styles.formPart}>
            <Grid container xs={12}>
              <Grid item xs={12} md={6} className={styles.control}>
                <Typography variant='h4' className={styles.label}>
                  Vezetéknév
                </Typography>
                <TextField
                  className={styles.input}
                  required
                  id='contactFirstName'
                  name='contactFirstName'
                  autoFocus
                  value={values.contactFirstName}
                  onChange={(e) => {
                    handleChange(e);
                    setContactData({
                      ...contactData,
                      contactFirstName: e.target.value
                    });
                  }}
                  error={Boolean(errors.contactFirstName)}
                  helperText={touched.contactFirstName && errors.contactFirstName}
                />
              </Grid>
              <Grid item xs={12} md={6} className={styles.control}>
                <Typography variant='h4' className={styles.label}>
                  Keresztnév
                </Typography>
                <TextField
                  variant='standard'
                  className={styles.input}
                  required
                  id='contactLastName'
                  name='contactLastName'
                  value={values.contactLastName}
                  onChange={(e) => {
                    handleChange(e);
                    setContactData({
                      ...contactData,
                      contactLastName: e.target.value
                    });
                  }}
                  error={Boolean(errors.contactLastName)}
                  helperText={touched.contactLastName && errors.contactLastName}
                />
              </Grid>
              <Grid item xs={12} md={6} className={styles.control}>
                <Typography variant='h4' className={styles.label}>
                  Felhasználónév
                </Typography>
                <TextField
                  variant='standard'
                  className={styles.input}
                  required
                  id='username'
                  name='username'
                  value={values.username}
                  onChange={(e) => {
                    handleChange(e);
                    setContactData({
                      ...contactData,
                      username: e.target.value
                    });
                  }}
                  error={touched.username && Boolean(errors.username)}
                  helperText={touched.username && errors.username}
                />
              </Grid>{' '}
              <Grid item xs={12} md={6} className={styles.control}>
                <Typography variant='h4' className={styles.label}>
                  Telefonszám
                </Typography>
                <TextField
                  variant='standard'
                  className={styles.input}
                  required
                  fullWidth
                  id='contactPhone'
                  name='contactPhone'
                  autoComplete='contactPhone'
                  value={values.contactPhone}
                  onChange={(e) => {
                    handleChange(e);
                    setContactData({
                      ...contactData,
                      contactPhone: e.target.value
                    });
                  }}
                  InputProps={{
                    startAdornment: <InputAdornment position='start'>+36</InputAdornment>,
                    placeholder: '301234567'
                  }}
                  error={touched.contactPhone && Boolean(errors.contactPhone)}
                  helperText={touched.contactPhone && errors.contactPhone}
                />
              </Grid>
              <Grid item xs={12} md={6} className={styles.control}>
                <Typography variant='h4' className={styles.label}>
                  Email cím
                </Typography>
                <TextField
                  variant='standard'
                  className={styles.input}
                  required
                  id='contactEmail'
                  name='contactEmail'
                  type='email'
                  value={values.contactEmail}
                  onChange={(e) => {
                    handleChange(e);
                    setContactData({
                      ...contactData,
                      contactEmail: e.target.value
                    });
                  }}
                  error={touched.contactEmail && Boolean(errors.contactEmail)}
                  helperText={touched.contactEmail && errors.contactEmail}
                />
              </Grid>
              <Grid item xs={12} md={6} className={styles.control}>
                <Typography variant='h4' className={styles.label}>
                  Email cím megerősítése
                </Typography>
                <TextField
                  variant='standard'
                  className={styles.input}
                  required
                  id='emailConfirm'
                  name='emailConfirm'
                  type='email'
                  value={values.emailConfirm}
                  onChange={(e) => {
                    handleChange(e);
                    setContactData({
                      ...contactData,
                      emailConfirm: e.target.value
                    });
                  }}
                  error={touched.emailConfirm && Boolean(errors.emailConfirm)}
                  helperText={touched.emailConfirm && errors.emailConfirm}
                />
              </Grid>
              <Grid item xs={12} md={6} className={styles.control}>
                <Typography variant='h4' className={styles.label}>
                  Jelszó
                </Typography>
                <TextField
                  variant='standard'
                  className={styles.input}
                  required
                  id='password'
                  name='password'
                  type='password'
                  value={values.password}
                  onChange={(e) => {
                    handleChange(e);
                    setContactData({
                      ...contactData,
                      password: e.target.value
                    });
                  }}
                  error={touched.password && Boolean(errors.password)}
                  helperText={touched.password && errors.password}
                />
              </Grid>
              <Grid item xs={12} md={6} className={styles.control}>
                <Typography variant='h4' className={styles.label}>
                  Jelszó megerősítése
                </Typography>
                <TextField
                  variant='standard'
                  className={styles.input}
                  required
                  id='passwordConfirm'
                  name='passwordConfirm'
                  type='password'
                  value={values.passwordConfirm}
                  onChange={(e) => {
                    handleChange(e);
                    setContactData({
                      ...contactData,
                      passwordConfirm: e.target.value
                    });
                  }}
                  error={touched.passwordConfirm && Boolean(errors.passwordConfirm)}
                  helperText={touched.passwordConfirm && errors.passwordConfirm}
                />
              </Grid>
              {formikRef?.current && (
                <PostalCodeFields
                  data={contactData}
                  setData={setContactData}
                  handleChange={handleChange}
                  zipHelper={formikRef?.current?.touched?.contactZipCode}
                  zipError={formikRef?.current?.errors?.contactZipCode}
                  cityHelper={formikRef?.current?.touched?.contactCity}
                  citypError={formikRef?.current?.errors?.contactCity}
                />
              )}
              <Grid item xs={12} md={6} className={styles.control}>
                <Typography variant='h4' className={styles.label}>
                  Utca, házszám
                </Typography>
                <TextField
                  variant='standard'
                  className={styles.input}
                  required
                  id='contactStreetAndNumber'
                  name='contactStreetAndNumber'
                  fullWidth
                  autoComplete='shipping address-line1'
                  value={values.contactStreetAndNumber}
                  onChange={(e) => {
                    handleChange(e);
                    setContactData({
                      ...contactData,
                      contactStreetAndNumber: e.target.value
                    });
                  }}
                  error={touched.contactStreetAndNumber && Boolean(errors.contactStreetAndNumber)}
                  helperText={touched.contactStreetAndNumber && errors.contactStreetAndNumber}
                />
              </Grid>
              <Grid item xs={12} md={6} className={styles.control}>
                <Typography variant='h4' className={styles.label}>
                  Ország
                </Typography>
                <TextField
                  variant='standard'
                  disabled
                  className={styles.input}
                  required
                  id='contactCountry'
                  name='contactCountry'
                  fullWidth
                  autoComplete='shipping country'
                  value={values.contactCountry}
                  onChange={(e) => {
                    handleChange(e);
                    setContactData({
                      ...contactData,
                      contactCountry: e.target.value
                    });
                  }}
                  error={touched.contactCountry && Boolean(errors.contactCountry)}
                  helperText={touched.contactCountry && errors.contactCountry}
                />
              </Grid>
              <Grid item xs={12} style={{ display: 'flex' }}>
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color='primary'
                        name='submitted'
                        id='submitted'
                        checked={wantShop}
                        required
                        onChange={(e) => {
                          setWantShop(!wantShop);
                        }}
                      />
                    }
                    label={
                      <Tooltip
                        placement='top'
                        title={
                          "Amennyiben szeretnél boltot létrehozni érdemes lehet most megadnod számlázási adataid, ugyanakkor erre később is lesz lehetőséged a 'Profil beállítások' felüleleten!"
                        }>
                        <Typography variant='h4' className={styles.label}>
                          Szeretném megadni a számlázási adataimat
                          <InfoIcon
                            style={{
                              fontSize: '0.9rem',
                              opacity: '0.5',
                              marginLeft: '0rem'
                            }}
                          />
                        </Typography>
                      </Tooltip>
                    }
                  />
                </div>
              </Grid>
              <Grid item xs={12} style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant='h4' className={styles.label}>
                  Nyomtatási kiszolgáló:
                </Typography>
                <Tooltip
                  placement='top'
                  title={
                    'Az általunk történő nyomtatás esetén használt képméret szerinti árlistát megtalálod az Általános Szerződési Feltételek 1. számú mellékletében.'
                  }>
                  <Typography variant='h4' className={styles.label}>
                    <InfoIcon
                      style={{
                        fontSize: '0.9rem',
                        opacity: '0.5',
                        marginLeft: '0.5rem'
                      }}
                    />
                    A képek nyomtatását jelenleg az Iskolaévkönyv saját képelőhívó csapata végzi.
                  </Typography>
                </Tooltip>
                <Typography
                  variant='h5'
                  style={{
                    color: '#b0043f',
                    marginTop: '0.2rem',
                    fontSize: '0.9rem'
                  }}>
                  Van lehetőség saját nyomtatásra is, ha ezt a megoldást választanád, kérjük vedd fel velünk a
                  kapcsolatot!
                </Typography>
              </Grid>
              <Grid
                item
                container
                style={{
                  margin: '2rem 0 0 0',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-around'
                }}>
                <div>
                  <Typography
                    variant='h5'
                    style={{
                      fontSize: '1rem'
                    }}>
                    Szeretnék a továbbiakban is értesülni a rendszert érintő újdonságokról, iskolai fotózással
                    kapcsolatos fotós szakmai hírekről, tippekről.
                  </Typography>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color='primary'
                        name='submitted'
                        id='submitted'
                        checked={newsAccept}
                        required
                        onChange={(e) => {
                          setNewsAccept(e.target.checked);
                        }}
                        error={!newsAccept}
                      />
                    }
                    label={
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row'
                        }}
                        className={styles.data}>
                        <p>Feliratkozom a hírlevélre</p>
                      </div>
                    }
                  />
                </div>

                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', marginBottom: '3rem' }}>
                  <button type='submit' onClick={handleSubmit} className={styles.button}>
                    {wantShop ? 'Tovább' : 'Regisztráció'}
                  </button>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      <div className={styles.loginContainer}>
        <a onClick={handleRedirect} className={styles.login}>
          Már van fiókod? Bejelentkezéshez kattints ide!
        </a>
      </div>
    </>
  );
};

export default ContactForm;
