import { Grid, TextField, Typography } from '@material-ui/core';
import { Autocomplete } from '@mui/material';
import React from 'react';
import { useEffect } from 'react';
import { usePostalCodeFields } from './hooks/usePostalCodeFields';
import styles from './PostalCodeFields.module.css';

const PostalCodeFields = ({ data, setData, zipHelper, zipError, cityHelper, cityError }) => {
const { calcAddress, calcPostalCode, calcAddressSuggestions, calcZipSuggestions, zips, settlements } =
    usePostalCodeFields();

  useEffect(() => {
    calcZipSuggestions(data?.city, data?.zipcode);
    if (data?.zipcode?.length > 0) {
      const suggestion = calcAddress(data.zipcode);
      if (data?.city && settlements[Object.keys(settlements)?.find((key) => key === data?.city)] == data?.zipcode) {
        return;
      } else {
        setData({ ...data, city: suggestion || '' });
      }
    } else if (data?.city?.length > 1 && Object.keys(settlements)?.find((key) => key === data?.city)) {
      setData({ ...data, city: '' });
    }
  }, [data?.zipcode]);

  useEffect(() => {
    calcZipSuggestions(data?.city, data?.zipcode);
    if (data?.city?.length > 0) {
      const suggestion = calcPostalCode(data.city, data.zipcode);
      setData({ ...data, zipcode: suggestion || '' });
    } else if (data?.zipcode?.length > 1) {
      setData({ ...data, zipcode: '' });
    }
  }, [data?.city]);

  return (
    <>
      <Grid item xs={12} md={6}>
        <Grid item xs={12}>
          <Typography variant='body1'>Irányítószám</Typography>
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            isOptionEqualToValue={(option, value) => option.value === value.value}
            disablePortal
            options={zips}
            filterOptions={(options) => options}
            inputValue={data?.zipcode}
            className={styles.autoComplete}
            sx={{ fontFamily: `var(--font-main), Sans-serif, Arial` }}
            onChange={(e) =>
              setData({
                ...data,
                zipcode: e.target.innerHTML.includes('<') ? '' : e.target.innerHTML
              })
            }
            renderInput={(params) => (
              <TextField
                id='zip'
                variant='outlined'
                required
                name='zip'
                value={data?.zipcode}
                {...params}
                key='postal_code_field'
                onChange={(e) => {
                  setData({ ...data, zipcode: e.target.value });
                }}
                error={zipHelper && Boolean(zipError)}
                helperText={zipHelper && zipError}
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} md={6}>
        <Grid item xs={12}>
          <Typography variant='body1'>Település</Typography>
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            isOptionEqualToValue={(option, value) => option.value === value.value}
            disablePortal
            options={calcAddressSuggestions(data?.city)}
            filterOptions={(options) => options}
            inputValue={data?.city}
            className={styles.autoComplete}
            sx={{ fontFamily: `var(--font-main), Sans-serif, Arial` }}
            onChange={(e) =>
              setData({
                ...data,
                city: e.target.innerHTML.includes('<') ? '' : e.target.innerHTML
              })
            }
            renderInput={(params) => (
              <TextField
                id='city'
                variant='outlined'
                required
                name='city'
                value={data?.city}
                {...params}
                key='address_field'
                onChange={(e) => {
                  setData({ ...data, city: e.target.value });
                }}
                error={cityHelper && Boolean(cityError)}
                helperText={cityHelper && cityError}
              />
            )}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default PostalCodeFields;
