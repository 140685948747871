import { Autocomplete, TextField } from '@mui/material';
import { useGroupAPI } from 'pages/PhotoAdmin/SelectShop/hooks/useGroupAPI';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import styles from './DeliveryTargetEditor.module.css';

const DeliveryTargetEditor = ({ option, setOption }) => {
  const { fetchGroups, fetchInnerGroups, groups, innerGroups, groupLoading, innerGroupLoading } = useGroupAPI();

  const [selectedSchool, setSelectedSchool] = useState(null);
  const [selectedClass, setSelectedClass] = useState(null);
  const [schoolOptions, setSchoolOptions] = useState([]);
  const [classOptions, setClassOptions] = useState([]);

  useEffect(() => {
    fetchGroups();
  }, []);

  useEffect(() => {
    if (selectedSchool) {
      fetchInnerGroups({ id: selectedSchool.id });
    }
  }, [selectedSchool]);

  useEffect(() => {
    if (groups) {
      setSchoolOptions(groups.filter((group) => !group.belongsTo));
    }
  }, [groups]);
  useEffect(() => {
    if (option.value !== '' && groups) {
      setSelectedSchool(groups.find((g) => option.value.includes(g.name)));
    }
  }, [groups]);

  useEffect(() => {
    if (innerGroups) {
      setClassOptions(innerGroups);
    }
    if (option.value !== '' && innerGroups) {
      setSelectedClass(innerGroups.find((g) => option.value.includes(g.name)));
    }
  }, [innerGroups]);

  useEffect(() => {
    if (selectedSchool) {
      fetchInnerGroups({ id: selectedSchool.id });
      setSelectedClass(null);
      const newValue = `${option.name}=${selectedSchool.name}`;
      if (decodeURI(option.value) !== decodeURI(newValue)) {
        setOption({ ...option, value: encodeURI(newValue) });
      }
    }
  }, [selectedSchool]);

  useEffect(() => {
    if (selectedSchool && selectedClass) {
      const newValue = `${option.name}=${selectedClass.belongsTo.name} - ${selectedClass.name}`;
      if (decodeURI(option.value) !== decodeURI(newValue)) {
        setOption({ ...option, value: encodeURI(newValue) });
      }
    }
  }, [selectedClass]);

  return (
    <div className={styles.mainContainer}>
      {groupLoading ? (
        <p>Loading...</p>
      ) : (
        <Autocomplete
          disabled={!groups || groups?.length === 0}
          disablePortal
          disableClearable
          id='school-autocomplete'
          options={schoolOptions}
          getOptionLabel={(option) => option?.name || ''}
          sx={{ width: 300, fontFamily: 'var(--font-main)' }}
          value={selectedSchool}
          className={styles.autocomplete}
          onChange={(event, selectedSchool) => {
            setSelectedSchool(selectedSchool);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                style: { fontFamily: 'var(--font-main)' }
              }}
              label='Iskola'
            />
          )}
        />
      )}
      {selectedSchool && innerGroupLoading ? (
        <p>Loading...</p>
      ) : (
        <Autocomplete
          disabled={!innerGroups || innerGroups?.length === 0}
          disablePortal
          disableClearable
          id='class-autocomplete'
          options={classOptions}
          getOptionLabel={(option) => option?.name || ''}
          sx={{ width: 300 }}
          value={selectedClass}
          className={styles.autocomplete}
          onChange={(event, selectedClass) => {
            setSelectedClass(selectedClass);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                style: { fontFamily: 'var(--font-main)' }
              }}
              label='Osztály'
            />
          )}
        />
      )}
    </div>
  );
};

export default DeliveryTargetEditor;
