import React, { useState } from 'react';
import styles from './TemplateMain.module.css';
import ProductTypes from '../ProductTypes/ProductTypes';
import TemplateBubbles from '../TemplateBubbles/TemplateBubbles';
import ProductTypeEditor from '../ProductTypeEditor/ProductTypeEditor';
import { useSelector } from 'react-redux';

const TemplateMain = ({ modifiers, isTemplate, setIsTemplate }) => {
  const [isEdit, setIsEdit] = useState(false);
  const [selectedEdit, setSelectedEdit] = useState(null);
  const [datagridData, setDatagridData] = useState([]);
  const shopTier = useSelector((state) => state.nonPersistedReducers?.shopTierSliceReducer?.shopTier);

  return !isEdit && !isTemplate ? (
    <div className={styles.mainPart}>
      <ProductTypes
        modifiers={modifiers}
        setSelectedEdit={setSelectedEdit}
        setIsEdit={setIsEdit}
        setDatagridData={setDatagridData}
      />
      {shopTier?.pricingTemplates ? (
        <TemplateBubbles setIsTemplate={setIsTemplate} />
      ) : (
        <div className={styles.overlayContainer}>
          <div className={styles.overlay}>
            <div className={styles.overlayText}>
              Ezt az oldalt a jelenlegi előfizetésed nem támogatja. Javasoljuk hogy válts nagyobb csomagra!
            </div>
          </div>
          <div className={styles.content}>
            <TemplateBubbles setIsTemplate={setIsTemplate} isOverlayed={true} />
          </div>
        </div>
      )}
    </div>
  ) : !isTemplate ? (
    <div className={styles.mainPart}>
      <ProductTypeEditor selectedEdit={selectedEdit} setIsEdit={setIsEdit} datagridData={datagridData} />
    </div>
  ) : null;
};

export default TemplateMain;
