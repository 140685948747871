import { TextField } from '@mui/material';
import React from 'react';

const StringEditor = ({ option, setOption }) => {
  return (
    <TextField
      variant='outlined'
      InputProps={{ style: { fontFamily: 'var(--font-main)' } }}
      onChange={(e) => setOption({ ...option, value: `${option.name}=${e.target.value}` })}
    />
  );
};

export default StringEditor;
