import styles from './ChangeContactNameCard.module.css';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Edit from '@material-ui/icons/Edit';
import Button from '@material-ui/core/Button';
import { useEffect, useState, useRef } from 'react';
import { Form, Formik } from 'formik';
import { CircularProgress } from '@material-ui/core';
import SettingsCard from 'components/PhotoAdminComponents/Settings/Card/SettingsCard';
import ActionButton from 'components/common/ActionButton/ActionButton';
import nameChangeValidationSchema from 'validation/UserSettings/NameChangeSchema';
import { useUserApi } from 'hooks/useUserApi';

const ChangeContactNameCard = ({ user, setInProgress, inProgress, showDialog }) => {
  const [isEditing, setIsEditing] = useState(false);
  const toggleIsEditing = () => setIsEditing(!isEditing);
  const formikRef = useRef(null);
  const { getCurrentUser, putUserName } = useUserApi();

  const [nameData, setNameData] = useState({ firstname: '', lastname: '' });

  const putNameWithDialog = async ({ user, firstName, lastName }) => {
    setInProgress('name');
    const status = await putUserName({
      user: user,
      firstname: firstName,
      lastname: lastName
    });
    getCurrentUser();
    showDialog(status);
    setInProgress(null);
  };

  useEffect(() => {
    if (user && formikRef.current) {
      formikRef.current.values.firstName = nameData?.firstname;
      formikRef.current.values.lastName = nameData?.lastname;
    }
  }, [user, formikRef]);

  useEffect(() => {
    if (isEditing) {
      setNameData({ firstname: '', lastname: '' });
    } else {
      setNameData({
        firstname: user?.contact?.firstname,
        lastname: user?.contact?.lastname
      });
    }
  }, [user, isEditing]);

  return (
    <SettingsCard
      title='Kapcsolattartói név'
      actionButton={
        <ActionButton
          icon={<Edit color='primary' />}
          clickHandler={toggleIsEditing}
          className={styles.actionButtonStyle}
        />
      }>
      <Formik
        innerRef={formikRef}
        enableReinitialize
        initialValues={{
          firstName: '',
          lastName: ''
        }}
        validationSchema={nameChangeValidationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          await putNameWithDialog({
            user: { ...user },
            firstName: values.firstName,
            lastName: values.lastName
          });
          setIsEditing(false);
          setSubmitting(false);
        }}>
        {({ values, errors, touched, handleChange, handleSubmit, isSubmitting }) => (
          <Form className={styles.form}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  disabled={!isEditing}
                  variant='outlined'
                  fullWidth
                  required
                  label='Vezetéknév'
                  id='firstName'
                  name='firstName'
                  value={isEditing ? values.firstName : nameData?.firstname}
                  onChange={handleChange}
                  error={touched.firstName && Boolean(errors.firstName)}
                  helperText={touched.firstName && errors.firstName}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  disabled={!isEditing}
                  variant='outlined'
                  fullWidth
                  required
                  label='Keresztnév'
                  id='lastName'
                  name='lastName'
                  value={isEditing ? values.lastName : nameData?.lastname}
                  onChange={handleChange}
                  error={touched.lastName && Boolean(errors.lastName)}
                  helperText={touched.lastName && errors.lastName}
                />
              </Grid>
            </Grid>
            <Grid container className={styles.gridContainer}>
              <Grid item xs={12}>
                <Button disabled={!isEditing} type='submit' fullWidth onClick={handleSubmit} variant='outlined'>
                  {inProgress === 'name' ? (
                    <>
                      Folyamatban
                      <CircularProgress style={{ marginLeft: '0.5rem' }} size={24} />
                    </>
                  ) : (
                    'Név frissítése'
                  )}
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </SettingsCard>
  );
};

export default ChangeContactNameCard;
