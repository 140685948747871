import Typography from '@material-ui/core/Typography';
import { Link as RouterLink } from 'react-router-dom';
import styles from './HeaderLogo.module.css';

const HeaderLogo = ({ currentHome, icon, siteName }) => {
  return !!icon ? (
    <RouterLink variant='h5' className={styles.title} to={currentHome}>
      <img src={icon} alt='Site icon' className={styles.title} />
    </RouterLink>
  ) : (
    <Typography variant='h4' className={styles.title}>
      <RouterLink to='/' className={styles.title}>
        {siteName}
      </RouterLink>
    </Typography>
  );
};

export default HeaderLogo;
