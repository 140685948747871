import { Button, Dialog, DialogActions, DialogTitle, Typography } from '@material-ui/core';
import React from 'react';

const DeleteDialog = ({ confirmOpen, deleteModalRef, setConfirmOpen, handleDelete }) => {
  return (
    <Dialog
      open={confirmOpen}
      ref={deleteModalRef}
      onClose={(e) => {
        setConfirmOpen(false);
      }}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'>
      <DialogTitle id='alert-dialog-title'>
        <Typography
          style={{
            fontFamily: 'var(--font-main), Sans-serif, Arial'
          }}>
          Biztosan törölni szeretnéd?
        </Typography>
      </DialogTitle>
      <DialogActions>
        <Button onClick={handleDelete} color='primary'>
          Törlés
        </Button>
        <Button
          onClick={() => {
            setConfirmOpen(false);
          }}
          color='primary'>
          Mégse
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteDialog;
