import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Edit from '@material-ui/icons/Edit';
import Button from '@material-ui/core/Button';
import { useEffect, useState } from 'react';
import SettingsCard from 'components/PhotoAdminComponents/Settings/Card/SettingsCard';
import ActionButton from 'components/common/ActionButton/ActionButton';
import { Form, Formik } from 'formik';
import { useRef } from 'react';
import { CircularProgress } from '@material-ui/core';
import styles from './ChangeBankAccountNumberCard.module.css';
import bankAccountNumberChangeValidationSchema from 'validation/UserSettings/BankAccountNumberChangeValidationSchema';
import { useUserApi } from 'hooks/useUserApi';

const ChangeBankAccountNumberCard = ({ user, setInProgress, inProgress, showDialog }) => {
  const [isEditing, setIsEditing] = useState(false);
  const toggleIsEditing = () => setIsEditing(!isEditing);
  const formikRef = useRef(null);
  const { getCurrentUser, putUserBankAccountNumber } = useUserApi();

  const [bankAccountData, setBankAccountData] = useState({
    bankAccountNumber: ''
  });

  const putBankAccountNumberWithDialog = async (body) => {
    setInProgress('bankAccountNumber');
    const status = await putUserBankAccountNumber(body);
    getCurrentUser();
    showDialog(status);
    setInProgress(null);
  };

  useEffect(() => {
    if (user && formikRef.current) {
      formikRef.current.values.bankAccountNumber = bankAccountData?.bankAccountNumber;
    }
  }, [user, formikRef]);

  useEffect(() => {
    if (isEditing) {
      setBankAccountData({ bankAccountNumber: '' });
    } else {
      setBankAccountData({
        bankAccountNumber: user?.billingInfo?.bankAccountNumber
      });
    }
  }, [user, isEditing]);

  return (
    <SettingsCard
      title={'Bankszámlaszám'}
      actionButton={
        <ActionButton
          icon={<Edit color='primary' />}
          clickHandler={toggleIsEditing}
          className={styles.actionButtonStyle}
        />
      }>
      <Formik
        innerRef={formikRef}
        enableReinitialize
        initialValues={{
          bankAccountNumber: ''
        }}
        validationSchema={bankAccountNumberChangeValidationSchema}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          setSubmitting(true);

          await putBankAccountNumberWithDialog({
            ...user,
            billingInfo: {
              ...user?.billingInfo,
              bankAccountNumber: values.bankAccountNumber
            }
          });
          setIsEditing(false);
          setSubmitting(false);
        }}>
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, resetForm }) => (
          <Form className={styles.form}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  disabled={!isEditing}
                  variant='outlined'
                  fullWidth
                  required
                  label={'Bankszámlaszám'}
                  id='bankAccountNumber'
                  name='bankAccountNumber'
                  value={isEditing ? values.bankAccountNumber : bankAccountData?.bankAccountNumber}
                  onChange={handleChange}
                  error={touched.bankAccountNumber && Boolean(errors.bankAccountNumber)}
                  helperText={touched.bankAccountNumber && errors.bankAccountNumber}
                />
              </Grid>{' '}
            </Grid>
            <Grid container className={styles.gridContainer}>
              <Grid item xs={12}>
                <Button disabled={!isEditing} type='submit' fullWidth onClick={handleSubmit} variant='outlined'>
                  {inProgress === 'bankAccountNumber' ? (
                    <>
                      Folyamatban
                      <CircularProgress style={{ marginLeft: '0.5rem' }} size={24} />
                    </>
                  ) : (
                    'Bankszámlaszám frissítése'
                  )}
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </SettingsCard>
  );
};

export default ChangeBankAccountNumberCard;
