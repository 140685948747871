import styles from './ChangePasswordCard.module.css';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Edit from '@material-ui/icons/Edit';
import Button from '@material-ui/core/Button';
import { useEffect, useState, useRef } from 'react';
import { Form, Formik } from 'formik';
import { CircularProgress, IconButton, InputAdornment } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import SettingsCard from 'components/PhotoAdminComponents/Settings/Card/SettingsCard';
import ActionButton from 'components/common/ActionButton/ActionButton';
import passwordChangeValidationSchema from 'validation/UserSettings/PasswordChangeSchema';
import { useUserApi } from 'hooks/useUserApi';

const ChangePasswordCard = ({ user, setInProgress, inProgress, showDialog }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const formikRef = useRef(null);
  const { getCurrentUser, putUserPassword } = useUserApi();

  const toggleIsEditing = () => setIsEditing(!isEditing);
  const handleShowPassword = () => setShowPassword(!showPassword);

  const putPasswordWithDialog = async ({ user, password }) => {
    setInProgress('password');
    const status = await putUserPassword({ user: user, password: password });
    getCurrentUser();
    showDialog(status);
    setInProgress(null);
  };

  useEffect(() => {
    if (user && formikRef.current) {
      formikRef.current.values.password = user?.password;
    }
  }, [user, formikRef]);

  return (
    <SettingsCard
      title='Jelszó'
      actionButton={
        <ActionButton
          icon={<Edit color='primary' />}
          clickHandler={toggleIsEditing}
          className={styles.actionButtonStyle}
        />
      }>
      <Formik
        innerRef={formikRef}
        enableReinitialize
        initialValues={{
          password: '',
          passwordConfirm: ''
        }}
        validationSchema={passwordChangeValidationSchema}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          await putPasswordWithDialog({
            user: { ...user },
            password: values.password
          });
          setIsEditing(false);
          setSubmitting(false);
        }}>
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
          <Form className={styles.form}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  disabled={!isEditing}
                  variant='outlined'
                  fullWidth
                  required
                  label='Jelszó'
                  id='password'
                  name='password'
                  type={showPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton onClick={handleShowPassword}>
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  value={values.password}
                  onChange={handleChange}
                  error={touched.password && Boolean(errors.password)}
                  helperText={touched.password && errors.password}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  disabled={!isEditing}
                  variant='outlined'
                  fullWidth
                  required
                  label='Jelszó megerősítése'
                  id='passwordConfirm'
                  name='passwordConfirm'
                  type={showPassword ? 'text' : 'password'}
                  value={values.passwordConfirm}
                  onChange={handleChange}
                  error={touched.passwordConfirm && Boolean(errors.passwordConfirm)}
                  helperText={touched.passwordConfirm && errors.passwordConfirm}
                />
              </Grid>
            </Grid>
            <Grid container className={styles.gridContainer}>
              <Grid item xs={12}>
                <Button type='submit' disabled={!isEditing} fullWidth onClick={handleSubmit} variant='outlined'>
                  {inProgress === 'password' ? (
                    <>
                      Folyamatban
                      <CircularProgress style={{ marginLeft: '0.5rem' }} size={24} />
                    </>
                  ) : (
                    'Jelszó frissítése'
                  )}
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </SettingsCard>
  );
};

export default ChangePasswordCard;
