import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import styles from './Header.module.css';
import HeaderData from 'config/HeaderData';
import NavbarMenu from './NavbarMenu/NavbarMenu';
import HeaderLogo from './HeaderLogo/HeaderLogo';
import { MenuItem, Select, Tooltip, Typography } from '@material-ui/core';
import { useShopAPI } from 'pages/PhotoAdmin/SelectShop/hooks/useShopApi';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setActive } from 'redux/slices/ShopSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import AuthService from 'services/AuthService';

const Header = ({ toggleDrawer }) => {
  const dispatch = useDispatch();
  const shopId = useSelector((state) => state.nonPersistedReducers?.shopSliceReducer?.shopId);

  const user = useSelector((state) => state.nonPersistedReducers?.userSliceReducer?.currentUser);
  const navigate = useNavigate();
  const { getShops, allShops } = useShopAPI();

  useEffect(() => {
    if (AuthService.isLoggedIn()) {
      getShops();
    }
  }, []);

  const handleChange = (e) => {
    dispatch(setActive(e.target.value));
    navigate(`/shops/${e.target.value}/administration/orders`);
  };

  const location = useLocation();

  return location?.pathname === '/register' ? (
    <></>
  ) : (
    <AppBar className={styles.appbar} position='static'>
      <Toolbar className={styles.toolbar}>
        <div className={styles.toolbarContent}>
          <HeaderLogo
            currentHome={
              AuthService.isLoggedIn()
                ? user?.groups?.find((group) => group === 'dt_admin')
                  ? '/superadmin/dashboard'
                  : shopId === -1
                  ? `/shops`
                  : `/shops/${shopId}/administration/orders`
                : '/register'
            }
            siteName={HeaderData.siteName}
            icon={HeaderData.icon}
          />
          {AuthService.isLoggedIn() && allShops?.length > 1 && (
            <Select
              style={{ backgroundColor: 'var(--primary-contrast-text)' }}
              variant='outlined'
              id='shopId-select'
              value={shopId}
              onChange={handleChange}>
              <MenuItem value={-1}>
                <Tooltip title={`Ugrás a bolt választó oldalra`}>
                  <Typography onClick={() => navigate(`/shops`)} variant='body2'>
                    Kérlek válassz
                  </Typography>
                </Tooltip>
              </MenuItem>
              {allShops?.map((shop, idx) => (
                <MenuItem value={shop?.shop ? shop?.shop?.id : shop?.id} key={idx}>
                  <Typography variant='body2'>{shop?.shop ? shop?.shop?.name : shop?.name}</Typography>
                </MenuItem>
              ))}
            </Select>
          )}
        </div>
        <div className={styles.bigMenu}>
          <NavbarMenu user={user} />
        </div>
        <div className={styles.smallMenu}>
          <IconButton
            onClick={toggleDrawer}
            edge='start'
            className={styles.listItemtext}
            color='inherit'
            aria-label='menu'>
            <MenuIcon className={styles.menuIcon} />
          </IconButton>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
