import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import DateEditor from './DateEditor/DateEditor';
import DeliveryTargetEditor from './DeliveryTargetEditor/DeliveryTargetEditor';
import KeyValueEditor from './KeyValueEditor/KeyValueEditor';
import SelectEditor from './SelectEditor/SelectEditor';
import StringEditor from './StringEditor/StringEditor';
import GroupNameEditor from './GroupNameEditor/GroupNameEditor';
import ReportingDateEditor from './ReportingDateEditor/ReportingDateEditor';
import StatusEditor from './StatusEditor/StatusEditor';

const QueryParamEditor = ({ tempOption, setTempOption, accountingData }) => {
  const [editor, setEditor] = useState(null);

  const getEditor = (tempOption) => {
    switch (tempOption.type) {
      case 'string':
        return <StringEditor option={tempOption} setOption={setTempOption} />;
      case 'date':
        return <DateEditor option={tempOption} setOption={setTempOption} />;
      case 'reportingDate':
        return <ReportingDateEditor option={tempOption} setOption={setTempOption} />;
      case 'select':
        return <SelectEditor option={tempOption} setOption={setTempOption} />;
      case 'status':
        return <StatusEditor option={tempOption} setOption={setTempOption} />;
      case 'key-value':
        return <KeyValueEditor option={tempOption} setOption={setTempOption} />;
      case 'deliveryTarget':
        return <DeliveryTargetEditor option={tempOption} setOption={setTempOption} />;
      case 'schoolName':
        return <GroupNameEditor option={tempOption} setOption={setTempOption} accountingData={accountingData} />;
      default:
        break;
    }
  };

  useEffect(() => {
    if (tempOption) {
      setEditor(getEditor(tempOption));
    }
  }, [tempOption]);

  return <div onClick={(e) => e.stopPropagation()}>{editor || <></>}</div>;
};

export default QueryParamEditor;
