import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import React from 'react';
import Zoom from '@material-ui/core/Zoom';
import styles from './AddGroupCard.module.css';
import { Typography } from '@material-ui/core';
import { Tooltip } from '@mui/material';

const AddGroupCard = ({ toggleCreateModal, isSchool, disabled, isDemo }) => {
  return (
    <Zoom in={true} timeout={1000}>
      <Card className={styles.cardStyle}>
        <Tooltip
          title={
            isDemo
              ? isSchool
                ? disabled
                  ? 'Elérted a demo boltod galéria limitjét. Ha több iskolát szeretnél létrehozni, élesítsd boltodat!'
                  : 'Iskola Hozzáadása'
                : disabled
                ? 'Elérted a demo boltod galéria limitjét. Ha több osztályt szeretnél létrehozni, élesítsd boltodat!'
                : 'Osztály hozzáadása'
              : isSchool
              ? disabled
                ? 'Elérted jelenlegi előfizetésed iskola létrehozási limitjét. Amennyiben többet szeretnél létrehozni, válts nagyobb csomagra.'
                : 'Iskola Hozzáadása'
              : disabled
              ? 'Elérted jelenlegi előfizetésed osztály létrehozási limitjét. Amennyiben többet szeretnél létrehozni, válts nagyobb csomagra.'
              : 'Osztály hozzáadása'
          }>
          <CardActionArea
            className={styles.cardActionArea}
            onClick={
              disabled
                ? () => {}
                : () => {
                    toggleCreateModal();
                  }
            }>
            <AddCircleIcon className={styles.icon} />
            <Typography className={styles.cardTitle}>
              {isSchool ? 'Iskola Hozzáadása' : ' Osztály Létrehozása'}
            </Typography>
          </CardActionArea>
        </Tooltip>
      </Card>
    </Zoom>
  );
};

export default AddGroupCard;
