import { Card, CardActionArea, Grid, Paper, Typography, Zoom } from '@material-ui/core';
import styles from './AddNewNotificationCard.module.css';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import React from 'react';

const AddNewNotificationCard = ({ setCreate }) => {
  return (
    <Zoom in={true} timeout={700}>
      <Grid item xs={12} md={6}>
        <Paper className={styles.paper}>
          <Card className={styles.card}>
            <CardActionArea
              className={styles.cardActionArea}
              onClick={() => {
                setCreate(true);
              }}>
              <AddCircleIcon
                style={{
                  color: 'var(--primary-background-secondary)',
                  fontSize: '5rem'
                }}
              />
              <Typography className={styles.cardTitle}>Új üzenet hozzáadása</Typography>
            </CardActionArea>
          </Card>
        </Paper>
      </Grid>
    </Zoom>
  );
};

export default AddNewNotificationCard;
