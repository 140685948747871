/** @format */

import { Fragment } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { MdDescription } from 'react-icons/md';
import { useFooterData } from './FooterData';
import styles from './Footer.module.css';
import simplepayPng from 'assets/images/simplepay_otp_bankcard_hu_top.png';
// import barionStrip from "assets/images/barionStrip.svg";
import { useDocumentsApi } from 'hooks/useDocumentsApi';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

const Footer = (props) => {
  const shopId = useSelector((state) => state.nonPersistedReducers?.shopSliceReducer?.shopId);
  const baseDocuments = useSelector((state) => state.nonPersistedReducers?.documentSliceReducer?.documents);
  const { FooterData } = useFooterData(props?.shopData);
  const { getDocuments, downloadDocuments, downloadBaseDocuments, documents } = useDocumentsApi();

  useEffect(() => {
    if (shopId && shopId !== -1) {
      getDocuments();
    }
  }, [shopId]);

  const copyright = () => {
    return (
      <Typography
        align='center'
        style={{
          fontFamily: 'var(--font-main), Sans-serif, Arial'
        }}>
        {'Copyright © '}
        <RouterLink className={styles.link} to='/'>
          iskolaévkönyv.hu
        </RouterLink>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  };

  return (
    <div className={styles.mainContainer} id='hirlevel'>
      <div className={styles.footerContainer}>
        <div className={styles.sectionsContainer}>
          {FooterData.sections.map((section, secIdx) => {
            return (
              <div className={styles.sectionContainer} key={`footer_section_${secIdx}`}>
                <div className={styles.titleContainer}>
                  <p className={styles.title}>{section?.name}</p>
                </div>
                <div className={styles.sectionContent}>
                  {section?.links?.map((link, idx) => {
                    return (
                      <Fragment key={`footer_section_${secIdx}_link_${idx}`}>
                        {link?.external ? (
                          <a target='_blank' href={link?.link} rel='noreferrer' className={styles.sectionLink}>
                            {link?.icon} {link?.text}
                          </a>
                        ) : (
                          <button className={styles.sectionLink} onclick='window.scrollTo(0, 0)'>
                            {link?.icon} {link?.text}
                          </button>
                        )}
                      </Fragment>
                    );
                  })}
                </div>
              </div>
            );
          })}
          {FooterData.downloadSections.map((section, downIdx) => {
            return (
              <div className={styles.sectionContainer} key={`footer_download_${downIdx}`}>
                <div className={styles.titleContainer}>
                  <p className={styles.title}>{section?.name}</p>
                </div>
                <div className={styles.sectionContent}>
                  {section?.name === 'Dokumentumok'
                    ? baseDocuments?.map((el) => {
                        if (el.name.includes('szerződési')) {
                          return (
                            <Fragment key={`footer_download_${el.id}_link_${el.id}`}>
                              <a
                                onClick={(event) => {
                                  event.preventDefault();
                                  downloadBaseDocuments(el.id, el.name);
                                }}
                                className={styles.sectionLink}
                                key={el.id}
                                href='/'>
                                <MdDescription
                                  style={{
                                    color: 'var(--primary-background-secondary)'
                                  }}
                                />
                                {el.name}
                              </a>
                            </Fragment>
                          );
                        }
                        return null;
                      })
                    : ''}
                  {section?.name === 'Adatvédelem'
                    ? baseDocuments?.map((el) => {
                        if (el.name.includes('tájékoztató')) {
                          return (
                            <Fragment key={`footer_download_${el.id}_link_${el.id}`}>
                              <a
                                onClick={(event) => {
                                  event.preventDefault();
                                  downloadBaseDocuments(el.id, el.name);
                                }}
                                className={styles.sectionLink}
                                key={el.id}
                                href='/'>
                                <MdDescription
                                  style={{
                                    color: 'var(--primary-background-secondary)'
                                  }}
                                />
                                {el.name}
                              </a>
                            </Fragment>
                          );
                        }
                        return null;
                      })
                    : ''}
                  {section?.links?.map((link, idx) => (
                    <Fragment key={`footer_download_${downIdx}_link_${idx}`}>
                      {link?.external ? (
                        <a target='_blank' href={link?.link} rel='noreferrer' className={styles.sectionLink}>
                          {link?.icon} {link?.text}
                        </a>
                      ) : (
                        <a href={link?.href} download={link?.download} className={styles.sectionLink} key={idx}>
                          {link?.icon} {link?.text}
                        </a>
                      )}
                    </Fragment>
                  ))}

                  {section?.name === 'Dokumentumok'
                    ? documents?.map((el) => {
                        if (el.name.includes('Általános szerződési feltételek') && shopId) {
                          return (
                            <Fragment key={`footer_download_${el.id}_link_${el.id}`}>
                              <a
                                onClick={(event) => {
                                  event.preventDefault();
                                  downloadDocuments(el.id, el.name);
                                }}
                                className={styles.shopLink}
                                key={el.id}
                                href='/'>
                                <MdDescription
                                  style={{
                                    color: 'var(--primary-background-secondary)'
                                  }}
                                />
                                {el.name}
                              </a>
                            </Fragment>
                          );
                        }
                        return null;
                      })
                    : ''}
                  {section?.name === 'Adatvédelem'
                    ? documents?.map((el) => {
                        if (el.name.includes('Adatkezelési tájékoztató') && shopId) {
                          return (
                            <Fragment key={`footer_download_${el.id}_link_${el.id}`}>
                              <a
                                onClick={(event) => {
                                  event.preventDefault();
                                  downloadDocuments(el.id, el.name);
                                }}
                                className={styles.sectionLink}
                                key={el.id}
                                href='/'>
                                <MdDescription
                                  style={{
                                    color: 'var(--primary-background-secondary)'
                                  }}
                                />
                                {el.name}
                              </a>
                            </Fragment>
                          );
                        }
                        return null;
                      })
                    : ''}
                </div>
              </div>
            );
          })}
        </div>

        <div className={styles.paymentContainer}>
          <a href='https://simplepartner.hu/PaymentService/Fizetesi_tajekoztato.pdf' target={'_blank'} rel='noreferrer'>
            <img className={styles.paymentLogo} src={simplepayPng} alt='simplepay_logo' />
          </a>
        </div>

        {/* <PaymentVendorLogoWrapper>
                    <a
                        href="https://secure.barion.com/Registration/Individual?lang=hu_HU"
                        target={"_blank"}
                        rel="noreferrer"
                    >
                        <PaymentVendorLogo
                            src={barionStrip}
                            alt="barion_logo"
                        />
                    </a>
                </PaymentVendorLogoWrapper> */}
        <div className={styles.copyrightContainer}>
          <div className={styles.sectionContent}>{copyright()}</div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
