import { Button, Dialog, Slide } from '@material-ui/core';
import React, { forwardRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useNotificationAPI } from './hooks/useNotificationAPI';
import styles from './NotificationDialog.module.css';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const NotificationDialog = () => {
  const { notifications, fetchNotifications } = useNotificationAPI();
  const [open, setOpen] = useState(true);
  const [activeStep, setActiveStep] = useState(0);
  const [visibleNotifications, setVisibleNotifications] = useState();

  useEffect(() => {
    fetchNotifications();
  }, []);

  useEffect(() => {
    if (notifications?.length > 0) {
      setVisibleNotifications(notifications?.filter((n) => !localStorage.getItem(`n${n?.id}confirmed`)));
    }
  }, [notifications]);

  return (
    <>
      {visibleNotifications?.length > 0 ? (
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          className={styles.dialog}
          onClick={(e) => e.stopPropagation()}
          onClose={() => {}}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'>
          <div className={styles.dialogContainer}>
            <div>
              <h1 className={styles.title}>{visibleNotifications[activeStep]?.message}</h1>
            </div>
            {visibleNotifications?.length > 1 && (
              <div className={styles.stepper}>
                {notifications?.map((notification, idx) => {
                  return (
                    <div
                      className={styles.stepIndicator}
                      key={notification.id}
                      style={{
                        background: activeStep === idx ? 'var(--primary-background-primary)' : 'lightgrey'
                      }}
                      onClick={() => setActiveStep(idx)}
                    />
                  );
                })}
              </div>
            )}
            <div className={styles.buttonsContainer}>
              {visibleNotifications?.length > 1 && (
                <Button
                  disabled={activeStep === 0}
                  className={styles.backBtn}
                  style={{
                    background: activeStep !== 0 ? 'var(--primary-background-primary)' : 'lightgrey'
                  }}
                  onClick={() => setActiveStep(activeStep - 1)}>
                  Vissza
                </Button>
              )}

              <Button
                className={styles.btn}
                onClick={() => {
                  !localStorage.getItem(`n${visibleNotifications[activeStep]?.id}confirmed`) &&
                    localStorage.setItem(`n${visibleNotifications[activeStep]?.id}confirmed`, true);
                  activeStep === notifications?.length - 1 ? setOpen(false) : setActiveStep(activeStep + 1);
                }}>
                {activeStep === visibleNotifications?.length - 1 ? 'Megértettem' : 'Tovább'}
              </Button>
            </div>
          </div>
        </Dialog>
      ) : (
        <></>
      )}
    </>
  );
};

export default NotificationDialog;
