import { Zoom, Typography } from '@material-ui/core';
import Delete from '@material-ui/icons/Delete';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import styles from './InvoiceVendorCard.module.css';
import { Tooltip } from '@mui/material';

const InvoiceVendorCard = ({ setEditVendor, invoiceVendor, toggleSure, disabled }) => {
  return (
    <Zoom in={true} timeout={1000}>
      <div className={styles.cardStyle}>
        {disabled ? (
          <Tooltip title='Demo bolt esetén a számlakibocsátó szolgáltatás nem törölhető'>
            <Delete className={styles.disabled} onClick={() => {}} />
          </Tooltip>
        ) : (
          <Delete className={styles.delete} onClick={toggleSure} />
        )}
        <div
          className={styles.cardActionArea}
          onClick={() => {
            setEditVendor(invoiceVendor);
          }}>
          <ReceiptLongIcon
            style={{
              color: 'var(--primary-background-secondary)',
              fontSize: '5rem'
            }}
          />
          <Typography className={styles.cardTitle}>{invoiceVendor?.name}</Typography>
        </div>
      </div>
    </Zoom>
  );
};

export default InvoiceVendorCard;
