import React from 'react';
import { useState } from 'react';
import styles from './StatusEditor.module.css';
import { IconButton } from '@mui/material';
import { Add, Remove } from '@mui/icons-material';

const StatusEditor = ({ option, setOption }) => {
  const [options, setOptions] = useState(option.value !== '' ? option.value.split('=')[1].split(',') : []);
  const addOption = (value) => {
    setOptions([...options, value]);
    setOption({
      ...option,
      value: `${option.name}=${[...options, value].join(',')}`
    });
  };

  const removeOption = (value) => {
    setOptions(options.filter((o) => o !== value));
    setOption({
      ...option,
      value: `${option.name}=${options.filter((o) => o !== value).join(',')}`
    });
  };
  return (
    <div className={styles.mainContainer}>
      {option.values?.map((value, idx) => (
        <div className={styles.optionContainer}>
          {options?.find((o) => o === value) ? (
            <p className={styles.found}> {option.displayedValues[idx]}</p>
          ) : (
            <p className={styles.notFound}>{option.displayedValues[idx]}</p>
          )}
          {options?.find((o) => o === value) ? (
            <IconButton onClick={() => removeOption(value)}>
              <Remove />
            </IconButton>
          ) : (
            <IconButton onClick={() => addOption(value)}>
              <Add />
            </IconButton>
          )}
        </div>
      ))}
    </div>
  );
};

export default StatusEditor;
