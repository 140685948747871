import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { useNavigate } from 'react-router-dom';
import styles from './Breadcrumb.module.css';
import { useSelector } from 'react-redux';

const Breadcrumb = ({ header, parentHeader }) => {
  const navigate = useNavigate();
  const uploadLoading = useSelector((state) => state.nonPersistedReducers.uploadSliceReducer.loading);
  const shopId = useSelector((state) => state.nonPersistedReducers?.shopSliceReducer?.shopId);

  const routeChange = (id) => {
    let path = `/shops/${shopId}/administration/galleries/${id}`;
    navigate(path);
  };

  return (
    <div className={styles.mainContainer}>
      {header?.belongsTo ? (
        <Breadcrumbs aria-label='breadcrumb'>
          <Link
            className={styles.pageName}
            onClick={
              uploadLoading
                ? () => {}
                : () => {
                    routeChange(header?.belongsTo.id);
                  }
            }>
            {parentHeader?.belongsTo && '...'} {header?.belongsTo.name}
          </Link>
          <Typography className={styles.currentName}>{header?.name}</Typography>
        </Breadcrumbs>
      ) : (
        <Typography className={styles.currentName}>{header?.name}</Typography>
      )}
    </div>
  );
};
export default Breadcrumb;
