import { createSlice } from '@reduxjs/toolkit';

export const shopTierSlice = createSlice({
  name: 'shopTier',
  initialState: {
    shopTier: null
  },
  reducers: {
    setShopTier: (state, action) => {
      state.shopTier = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const { setShopTier } = shopTierSlice.actions;

export default shopTierSlice.reducer;
