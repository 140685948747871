import React from 'react';
import { CircularProgress, Typography } from '@material-ui/core';
import styles from './LoadIndicator.module.css';

const LoadIndicator = ({ color, height }) => {
  return (
    <div className={styles.loadContainer} style={{ minHeight: height }}>
      <Typography
        style={{
          color: color,
          fontFamily: `var(--font-main), Sans-serif, Arial`
        }}>
        Egy pillanat ...
      </Typography>
      <CircularProgress size={70} className={styles.spinner} style={{ color: color }} />
    </div>
  );
};

export default LoadIndicator;
