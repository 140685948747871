import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import DoneIcon from '@mui/icons-material/Done';
import CancelIcon from '@mui/icons-material/Cancel';
import styles from './NewNotificationCard.module.css';
import { Button, Checkbox, MenuItem, Select, TextField, Tooltip, Zoom } from '@material-ui/core';
import { useState } from 'react';
import ConfirmDialog from 'components/common/ConfirmDialog/ConfirmDialog';

const NewNotificationCard = ({ setCreate, createNotification, requiredActions }) => {
  const [newNotification, setNewNotification] = useState({
    isPublic: false,
    isActive: true
  });
  const [open, setOpen] = useState(false);
  return (
    <Zoom in={true} timeout={700} unmountOnExit>
      <Grid item xs={12} md={6}>
        <Paper className={styles.paper}>
          <div className={styles.nameContainer}>
            <TextField
              placeholder='Üzenet címe'
              className={styles.nameField}
              onChange={(e) => {
                setNewNotification({
                  ...newNotification,
                  description: e.target.value
                });
              }}
              value={newNotification?.description}
            />
          </div>

          <div className={styles.controlButtonContainer}>
            <Tooltip title={'Üzenet létrehozása'}>
              <Button
                onClick={() => {
                  setOpen(true);
                }}
                style={{
                  color: 'var(--primary-text-primary)'
                }}>
                <DoneIcon />
                {/* Mentés */}
              </Button>
            </Tooltip>
            <Tooltip title={'Ablak bezárása'}>
              <Button
                onClick={() => {
                  setCreate(false);
                }}
                style={{
                  color: 'var(--primary-text-primary)'
                }}>
                <CancelIcon />
                {/* Mégsem */}
              </Button>
            </Tooltip>
          </div>
          {/* <Row>
            <Title>Üzenet:</Title>
            <TextField
              className={classes.detail}
              onChange={(e) => {
                setNewNotification({
                  ...newNotification,
                  message: e.target.value,
                });
              }}
              value={newNotification?.message}
            />
          
          </Row> */}
          <div className={styles.row}>
            <p className={styles.title}>Üzenet:</p>
            <TextField
              className={styles.detail}
              onChange={(e) => {
                setNewNotification({
                  ...newNotification,
                  message: e.target.value
                });
              }}
              value={newNotification?.message}
            />
            {/* <Detail>{notification?.title}</Detail> */}
          </div>
          <div className={styles.row}>
            <p className={styles.title}>Publikus üzenet</p>
            <Checkbox
              className={styles.check}
              multiline={true}
              color='primary'
              checked={newNotification?.isPublic}
              onChange={(e) => {
                setNewNotification({
                  ...newNotification,
                  isPublic: !newNotification?.isPublic
                });
              }}
              value={newNotification?.isPublic}
            />
            {/* <Detail>{notification?.mainDesc}</Detail> */}
          </div>
          {/* <Row>
            <Title>Aktív?</Title>
            <Checkbox
              className={classes.check}
              multiline={true}
              color="primary"
              checked={newNotification?.isActive}
              onChange={(e) => {
                setNewNotification({
                  ...newNotification,
                  isActive: !newNotification?.isActive,
                });
              }}
              value={newNotification?.isActive}
            />
            
          </Row> */}
          <div className={styles.row}>
            <p className={styles.title}>Szükséges műveletek:</p>
            <Select
              required
              variant='outlined'
              className={styles.detail}
              defaultValue={'NO_ACTION'}
              onChange={(e) => {
                if (e.target.value === 'NO_ACTION') {
                  setNewNotification({
                    ...newNotification,
                    requiredActions: []
                  });
                } else {
                  setNewNotification({
                    ...newNotification,
                    requiredActions: [e.target.value]
                  });
                }
              }}>
              <MenuItem
                key='0_action'
                value={'NO_ACTION'}
                style={{
                  fontFamily: 'var(--font-main), Sans-serif, Arial'
                }}>
                Válassz egyet...
              </MenuItem>
              {requiredActions?.map((action) => (
                <MenuItem
                  key={`${action.id}_action`}
                  value={action}
                  style={{
                    fontFamily: 'var(--font-main), Sans-serif, Arial'
                  }}>
                  {action.action}
                </MenuItem>
              ))}
            </Select>

            {/* <Detail>{notification?.btnText}</Detail> */}
          </div>
          <ConfirmDialog
            open={open}
            functionButtonColor={'var(--primary-success), Sans-serif, Arial'}
            functionButtonText={'Igen'}
            onClose={() => {
              setOpen(false);
            }}
            multipleButtons
            closeButtonText={'Nem'}
            title={'Biztos hogy létrehozod az üzenetet ezekkel az adatokkal?'}
            onOk={() => {
              createNotification(newNotification);
              setCreate(false);
            }}
          />
        </Paper>
      </Grid>
    </Zoom>
  );
};

export default NewNotificationCard;
